import { commonToAll } from './commonToAll'

export const it = {
  title: '[ITA] ASTERS',
  common: commonToAll,

  temp: {
    settings: 'Impostazioni',
    billing: 'Pagamenti',
    publish: 'Pubblicazione',
    discover: 'Discover',
  },

  upperSettings: {
    quickStart: 'Inizia da qui',
    helpSupport: 'Centro assistenza',
    roadmap: 'Roadmap',
    academy: 'Asters Academy',
    docs: 'API docs',
    settings: 'Impostazioni',
    billing: 'Fatturazione',
    darkMode: 'Dark mode',
    logout: 'Log out',
  },

  permissions: {
    contactAdmin: 'Non hai le autorizzazioni necessarie, contatta l’admin del workspace',
    post: 'Gestisci tutti i tuoi canali social da un’unica dashboard, pubblicando direttamente o pianificando la pubblicazione dei contenuti nel tempo.',
    calendar:
      "Visualizza e gestisci tutti i post del calendario editoriale da un'unica schermata, filtrandoli a tuo piacimento",
    medias:
      'In Asters puoi creare qualsiasi grafica in pochi secondi, usare migliaia di template, organizzare i tuoi media in un unico storage e cercarli in modo veloce e intelligente',
    analytics:
      'Scopri approfondimenti fondamentali sui tuoi profili social e condividili con il cliente attraverso report personalizzati',
    discover:
      'Perdere ore a cercare idee su Google è il passato. Ora in 1 click puoi trovare nuove idee di contenuti in trend sugli argomenti di cui vuoi parlare e monitorare le comunicazioni dei tuoi competitors.',
  },

  misc: {
    signin: 'Accedi',
    signup: 'Iscriviti',
    name: 'Nome',
    connectedBy: 'Connesso da',
  },

  pages: {
    post: 'Post',
    discover: 'Discover',
    dashboard: 'Dashboard',
    calendar: 'Calendario',
    media: 'Media',
    analytics: 'Analytics',
  },

  date: {
    startDate: "Data d'inizio",
    endDate: 'Data di fine',
    today: 'Oggi',
    yesterday: 'Ieri',
    last7Days: '7 giorni',
    lastMonth: '30 giorni',
    last3Months: '3 mesi',
    last6Months: '6 mesi',
    last12Months: '12 mesi',
    previousDay: 'Giorno precedente',
    previousWeek: 'Settimana precedente',
    previousMonth: 'Mese precedente',
    previousQuarter: 'Trimestre precedente',
    previousYear: 'Anno precedente',
    selectDateFirst: 'Seleziona prima  una data',

    months: {
      january: 'Gennaio',
      february: 'Febbraio',
      march: 'Marzo',
      april: 'Aprile',
      may: 'Maggio',
      june: 'Giugno',
      july: 'Luglio',
      august: 'Agosto',
      september: 'Settembre',
      october: 'Ottobre',
      november: 'Novembre',
      december: 'Dicembre',
    },
  },

  limitUser: {
    cannot_add_all_social: 'Non puoi aggiungere tutti i social perché supereresti il limite del tuo piano',
    max_reached_social_accounts: 'Hai raggiunto il numero massimo di account social previsti dal tuo piano',
    max_reached_generation: 'Hai raggiunto il numero massimo di generazioni previste dal tuo piano',
    max_reached_storage: 'Hai raggiunto il limite di spazio previsto dal tuo piano',
    max_reached_collaborators: 'Hai raggiunto il numero massimo di collaboratori previsti dal tuo piano',
    max_reached_post: 'Hai raggiunto il numero massimo di post  pubblicati previsti dal tuo piano',
    warning_reaching_max_post: (postPlanned, postPublished, postTotal) =>
      `Questo post potrebbe non essere pubblicato perché stai raggiungendo il numero massimo di post previsti dal tuo piano \n
      Hai ${postPlanned} post pianificati e ${postPublished} post pubblicati, per un totale di ${
        postPlanned + postPublished
      }/${postTotal} post`,
    max_reached_post_in_social: (social) =>
      `Hai raggiunto il numero massimo di post previsti in ${social} per il tuo piano`,
    max_reached_post_details: (postPublishing, postPublished, postTotal) =>
      `I post che stai creando superano i limiti del tuo piano.\nStai creando ${postPublishing} di ${
        postTotal - postPublished
      } post disponibili`,
  },

  referral: {
    referral: 'Guadagna',
    step_1: 'Condividi il tuo link referral con amici, colleghi, followers, partner, clienti...',
    step_2: 'La persona si iscrive ad Asters e attiva uno dei piani a pagamento',
    step_3: 'Ottieni €10 di credito tu e €10 l’altra persona',
    step_4: 'Condividi di nuovo e guadagna!',
    how_it_works: 'Come funziona?',
    copy_link: 'Copia link',
    by_joining: 'Partecipando al referral program, stai accettando i nostri',
    terms_and_conditions: 'Termini e Condizioni',
    share_via_social: 'Condividi tramite social',
    progress: 'Progresso',

    link_visiter: 'Visite al link',
    subscriber: 'Iscrizioni',
    subscriber_plan: 'Abbonati',
    credit: 'Crediti',

    preText: 'IL Messaggio da mandere sui vari social. .. . . .. . ',
  },

  sidebarNavigation: {
    post: 'Post',
    discover: 'Discover',
    dashboard: 'Dashboard',
    calendar: 'Calendario',
    media: 'Media',
    analytics: 'Analytics',
  },
  updateSocial: {
    how_to_update: 'Come aggiornare i token',
    onese_closed: 'Una volta chiuso questo messaggio vedrai i tuoi account lampeggiare come nell’esempio qui sotto.',
    clik_on_icon:
      'Cliccando sull’icona si aprirà una finestra del social selezionato dove dovrai eseguire nuovamente il log-in.',
    days_to: 'giorni per',
    change_pass: 'Se cambi password ',
    ok_understood: 'Ok, ho capito',
  },
  postType: ['Tutti', 'Pianificati', 'Pubblicati', 'Non pubblicati'],
  cancelPlan: {
    cancel: 'annulla',
    cancelTotalPlan: 'Annulla piano',
    cancel_done_whith_success: 'Annullamento effettuato con successo',
    resons: [
      'Circostanze estenuanti (e.s. covid-19, tagli di budget, etc...)',
      'É troppo complicato',
      'É troppo costoso',
      'Non ha le funzioni di cui necessito',
      'Bugs o problemi di usabilità',
      'Altre ragioni',
    ],
    conditions1:
      'Sono consapevole che cancellando ora il mio abbonamento, continuerò ad avere accesso alle funzionalità previste nel mio piano corrente fino al',
    conditions2:
      'Sono consapevole che nel momento in cui la cancellazione diventerà effettiva, perderò accesso a tutte le funzionalità previste nel mio piano corrente, ed eventuali post pianificati non verranno pubblicati.',
    message1: 'Ci dispiace vederti andar via!',
    message2: 'Ad Asters cerchiamo sempre di migliorarsi!',
    message3: 'Ti dispiacerebbe condividere la ragione del tuo annullamento?',
    message4: 'Sto cancellando il mio abbonamento perché:',
    howCanWeImprove: 'Come possiamo migliorarci?',
    cancelPlan: 'Cancellazione abbonamento',
    changedMyMind: 'Ho cambiato idea, torna indietro',
    youPlanWillRemainUntil: 'Il tuo abbonamento corrente rimarrà attivo fino al',
  },
  logIn: {
    reset: 'Reimposta',
    privacyPolicy: 'privacy policy',
    welcomeYouAre: {
      1: 'Un caloroso benvenuto 👋',
      2: 'Per personalizzare al meglio la tua esperienza, seleziona la tipologia in cui ti rispecchi maggiormente.',
    },

    welcome1: 'Congratulazioni per aver completato l’iscrizione ad Asters 🎉',
    welcome2: 'Abbiamo inviato un’email al tuo indirizzo con il link per attivare il tuo account.',
    welcome3: 'Per sicurezza controlla anche la casella spam.',
    error1: 'Si è presentato un errore.',
    error2: 'Riprova tra qualche istante.',
    returnToSigning: "Torna all'iscrizione",
  },

  intercom: {
    iHadAProblemWithAPost: 'Ho avuto un problema con un post',
  },

  singleWord: {
    from: 'Da',
    seconds: 'secondi',
    generate: 'Genera',
    post: 'Post',
    translate: 'Traduci',
    retry: 'Riprova',
    at: 'alle',
    next: 'Avanti',
    confirm: 'Conferma',
    cancel: 'Annulla',
    and: 'e',
    video: 'Video',
    image: 'Immagine',
    images: 'Immagini',
    media: 'Media',
    medias: 'Medias',
    reel: 'Reel',
    on: 'su',
    are: 'sono',
    is: 'è',
    minimum: 'minimo',
    maximum: 'massimo',
    of: 'di',
    for: 'per',
    height: 'altezza',
    width: 'larghezza',
    aspectRatio: "rapporto d'aspetto",
    codecAudio: 'codec audio',
    codecVideo: 'codec video',
    bitrate: 'bitrate',
    bitrateVideo: 'bitrate video',
    bitrateAudio: 'bitrate audio',
    fps: 'fps',
    reelThumbnail: 'reel copertina',
    colorSpace: 'spazio cromatico',
    story: 'Storia',
    gif: 'Gif',
    thumbnail: 'Copertina',
    manage: 'Gestisci',
    delete: 'Elimina',
    edit: 'Modifica',
    reuse: 'Riutilizza',
    download: 'Scarica',
    labels: 'Etichette',
    goToPost: 'Vai al post',
    more: 'Più',
    others: 'Altri',
    pending: 'In sospeso',
    accepted: 'Accettato',
    declined: 'Rifiutato',
    with: 'con',
    carousel: 'Carosello',
    typology: 'Tipologia',
    format: 'Formato',
    text: 'Testo',
    photo: 'Foto',
  },

  tableGraphCol: {
    date: 'Data',
    likes: 'Mi piace',
    comments: 'Commenti',
    saved: 'Salvati',
    reach: 'Reach',
    plays: 'Riproduzioni',
    shares: 'Shares',
    impressions: 'Impressions',
    link_clicks: 'Click sui link',
    interactions_rate: 'Tasso di interazioni',
    interactions_rate_with_link_clicks: 'Tasso di interazioni con click sui link',
    video_views: 'Visualizzazioni video',
    videos_views_clicked_to_play: 'Visualizzazioni video cliccati per riprodurre',
    add_to_favorites: 'Aggiungi ai preferiti',
    views: 'Visualizzazioni',
    total_time_watched: 'Tempo totale guardato',
    full_video_watched_rate: 'Tasso di visualizzazione video completo',
    average_time_watched: 'Tempo medio guardato',
    clicks: 'Click',
    engagement_rate: 'Tasso di coinvolgimento',
    video_duration: 'Durata video',
    engagement: 'Coinvolgimento',
  },

  notifyCategory: {
    settings: 'Impostazioni',
    billing: 'Pagamenti',
    publish: 'Pubblicazione',
    discover: 'Discover',
    inbox: 'Inbox',
    autopilot: 'Autopilot',
  },
  notifyTexts: {
    showUnreadNotifications: 'Mostra notifiche non lette',
    markAllAsRead: 'Segna tutte come già lette',
    notificationSettings: 'Impostazioni notifiche',
  },
  emptyNotify: 'Non ci sono notifiche',
  emptyNotifyForCategory: 'Non ci sono notifiche da leggere per questa categoria',
  emptyNotifyForCategories: 'Non ci sono notifiche da leggere per queste categorie',
  notifications: 'Notifiche',
  error: {
    analyticsNotEnoughUsers:
      'Non ci sono abbastanza utenti per generare dati. L’account social deve avere almeno 100 follower',
    colaboratorError: 'Errore durante l’aggiunta del collaboratore',
    analyticsPeriodErrorSocial:
      '{provider} consente di accedere ai dati di questa metrica solo per gli ultimi {days} giorni. Prova a modificare il periodo di riferimento.',
    analyticsPeriodError:
      'I dati per il periodo selezionato non sono disponibili,\n prova a cambiare il periodo con un intervallo di date minore',

    noPermissionTo: 'Non hai i permessi per',
    renameMedia: 'rinominare il media',
    uploadMedia: 'caricare il media',
    deleteMedia: 'eliminare il media',
    createFolder: 'creare la cartella',
    deleteFolder: 'eliminare la cartella',
    manageMedia: 'gestire i media',
    manageFolders: 'gestire le cartelle',
    useEditor: 'usare l’editor',
    newProject: 'Nuovo progetto',
    noTemplates: 'Non sono presenti templates',
    noProjects: 'Non sono presenti progetti',
    myProjects: 'I miei progetti',
    templates: 'Templates',
    seeCalendar: 'vedere il calendario',
    editPost: 'modificare il post',
    publishPosts: 'pubblicare i post',
    generateText: 'generare i testi',
    manageDrafts: 'gestire le bozze',
    createDraft: 'creare la bozza',
    addSocials: 'aggiungere i social',
    deleteSocials: 'eliminare i social',
    manageReport: 'gestire un report',
    viewReport: 'vedere il report',
    shareReport: 'condividere il report',
    searchContent: 'cercare contenuti',
    manageDiscover: 'gestire discover',

    reportAProblem: 'Segnala un problema',
    aiServerError: 'Apprendimento ancora in corso, per favore riprova più tardi',
    lostConnection: 'Hai perso la connessione di qualche profilo social',
    onlyVideoAllowedIn: 'Solo i video sono ammessi in  ',
    mustBe: 'deve essere ',
    mustBeWithin: 'deve essere entro ',
    mustBeUnder: 'deve essere sotto ',
    mustBeOver: 'deve essere sopra ',
    mustBeEqual: 'deve essere uguale a ',
    theStoriesOf: 'Le storie di ',
    theMaximumSize: 'La dimensione massima per ',
    theAcceptedExtensions: 'Le estensioni accettate per un ',
    allMediaSameRatio: 'Tutti i media di un carosello devono avere lo stesso ratio',
    atLeastOneVariantMustBePresent: 'Almeno una variante deve essere presente',
    alreadyWorkingOnThisPost: 'Stai già lavorando su questo post ',
    checkSocialConnection: 'Controllare la connessione sociale',
    generation_placeholder: 'Qui verranno mostrate le tue generazioni',
    brand_voice_requirements: 'Seleziona un profilo social Meta o Linkedin  per utilizzare il Brand Voice',
    brand_voice_not_included: 'Il Brand voice non è compreso nel tuo piano',
    no_data_at_this_moment: 'Nessun dato disponibile in questo momento',
    noPostInSelectedPeriod: 'Nessun post pubblicato nel periodo selezionato',
    generic_error: "Si è presentato un errore, ritenta più tardi o contatta l'assistenza",
    verify_that_all_posting_requirements_are_correct: 'Verifica che tutti i requisiti di pubblicazione siano corretti',
    user_already_exist: 'Utente già esistente',
    selectObjectiveFirst: 'Seleziona prima un obiettivo',
    updateConnection: 'Aggiorna la connessione per poter pubblicare',
    plese_check_the_conditions: 'Per favore controlla le condizioni',
    error: 'Errore',
    user_not_valid: 'Email non valida',
    password_not_valid: 'Password non valida',
    user_or_password_wrong: 'Email o password non valida',
    serverError: 'Errore di server',
    credentialError: 'Errore critico',
    notAuthorized: 'Non autorizzato',
    notHavePermiseToGetRecord: 'Non hai i permessi per accedere a questa risorsa',
    genericError: 'Errore sconosciuto',
    malformedResponseServer: 'Risposta errata dal server',
    notEsistente: 'Post non esistente',
    urlNotFound: 'Post URL non trovato',
    urlNotYetAvailable: 'Post URL non ancora disponibile. Possono essere necessari fino a 5 minuti. Riprova più tardi',
    cantHaveMoreThan25: 'Non puoi avere più di 25 gruppi, prova a eliminarne qualcuno',
    addHashtagToGenerate: 'Inserisci un testo al tuo post per poter generare gli hashtag',
    addTextToGenerate: 'Inserisci alcune parole chiave per poter generare il tuo testo',
    nameCantSupass: 'Il nome non può superare 30 caratteri',
    selectObjectiveAndWrite: 'Scrivi un testo e seleziona un obiettivo per una maggiore accuratezza',
    selectProfile: 'Seleziona almeno un profilo social per poter pubblicare',
    selectMedia: 'Devi aggiungere almeno un media o un testo a tutti i post prima di pubblicare',
    somePostAreInError: 'Alcuni post mostrano errori, correggili prima di procedere',
    SentAnEmailForActivation:
      'Abbiamo inviato un’email al tuo indirizzo con il link per attivare il tuo account.\nPer sicurezza controlla anche la casella spam',
    SentEmailForPasswordRecovery:
      'Abbiamo inviato un’email al tuo indirizzo per recuperare la tua password.\nPer sicurezza controlla anche la casella spam',
    unableToFindMail: 'Email non trovata, impossibile procedere con la richiesta',
    acceptTerm: 'Accettazione dei termini di utilizzo obbligatoria',
    acceptPrivay: 'Accettazione della privacy policy obbligatoria',
    cannotLoadDraft: 'Caricamento delle tue bozze non riuscito',
    postDontExist: 'Il post non esiste',
    eliminationFaild: "L'eliminazione non è andata a buon fine",
    unknownError: 'Errore sconosciuto',
    insertPixlLeght: 'Inserire altezza e larghezza in pixel',
    errorOnChangePasseword: 'Errore nella modifica della password',
    allFildRequired: 'Tutti i campi sono obbligatori',
    didentChangedPlan: 'Attenzione, non hai effettuato nessun cambiamento al piano',
    canNotHaveNegativeQuantity: 'Non puoi avere quantità negative',
    invalidEmail: 'Email non valida',
    invalidPassword: 'Password non valida',
    invalidName: 'Nome non valido',
    authFaild: 'Autorizzazione rifiutata. Riprova di nuovo o scrivi all’assistenza',
    mailNotSended: "Errore durante l'invio dell'email di conferma",
    errorAccount: 'Account errato',
    confirmMail: 'Conferma la tua email per accedere a questa funzionalità',
    writeSomeThing: 'Scrivi qualcosa nel commento prima di aggiungerlo al post',
    noChangedReveald: 'Nessuna modifica rilevata',
    youFinishedYourTrial:
      'Hai terminato il periodo di prova gratuita. Conferma la tua email per accedere a questa funzionalità',
    toManyRequest: 'Too many requests',
    weSentAMail:
      'Abbiamo inviato un’email al tuo indirizzo con il link per attivare il tuo account.\nPer sicurezza controlla anche la casella spam.',
    downlordFaild: 'Download del file non riuscito.',
    autorizationFails: 'Autorizzazione rifiutata. Riprova di nuovo o scrivi all’assistenza.',
    obligatoryMedia: 'Media obbligatorio per il post di',
    obligatoryText: 'Testo obbligatorio per il post di',
    obligatoryTitle: 'Titolo obbligatorio per il post di',
    MaxMedia: 'possono pubblicare massimo',
    maxHashtag1: 'può inserire al massimo',
    maxHashtag2: 'hashtag nel testo del post',
    dontConsentFotoAndVideo: 'non consente la pubblicazione di foto e video nello stesso post',
    errorSelectable: 'Errore recupero dati',
    selectTimezoneFirst: 'Seleziona una timezone',
    addNameOfWorkspace: `Dai un nome al tuo ambiente`,
    addTimezoneOfWorkspace: 'Aggiungi una timezone per il tuo ambiente',
    completeFieldsFirst: 'Per favore compila i campi vuoti',
    descriptionMaxChars: 'consente una descrizione caratteri massima di',
    dontHavePermissionToPerformAction:
      "Non disponi dell'autorizzazione per eseguire questa azione. Autorizzazione necessaria: ",
    paymentDeclined: 'Pagamento è stato rifiutato',
    subscriptionPayment: "Errore durante il pagamento dell'abbonamento",
    folderAlreadyExists: 'La cartella esiste già in questo percorso',
    dontHavePermissionToAccessFeature: 'Non hai i permessi per utilizzare questa funzione',
    addAtLeastOneSocialAccount: 'Assicurati di aver aggiunto almeno un account social',
    retrieveDataFromSite: 'Errore nel ottenere i dati dal sito',
    selectAToneOfVoice: 'Seleziona un tono di voce prima',
    roleAlreadyExists: 'Nome ruolo già esistente',
    roleNotFound: 'Ruolo non trovato',
    validPassword: 'Inserisci una password valida',
    validName: 'Inserisci un nome valido',
    roleStillUsed: 'Il ruolo è assegnato ad altri collaboratori, cambia prima il loro ruolo.',
    needText: "richiede che sia presente del testo all'interno del post",
    needMedia: "richiede che sia presente del media all'interno del post",
    needTextOrMedia: "richiede che sia presente del testo o del media all'interno del post",
    needTextAndMedia: "richiede che sia presente del testo e del media all'interno del post",
    descriptionTooLong: (social, maxLength) =>
      `${social} non supporta una descrizione di questa lunghezza. \n (massimo ${maxLength})`,
    titleTooLong: (social, maxLength) =>
      `${social} non supporta un titolo di questa lunghezza. \n (massimo ${maxLength})`,
    emptyDraft: 'La bozza non può essere vuota!',
    emptyPost: 'Il post non può essere vuoto!',
    socialExist:
      'Uno o più profili social sono già presenti in un altro ambiente. Procedendo, verranno scollegati e collegati in questo ambiente.',
    noBusinessSocial: 'Non è presente nessun profilo Instagram Business associato ad una pagina Facebook.',
    missingAppsumoAuthCode: `query-param 'code' mancante. Sei sicuro di arrivare da Appsumo?`,
    genericErrorWhileFetchingLicenseKey: "Qualcosa è andato storto durante il recupero del codice della licenza",
    specificErrorWhileFetchingLicenseKey : "Il seguent errore è stato ricevuto durante il recupero del codice della licenza"
  },
  socialError: {
    maxPostToday: 'consente di pubblicare un massimo di post al giorno:',
    maxCharDescription: 'consente una descrizione con un massimo di caratteri:',
    maxCharTitle: '',
    obligatoryMedia: 'Media obbligatorio per il post di',
    maxMedia: 'permette di pubblicare un massimo di media pari a',
    formatType: 'consente i seguenti formati:',
    maxSizeImg: 'prevedere che le immagini siano sotto i',
    maxSizeVideo: 'prevedere che i video siano sotto i',
    minMaxVideoDuration: 'richiede che i video abbiano una durata compresa tra',
    videoCodec: 'consente i seguenti codec video:',
    minMaxVideoFps: 'richiede che gli fps (frame per secondo) siano compresi tra',

    xCarousel: `${commonToAll.socials.xTwitter} prevede che i carousel abbiano : massimo 4 media e non possano contenere video e gif allo stesso tempo`,
  },
  socialWarning: {
    minMaxImgWidth: 'potrebbe ridimensionare la tua Immagine se non  rientra nel range di larghezza di',
    rangeImgRatio: 'potrebbe ridimensionare la tua immagine se non rispetta il rapporto tra',
    specificImgRatio: '',
    minMaxVideoWidth: 'potrebbe ridimensionare il tuo video se non  rientra nel range di larghezza di',
    rangeVideoRatio: 'potrebbe ridimensionare il tuo video se non rispetta il rapporto tra ',
    specificVideoRatio: '',
    minMaxVideoFps: 'potrebbe modificare il video se  gli fps (frame per secondo) non sono compresi tra',
    rangeRatio: 'potrebbe ridimensionare il tuo video/immagine se non rispetta il rapporto tra',
    specificRatio: 'potrebbe ridimensionare il tuo video/immagine se non rispetta il seguente rapporto:',
    specificMultipleImgRatio: 'potrebbe ridimensionare la tua immagine se non rispetta i seguenti rapporti:',
    possibleWrongDisplay: ' potrebbe non far visualizzare bene il tuo video se non rispetta questi codec video:',
  },
  success: {
    settedTimezone: 'Timezone impostata con successo',
    eliminationSuccess: 'Eliminazione del post avvenuta con successo',
    eliminationDraftSuccess: 'Eliminazione della bozza avvenuta con successo',
    theModalCreated: 'Il modello è stato creato con successo',
    modalEliminated: 'Il modello è stato eliminato con successo',
    modalAdded: 'Il modello è stato aggiunto al tuo post',
    nameChanged: 'Nome cambiato con successo',
    generationInProgress: 'Generazione in corso...',
    socialProfileAdded: 'Profili social aggiunti con successo',
    eliminationSuccses: 'Eliminazione avvenuta con successo',
    envImgUpdated: 'Immagine dell’ambiente aggiornata con successo',
    envNameUpdated: 'Nome dell’ambiente aggiornato con successo',
    envCreated: 'Ambiente creato con successo',
    postPlanned: 'Post pianificato con successo',
    postSavedInDraft: 'Post salvato in bozza con successo',
    postGenerated: 'Post generato con successo',
    cancelazioneFolder: 'Cancellazione della cartella e file all’interno avvenuta con successo',
    passwordChanged: 'Password cambiata con successo',
    dataSaved: 'Dati salvati con successo',
    planCanceled: 'Abbonamento annullato con successo',
    nameModified: 'Il tuo nome è stato modificato con successo',
    creditCardAdded: 'Carta di credito aggiunta correttamente',
    weSendEmailWhithNewEmail:
      'Abbiamo inviato un’email al tuo nuovo indirizzo email.\nClicca il link al suo interno per confermare la nuova email',
    passworldUpdated: 'La tua password è stata aggiornata con successo',
    accountOf: 'account di',
    accountLinkedInOf: "L'account LinkedIn di",
    accountXOf: "L'account X di",
    accountYouTubeOf: "L'account YouTube di",
    accountTikTokOf: "L'account TikTok di",
    isUpdated: 'è stato aggiornato',
    profileSocialEliminated: 'Profilo social eliminato con successo',
    mailSended: 'Email di conferma inviata con successo',
    emailUpdated: 'Email aggiornata con successo',
    accontIsUpdated: {
      one: "L'account ",
      two: ' di ',
      three: ' è stato aggiornato ',
    },
    languageChanged: 'Lingua cambiata con successo',
    reportNameChanged: 'Nome del report cambiato con successo',
    reportLogoChanged: 'Logo del report cambiato con successo',
  },
  info: {
    shortening_link: 'Stiamo abbreviando il tuo collegamento, attendi...',
    copied_error_in_clipboard:
      "Errore copiato in clipboard, contatta l'assistenza via chat e incolla il testo appena copiato",
    exit_without_confirmation: 'Sei uscito senza confermare',
    insert_at_least_4_words: 'Inserisci almeno 4 parole',
    selectReason: 'Perfavore seleziona un motivo',
    onlyDemo: 'Questa è solo una demo',
    cancelOrConfirmPin: 'Devi confermare o cancellare il pin precedente',
    userNotFoundInstagram: `Utente non trovato su ${commonToAll.socials.instagram} perfavore riscrivere il nome utente`,
    haveToEnterUsername: 'Devi inserire un nome utente',
    enterLink: 'Inserisci un link',
    generationAttempt: 'Nuovo tentativo di generazione in corso ...',
    blockerPreventing: 'Qualche blocker ci impedisce di generare su questo sito web',
    gettingDataFromSite: 'Ottenendo dati dal sito in corso...',
    roleUpdated: 'Ruolo aggiornato',
    roleCreated: 'Ruolo creato',
    linkCopied: 'Link copiato',
    onUser: 'su utente',
    userAlreadyExistsInCollaborator: 'Utente esiste già in collaboratori :',
    arrived: 'Arrivato',
    selectWorkspace: 'Seleziona un workspace',
    enterName: 'Inserisci un nome per il sito',
    enterTitle: 'Inserisci un titolo per il sito',
    enterDescription: 'Inserisci una descrizione per il sito',
    updating: 'Aggiornamento in corso',
    youHaveBeenInvitedToTheWorkspace: 'Sei stato invitato nel workspace',
    completeYourRegistrationOn: 'Completa la tua registrazione su',
    register: 'Registrati',
  },
  warning: {
    comingSoon: 'In arrivo presto',
    undefined: 'Undefined',
    confirmOrCancelBeforeAddingTag: 'Conferma la azione o annullala prima di aggiungere un altro tag',
    linkAlreadyPresent: 'Questo link è già presente',
    cantCommentTextAlreadyCommented: 'Non puoi commentare una parte di testo già commentata',
    problemSettingMetadata: "C'è stato un problema nel cambiamento dei metadati",
    notValidUrl: 'Il link che hai inserito non è valido',
  },
  loading: {
    loadingCompleted1: 'Caricamento completato,',
    loadingCompleted2: 'attendi qualche secondo',
  },
  messages: {},
  placeholder: {
    logInWithFacebook: 'Accedi con Facebook',
    logInWithGoogle: 'Accedi con Google',
    subscribeInWithFacebook: 'Iscriviti con Facebook',
    subscribeInWithGoogle: 'Iscriviti con Google',
    dontHaveAnAccountYet: 'Non hai ancora un account?',
    alreadyHaveAnAccount: 'Hai già un account?',
    subscribeNow: 'Iscriviti ora!',
    iHaveReadUnderstoodAndAcceptedThe: ' Ho letto, compreso e accettato',
    siteName: 'Nome del sito (domain)',
    siteTitle: 'Titolo del sito',
    siteDescription: 'Descrizione del sito',
  },
  articles: {
    the: 'il',
    there: 'la',
  },
  confirm: {},
  labels: {
    remember: 'Ricordami',
    recoverPassword: 'Password dimenticata?',
    signIn: 'Accedi',
    subscribe: 'Iscriviti',
    orLogInUsing: 'Oppure accedi utilizzando',
    OrSignUpUsing: 'Oppure iscriviti utilizzando',
  },
  status: {
    all: 'Tutti',
    notPublished: 'Non pubblicati',
    draft: 'Bozza',
    planned: 'Pianificato',
    error: 'Errore pubblicazione',
    published: 'Pubblicato',
    publishing: 'Pubblicazione in corso',
  },
  analyticsPage: {
    tableGraphEmptyState: 'Non ci sono dati da visualizzare per il periodo selezionato',
    tableGraphEmptyStateLimit:
      'Il limite orario imposto dal social network è stato raggiunto \n Per favore, riprova più tardi',
    analytics: 'Analytics',
    myReports: 'I miei report',
    yourReportNotice: 'Il tuo report',
    yourReportNoticeIsReady: 'è pronto!',
    currentlyDevelopingComingSoon: 'Al momento stiamo sviluppando questa sezione e sarà in arrivo presto!',
    visitRoadMap:
      'Per monitorare lo stato di sviluppo, votare le funzioni che stiamo considerando o in programma, e suggerire tu stess* nuove funzioni utili, puoi visitare la nostra roadmap pubblica cliccando sul pulsante qui sotto. \n  Ci teniamo molto alla tua opinione e organizziamo gli sviluppi futuri anche in funzione delle richieste più votate tra tutti gli utilizzatori di Asters. ',
    publicRoadMap: 'Roadmap pubblica',
    addMetric: 'Aggiungi metrica',
  },
  indexPage: {
    index: 'Index',
    currentlyDevelopingComingSoon: 'Al momento stiamo sviluppando questa sezione e sarà in arrivo presto!',
    visitRoadMap:
      'Per monitorare lo stato di sviluppo, votare le funzioni che stiamo considerando o in programma, e suggerire tu stess* nuove funzioni utili, puoi visitare la nostra roadmap pubblica cliccando sul pulsante qui sotto. \n  Ci teniamo molto alla tua opinione e organizziamo gli sviluppi futuri anche in funzione delle richieste più votate tra tutti gli utilizzatori di Asters. ',
    publicRoadMap: 'Roadmap pubblica',
  },
  mediaPage: {
    showResults: 'Mostra risultati',
    images: 'Immagini',
    fromLatest: 'Dal più recente',
    addFilters: 'Aggiungi filtri',
    deselectAll: 'Deseleziona tutto',
    typeHere: 'Scrivi qui',
    openInEditor: 'Apri in editor',
    warningTagEditor: "Si sta per ridimensionare un'immagine con tag, se si continua si perderanno i tag dell'immagine",
    media: 'Media',
    loadFile: 'Carica',
    createFolder: 'Crea cartella',
    openEditor: 'Apri Editor',
    storageSpaceAvailable: 'Spazio storage:',
    storageSpaceUsed: 'utilizzati di',
    folders: 'Cartelle',
    file: 'File',
    rename: 'Rinomina',
    canModifyOneAtTime: "Puoi modificare solo un'immagine alla volta",
    move: 'Sposta',
    instal: 'Scarica',
    openProject: 'Apri progetto',
    selectAProject: 'Seleziona un progetto',
    selectATemplate: 'Seleziona un template',
    selectSocial: 'Scegli un social',
    selectFormat: 'Scegli un formato',
    create: 'Crea',
    where_you_want_to_move_file: 'Dove vuoi spostare i tuoi file selezionati ?',
    where_you_want_to_move_folder: 'Dove vuoi spostare il tuo folder selezionato ?',
    askDelete: 'Sei sicur* di voler eliminare?',
  },

  draftModal: {
    yourDrafts: 'Le tue bozze',
    noDraft: 'Nessuna bozza',
    noDateInserted: 'Nessuna data inserita',
  },

  calendarPage: {
    calendar: 'Calendario',
    queue: 'Coda',
    profiles: 'Profili',
    noProfiles: 'Nessun profilo',
    noPostsOnThisDay: 'Nessun post in questo giorno',
    manual: 'Manuale',
    autopilot: 'Autopilot',

    reuse: 'Riutilizza',
    preview: 'Anteprima',
    previewInProgress: 'Anteprima in corso',
    sureToRemovePost: 'Sei sicur* di voler eliminare questo post?',
    goToPlaned: 'Vai alle bozze',
    weekdaysMin: ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],
    months: [
      'Gennaio',
      'Febbraio',
      'Marzo',
      'Aprile',
      'Maggio',
      'Giugno',
      'Luglio',
      'Agosto',
      'Settembre',
      'Ottobre',
      'Novembre',
      'Dicembre',
    ],
    today: 'Oggi',
    selectAll: 'Seleziona tutti',
    deselectAll: 'Deseleziona tutti',
    cancel: 'Annulla',
  },
  DashboardPage: {
    createSocial: 'Connetti social',
    dashboard: 'Dashboard',
    planedPost: 'Post pianificati',
    publishedPost: 'Post pubblicati',
    notPublishedPost: 'Post non pubblicati',
    generatedText: 'AI caption generate',
    generatedHashtag: 'Hashtag AI generate',
    generatedImage: 'AI immagini generate',
    connectedSocials: 'Profili social collegati',
    socialPosts: 'Post social',
    teamMember: 'Membri del team',

    increaseLimit: 'Aumenta limite',
    teamMembers: 'AI analytics',
    discoverArticle: 'Articoli AI discover trovati',
    comingSoon: 'Coming soon',
    loadFile: 'Carica File',
    addSocial: 'Seleziona social',
    draft: 'Bozze',
    enviromentManager: 'Gestisci ambiente',
    accountManager: 'Gestisci account',
    blog: 'Blog',
    guideAndTutorial: 'Centro assistenza',
    community: 'Community',
    publicRoadMap: 'Roadmap pubblica',

    yourEnvironment: 'Il tuo ambiente',
    yourOrganization: 'La tua organizzazione',
    // -----------------------------------------------
    video_review: 'Video recensione',
    tell_us_what_you_think: 'Raccontaci cosa ne pensi di Asters in una video recensione e',
    earn_50: 'guadagna €50',
    lets_go: 'Andiamo!',

    receve_from_global: 'Ricevi da globale',
    data_and_time: 'Data e ora:',
    group_name_cant_be_empty: 'Il nome del gruppo non può essere vuoto',
    group_text_cant_be_empty: 'Il testo del gruppo non può essere vuoto',
    home: 'Home',
    step: 'Step',
    select_at_least_one_social_to_add: 'Seleziona almeno un social da aggiungere',
    select_your_preview_link: 'Seleziona il tuo link di anteprima',
    upload_image: 'Carica immagine',
    upload_media: 'Carica media',

    are_you_sure: 'Sei sicuro/a di voler collegare un social? Tutte le modifiche del post corrente andranno perse',
    cancel: 'Annulla',
    yes_i_am_sure: 'Si, confermo',
  },
  AiDiscoverPage: {
    titlePage: 'Discover',
    placeholderPageText:
      'Perdere ore a cercare idee su Google è il passato. Con AI Discover in 1 click puoi trovare nuove idee di contenuti in trend sugli argomenti di cui vuoi parlare e monitorare le comunicazioni dei tuoi competitors.',
    placeholderLoadingText: 'Stiamo verificando le tue autorizzazioni',
    placeholderNoPermissions: "Non hai le autorizzazioni necessarie, contatta l 'admin del workspace",
    word: {
      topic: 'Topic',
      source: 'Fonte',
      private: 'Privata',
      pluralPrivate: 'Private',
      shared: 'Condivisa',
      pluralShared: 'Condivise',
      search: 'Cerca',
      comment: 'Commenta',
    },
    deleteFilters: 'Rimuovi filtri',
    addComment: 'Inserisci il tuo commento',
    lastUpdate: 'Ultimo aggiornamento: ',
    loadArticles: 'Carica altri 24 articoli',
    boardCard: {
      createBoard: 'Crea board',
      saveOnBoard: 'Salva in board',
      adviseNewBoard: 'Premi nuova board per creare',
      adviseSearchBoardNotFind: 'La board cercata non esiste',
      articles: 'Articoli',
      nameBoard: 'NomeBoard',
      createdBy: 'Creata da:',
      newBoard: 'Nuova Board',
      yourBoard: 'Le tue Board',
      noBoardText: 'Nessuna board disponibile',
      yourFistBoard: 'La tua prima board',
      createNewBoard: 'Crea la tua prima board',
      selectionAll: 'Seleziona tutti',
      deselectAll: 'Deseleziona tutti',
      Manage: 'Gestisci',
      Delete: 'Elimina',
    },
    boardCardSucces: {
      createBoard: 'Board creata con successo',
      saveArticle: 'Articolo salvato con successo',
    },
    boardCardError: {
      createBoard: 'Errore creazione board.',
      createComment: 'Errore nella creazione del commento',
      saveArticle: 'Errore salvataggio articolo nella board:',
    },
    boardCardWarning: {
      createDoubleBoard: 'Non puoi creare due board con lo stesso nome e di tipo',
      noComment: 'Non puoi commentare una parte di testo già commentata',
      cantCreateBoardPrivate: 'Non puoi creare una board privata in questo workspace',
    },
    carousel: {
      trendingArticles: 'Articoli in trend',
      trendingArticleFor: 'Articoli in trend per',
      noTrendsAvailable: 'Nessun trend disponibile',
      noBoardsAvailable: 'Nessuna board disponibile',
      followYourFirstTopic: 'Segui il tuo primo argomento',
      createYourFirstBoard: 'Crea la tua prima board',
      yourBoards: 'Le tue boards',
      showAllArticles: 'Mostra tutti gli articoli',
      showAllBoards: 'Mostra tutte le board',
      refreshArticles: 'Aggiornare gli articoli',
    },
    followPage: {
      followArticle: 'Topic seguiti',
      followArticleFor: 'Articoli seguiti per',
      noFollowAvailable: 'Nessun argomento seguito',
      followYourFirstTopic: 'Segui il tuo primo argomento',
    },
    boardPage: {
      noArticle: 'Nessun articolo salvato',
      SearchFirstArticle: 'Cerca il tuo primo articolo',
    },
    linkCopiedInClipboard: 'Link copiato in clipboard',
    copyLink: 'Copia Link',
    readFullArticle: 'Leggi articolo intero',
    imageNotFound: 'Immagine anteprima non disponibile',
  },
  postPage: {
    username: 'Nome utente',
    usernameRequired: 'Il nome utente è obbligatorio',
    yourText: 'Il tuo testo',
    noMediaInserted: 'Nessun media inserito',
    noTextInserted: 'Nessun testo inserito',
    confirmPostElimination: 'Confermi di voler proseguire nell’eliminare il post?',
    confirmPostEdit:
      "Al momento è già presente un post in lavorazione all'interno dell'area di posting.\nProcedendo con la modifica di questo specifico post, perderai il lavoro in lavorazione.",

    labelsSection: {
      applyLabels: 'Applica etichette',
      createOrApplyLabel: 'Crea etichetta o applica etichetta esistente',
      alreadyAdded: "L'etichetta è già presente tra quelle selezionate",
      addHere: 'Inserisci qui',
      noLabelIsPresent: 'Non è presente alcuna etichetta',
      manageLabels: 'Gestisci etichette',
      labelRename: (min, max) => `L'etichetta deve contenere almeno ${min} lettera e massimo ${max} caratteri`,
    },
    noMedia: 'Nessun media',
    noText: 'Nessun testo',
    changeDescription: 'Scrivi qui la tua descrizione (facoltativo)',
    changeDescriptionYouTube: 'Descrizione',
    daysAgo: 'giorni fa',
    more: 'altro',
    utm_road: 'UTM',
    whatAreUtm: 'Cosa sono gli UTM?',
    expand_link: 'Espandi link',
    short_link: 'Short link',
    your_workspace: 'I tuoi ambienti',
    currentWorkspace: 'Ambiente attuale',
    select_the_workspace: 'Seleziona l’ambiente in cui vuoi spostare il profilo social',
    switch_accounts: 'Sposta profilo',
    switch_accounts_success: 'Profilo social spostato con successo',
    instagramStories: {
      warning: {
        maxStoriesMedia: 'Puoi selezionare solo un media alla volta per le Stories',
      },
    },

    generalExtra: {
      noCarouselInstagramLocation: 'Non è possibile inserire un luogo in un post carosello di Instagram',
      addAImageForUseTheTags: "Aggiungi un'immagine per poter utilizzare i tag",
      noTagsForVideos: 'Non è possibile inserire tag in un video',
    },

    instagramExtra: {
      previewDisplayInProfileFeed: 'Anteprima di visualizzazione nel feed del profilo',
    },

    tiktokExtra: {
      follower: 'Tutti',
      friends: 'Amici',
      private: 'Privato',
      public: 'Pubblico',
      duet: 'Duetto',
      confirmedTimestamp: 'Copertina confermata con successo',
      whoCanWatchThisVideo: 'Chi può guardare questo video',
      allowUsersTo: 'Permetti agli utenti di:',
      discloseVideoContent: 'Divulga il contenuto del post',
      letOthersKnow: 'Fai sapere agli altri che questo post promuove un brand, un prodotto o un servizio.',
      yourBrand: 'Il tuo brand',
      promoteYourselfOrBusiness: 'Stai promuovendo te o la tua attività.',
      brandedContent: 'Contenuti brandizzati',
      inPaidPartnership: 'Sei in una partnership retribuita con un brand.',
      toDisclose: 'Per divulgare il tuo post come contenuto brandizzato, impostalo su "Pubblico".',
      brandedContentCantBePrivate: 'I post con contenuti brandizzati non possono essere impostati come privati.',
    },
    youtubeTubeExtra: {
      privacy: 'Privacy*',
      public: 'Pubblico',
      private: 'Privato',
      unlisted: 'Non in lista',
      title: 'Titolo',
      enterTitle: 'Inserisci un titolo',
      description: 'Descrizione',
      enterDescription: 'Inserisci una descrizione',
      category: 'Categoria*',
      selectCategory: 'Seleziona categoria',
      audience: 'Pubblico*',
      isSuitableForChildren: 'Adatto ai bambini',
      advancedOptions: 'Impostazione avanzate',
      playlist: 'Playlist',
      selectPlaylist: 'Seleziona playlist',
      singlePlaylistSelected: 'playlist selezionata',
      multiplePlaylistSelected: 'playlist selezionate',
      extras: 'Extras',
      thumbnail: 'Miniatura',
      addThumbnail: 'Aggiungi miniatura',
      thumbnailConfirm1: 'Vuoi modificare la miniatura selezionando un frame dal video?',
      thumbnailConfirm2: 'La precedente immagine verrà deselezionata. ',
      conferma: 'Conferma',
      tag: 'Tag',
      enterTag: 'Inserisci qui i tuoi tag',
      embeddable: 'Embeddabile',
      notifySubscribers: 'Notifica abbonati',
      adultsOnly: 'Solo adulti',
      subtitles: 'Sottotitoli',
      addSubtitles: 'Aggiungi sottotitoli',
    },
    you_are_adding: 'Stai caricando più di 50MB, vuoi procedere?',
    confirm: 'Confermo',
    // -----------------------------------------------
    lengthGeneration: 'Lunghezza generazione',
    textShort: 'Testo troppo corto',
    textMedium: 'Testo medio',
    textLong: 'Testo troppo lungo',

    // -----------------------------------------------

    addMediaFirst: 'Seleziona prima un media',
    savedSucces: 'Caption aggiunta con successo',
    saveDraft: 'Salva bozza ',
    carousel: 'Selezionati',
    editor: 'Editor',
    extra: 'Extra',
    socials: 'Socials',
    addComent: 'Aggiungi commento...',
    likes: 'Mi piace',
    comments: 'Commenti',
    viewAll: 'Vedi tutti',
    like: 'Mi piace',
    comment: 'Commento',
    share: 'Condividi',
    addComment: 'Aggiungi un commento...',
    eightComments: '8 commenti',
    download: 'Scarica',
    visualizations: '1000 visualizzazioni 12 mesi fa',
    reposts: 'Reposts',
    send: 'Invia',
    suggestedDate: 'Orari suggeriti',
    loadingData: 'Caricamento dati...',
    under100Follower: 'Il profilo Instagram deve avere almeno 100 followers',

    difficultyText: {
      dataSet: {
        basic: 'Livello base per chi sta ancora imparando a leggere.',
        easy: 'Molto facile da leggere.',
        ideal: 'Ideale per lettori di livello medio.',
        fairly: 'Abbastanza difficile da leggere.',
        hard: 'Troppo difficile da leggere per la maggior parte dei lettori.',
      },
      approximate: 'Si tratta di un livello approssimativo di comprensione del post, basato su',
      automated: 'indice di leggibilità automatico',
      it: "Corrisponde grosso modo alle seguenti fasce d'età:",
      grade: 'Grado',
      age: 'Età',
      description: 'Descrizione',
      copywriters: 'Copywriter e i giornalisti mirano in genere a un livello di qualità di',
      lower: '8 o inferiore.',
      this: 'This is a good measuring stick for social media posts as well.',
    },
    sfortunatly_we_cant:
      "Nota bene: Instagram non consente di utilizzare l'autocompilazione dei tag o taggare profili privati. Scrivi l'username corretto e funzionerà.",

    freeTrial: 'Prova gratuita: ',
    remainingDayes: ' giorni rimasti',
    generationAi: 'Genera',
    update: 'Aggiorna',
    cancel: 'Annulla',
    createGroup: 'Crea gruppo',
    reel: 'Reel',
    yourDrafts: 'Le tue bozze',
    sureToDeleteDraft: 'Sei sicur* di voler eliminare questa bozza?',
    createPost: 'Post',
    showFullLink: 'Espandi',
    shortFullLink: 'Accorcia',
    publicNowPost: 'Pubblica ora',
    textOfYourPost: 'Testo del tuo post',
    insertHereText: 'Inserisci qui il testo del tuo post',
    insertHereTextYouTube: 'Titolo',
    textGeneration: 'Generazione testi AI',
    hashtagGeneration: 'Generazione hashtag AI',
    insertTextToGenerateText: "Inserisci qui alcune parole chiave sull'argomento di cui vuoi parlare",
    insertLinkToGenerateText: 'Inserisci qui il link',
    generate: 'Genera',
    models: 'Modelli',
    labels: 'Etichette',
    writeIn: 'Scrivi in',
    generateIn: 'Genera in',
    visibility: 'Visibilità',
    event: 'Evento / Festività',
    utility: 'Info utili',
    promo: 'Promozione / Sconto',
    contest: 'Contest / Giveaway',
    media: 'Media',
    text: 'Testo',
    add: 'Aggiungi',
    delete: 'Elimina',
    all: 'Tutti',
    caption: 'Caption',
    hashtag: 'Hashtag',
    modalName: 'Nome del modello',
    modeltype: 'Tipologia modello',
    modalText: 'Testo del modello',
    createModal: 'Crea modello',
    search: 'Cerca',
    public: 'Pubblica',
    publicNow: 'Pubblica ora',
    clearPost: 'Pulisci post',
    plan: 'Pianifica',
    saveInDraft: 'Salva in bozze',
    dateAndTime: 'Giorno e ora',
    social: 'Social',
    selectDateAndTime: 'Scegli giorno e ora',
    objective: 'Obiettivo',
    generationRemaind: 'Generazioni AI rimanenti',
    fromReelToPost: 'Reel modificato in post',
    fromPostToReel: 'Post modificato in reel',
    revealLanguage: 'Rileva lingua',
    language: {
      AR: 'Arabo',
      BG: 'Bulgaro',
      CS: 'Ceco',
      DA: 'Danese',
      DE: 'Tedesco',
      EL: 'Greco',
      EN: 'Inglese',
      ES: 'Spagnolo',
      ET: 'Estone',
      FI: 'Finlandese',
      FR: 'Francese',
      HU: 'Ungherese',
      ID: 'Indonesiano',
      IT: 'Italiano',
      JA: 'Giapponese',
      KO: 'Coreano',
      LT: 'Lituano',
      LV: 'Lettone',
      NB: 'Norvegese',
      NL: 'Olandese',
      PL: 'Polacco',
      PT: 'Portoghese',
      RO: 'Rumeno',
      RU: 'Russo',
      SK: 'Slovacco',
      SL: 'Sloveno',
      SV: 'Svedese',
      TR: 'Turco',
      UK: 'Ucraino',
      ZH: 'Cinese',
    },
    globalEdit: 'Modifica globale',
    basic: 'Base',
    advanced: 'Avanzata',
    texts: 'Testi',
    extraSelectNotAvailable: 'Non disponibili',
    extraFirstComment: 'Primo commento',
    collaborationsStatus: 'Stato collaborazioni',
    collaborators: 'Collaboratori',
    collaboratorsPlaceholder: 'Cerca una persona',
    collaboratorsInfo:
      'Se accetta, il suo nome utente sarà aggiunto al post. Il post sarà condiviso con i suoi follower e verrà mostrato sul suo profilo',
    alreadyPresentCollaborator: 'Questo collaboratore è già presente tra quelli selezionati',
    extraLocation: 'Luogo',
    extraPaidSponsor: 'Partnership pubblicizzata',
    extraBrandWarning: 'Inserisci un media per poter utilizzare il contenuto brandizzato ',
    extraBrand: 'Contenuto brandizzato',
    extraBrandTooltip:
      'Le pagine taggate nel post riceveranno una notifica. Potranno visualizzare le metriche del post e utilizzarle per creare un ad. È possibile scrivere il nome della pagina sponsor oppure incollare la relativa URL',
    extraBrandPlaceholder: "Inserisci qui il nome o l'URL dello sponsor",
    extraLocationPlaceholder: 'Inserisci qui il luogo',
    extraTag: 'Tagga persone',
    createNewPost: 'Crea post',
    extraInsertHashtag: 'Inserisci hashtag nel primo commento',
    extraAddFirstContent: 'Aggiungi primo contenuto',
    draftAll: 'Tutti',
    draftPhotos: 'Foto',
    draftVideo: 'Video',
    draftText: 'Testo',
    fromLatest: 'Dal più recente',
    fromLeast: 'Dal meno recente',
    alphAZ: 'Alfabetico A-Z',
    alphZA: 'Alfabetico Z-A',
    searchSocial: 'Cerca social',
  },
  textGenerator: {
    article: 'Articolo',
    subSection1: 'Professionale',
    subSection2: 'Amichevole',

    brandVoice: 'Brand voice',
    brandVoiceTooltip1:
      'Asters AI Brand Voice combina la potenza dell’AI con il tono e lo stile unico del tuo brand, in modo da generare contenuti precisi, accurati e on-brand.',
    brandVoiceTooltip2: 'Piattaforme social al momento supportate:',
    brandVoiceTooltip3:
      'Asters AI sta analizzando e apprendendo lo stile unico del tuo brand per ogni profilo social collegato. Questa fase potrebbe durare da qualche ora a qualche giorno.',
    brandVoiceTooltip4:
      'Brand Voice sintonizzato! Asters AI è pronta a generare contenuti precisi e accurati sul tuo brand. Man mano che vengono pubblicati nuovi post, l’AI di Asters continua ad apprendere ed aggiornarsi per essere sempre on-brand.',
    neutral: 'Neutro',
    textGenerator: 'Genera da keyword',
    linkGenerator: 'Genera da link',
    remainingGeneration: 'Generazioni rimanenti',
    optimalFor: 'Ottimale per',
    optimalForFbIg: `Ottimale per ${commonToAll.socials.facebook}/${commonToAll.socials.instagram}`,
    selectToneOfVoice: 'Seleziona un tone of voice',
    selectObjective: 'Seleziona un obiettivo',
    goal: 'Obiettivo',
    feedbackModalTitle: 'Cosa non ti è piaciuto della generazione?',
    feedbackPlaceholder: 'Non mi è piaciuta la generazione perchè ... ',
    feedbackBrandVoiceDislike: 'Cosa non ti è piaciuto del Brand Voice?',
    feedbackBrandVoiceLike: 'Cosa ti è piaciuto del Brand Voice?',
    enterHere: 'Inserisci qui',
    sendFeedback: 'Invia feedback',
    thanksForFeedback: 'Grazie per il feedback!',
    somethingWentWrong: 'Ops, qualcosa È andato storto',
    short: {
      tweet: {
        generic: 'Generico',
        tweet: 'Tweet',
        humSatirical: 'Umoristico / Satirico',
        interrogative: 'Interrogativo',
        news: 'Notizie',
        inspMotivation: 'Ispirazione / Motivazione',
      },
      contest: {
        contest: 'Contest',
        engaging: 'Coinvolgente',
        creative: 'Creativo',
        competitive: 'Competitivo',
        friendly: 'Amichevole',
      },
      prodEvent: {
        prodEvent: 'Prodotto / Evento',
        explicative: 'Esplicativo',
        info: 'Informativo',
        formal: 'Formale',
        creative: 'Creativo',
      },
      quotes: {
        quotes: 'Quotes',
        inspiration: 'Ispirazione',
        funny: 'Divertente',
        motivational: 'Motivazionale',
        educational: 'Educativo',
      },
    },
    medium: {
      discPromotion: {
        discPromotion: 'Sconto / Promozione',
        inspiration: 'Ispirazione',
        funny: 'Divertente',
        descriptive: 'Descrittivo',
        professional: 'Professionale',
      },
      sesonHolidays: {
        sesonHolidays: 'Stagionale /Festività',
        enthPlayful: 'Entusiasta / Giocoso',
        celebFestive: 'Festoso / Celebrativo',
        infoEducational: 'Educativo / Informativo',
        gratitude: 'Gratitudine / Riconoscenza',
      },
      contestGiveaway: {
        contestGiveaway: 'Contest / Giveaway',
        enthusiastic: 'Entusiasta',
        engaging: 'Coinvolgente',
        funny: 'Divertente',
        exclusive: 'Esclusivo',
      },
      highProduct: {
        highProduct: 'Highlight Prodotto / Servizio',
        inspiration: 'Ispirazione',
        funny: 'Divertente',
        descriptive: 'Descrittivo',
        professional: 'Professionale',
      },
      tipsInfo: {
        tipsInfo: 'Tips / Info Utili',
        infoEducational: 'Educativo / Informativo',
        friendly: 'Amichevole',
        explicative: 'Esplicativo',
        professional: 'Professionale',
      },
    },
    longer: {
      informative: {
        informative: 'Informativo',
        institutional: 'Istituzionale',
        objective: 'Obiettivo',
        technician: 'Tecnico',
        thoughtful: 'Riflessivo',
      },
      educative: {
        educative: 'Educativo',
        technician: 'Tecnico',
        motivational: 'Motivazionale',
        informative: 'Divulgativo',
        academic: 'Accademico',
      },
      reflexive: {
        reflexive: 'Riflessivo',
        inspiration: 'Ispirazione',
        authorative: 'Autorevole',
        metaphorical: 'Metaforico',
        motivational: 'Motivazionale',
      },
      promotion: {
        promotion: 'Promozione',
        professional: 'Professionale',
        explicative: 'Esplicativo',
        persuasive: 'Persuasivo',
        emotional: 'Emotivo',
      },
    },
  },
  emoji: {
    suggested: 'Suggerite',
    smileys_people: 'Emoticon e persone',
    animals_nature: 'Animali e natura',
    food_drink: 'Cibo e bevande',
    travel_places: 'Viaggi e luoghi',
    activities: 'Attività e sport',
    objects: 'Oggetti',
    symbols: 'Simboli',
    flags: 'Bandiere',
  },
  courtesy: {
    subscriptionExpired: 'Abbonamento Scaduto',
    title: 'Oh - oh!',
    description: 'Il tuo abbonamento è scaduto',
    cta: "Contatta l'assistenza",
  },
  settings: {
    API: 'API',
    apiButton: 'API',
    remove_user_from_workspace: 'Rimuovi utente dal workspace',
    remove_user_from_workspace_confirm: 'Sei sicuro di voler rimuovere questo utente dal workspace?',
    unlimited: 'Illimitate',
    addImage: 'Aggiungi immagine',
    addTimezone: 'Aggiungi timezone',
    defoutWorkspaceNotOwner: '(Ambiente condiviso)',
    astersAi: 'Asters.ai',
    version: 'Versione',
    lastUpdate: 'Ultimo Aggiornamento',
    nextRenewal: 'Prossimo rinnovo',
    update: 'Aggiorna',
    you_have_to_accept_the_privacy_policy: 'Devi accettare la privacy policy',
    you_have_to_buy_at_least_1_test: 'Devi acquistare almeno 1 testo',
    iva_excluded: 'IVA esclusa',
    obtained: '€ guadagnati',
    missing: '€ mancanti',
    you_don_have_any: 'Non hai nessun profilo social da controllare',
    popEnvConfirm1: 'Sei sicur* di voler eliminare questo ambiente?',
    popEnvConfirmAccount: 'Sei sicur* di voler eliminare questo account?',
    popEnvConfirm2: 'Verranno cancellati definitivamente tutti i post',
    popEnvConfirm3: 'passati, pianificati, le bozze e i media.',
    popEnvConfirm4: 'Questa azione è irreversibile.',
    deleteEnv: 'Elimina ambiente',
    applica: 'Applica',
    sureToCancelPlan: "Sei sicur* di voler annullare l'abbonamento?",
    mode: 'Modalità',
    differenceToPayNow: 'Differenza da saldare subito',
    addAll: 'Aggiungi tutti',
    settings: 'Impostazioni',
    account: 'Account',
    profileSocial: 'Profili social',
    environment: 'Ambiente',
    subscription: 'Piano',
    accountData: 'Dati account',
    yourMail: 'La tua email',
    yourName: 'Il tuo nome',
    yourPassword: 'La tua password',
    selectLanguage: 'Seleziona lingua',
    logOut: "Esci dall'account",
    deleteAccount: 'Elimina account',
    visualLightDark: 'Visuale chiara / scura',
    manageCode: 'Gestisci coda',
    manageNotifications: 'Gestisci notifiche',
    savedChangedNotify: 'Modifiche salvate con succcesso',
    notSavedChangedNotify: 'Salva le modifiche prima di tornare indietro',
    addSocial: 'Aggiungi social',
    addEnv: 'Crea ambiente',
    selectSocialCreatePost: 'Seleziona social',
    defoutWorkspace: '(Il tuo ambiente personale)',
    searchSocial: 'Cerca social',
    modify: 'Modifica',
    confirm: 'Conferma',
    confirmModify: 'Conferma modifiche',
    createYourEnv: 'Crea il tuo ambiente',
    searchEnv: 'Cerca ambiente',
    nameEnv: 'Nome ambiente',
    save: 'Salva',
    saveTheModify: 'Salva le modifiche',
    goOutWhitautSaving: 'Esci senza salvare',
    saveThePassword: 'Salva password',
    modifyPlan: 'Modifica piano',
    expireIn: 'In scadenza',
    addOn: 'Add-on',
    modifyAddOn: 'Modifica add-on',
    costAddOn: 'Importo add-on ',
    fromPlan: 'Dal piano:',
    extra: 'Extra:',
    total: 'Totali:',
    addCreditAi: 'Aggiungi crediti AI (intelligenza artificiale)',
    generationUsed: 'Generazioni AI utilizzate',
    zeroOn: ' su ',
    extraTextGenPrice: '10 generazioni testi AI extra a soli 3€',
    extraHashGenPrice: '10 generazioni hashtag AI extra a soli 2€',
    faturationDetail: 'Dettagli di fatturazione',
    modifyYourData: 'Modifica i tuoi dati',
    paymentDetails: 'Dettagli di pagamento',
    nameSurnameAccountholder: 'Carta di credito o debito',
    private: 'Privato',
    iva: 'P.iva',
    folderName: 'Nome cartella',
    surname: 'Cognome',
    completeAddress: 'Indirizzo completo',
    fiscalCode: 'Codice fiscale',
    addGeneration: 'Aggiungi generazioni AI',
    cancelPlan: 'Annulla abbonamento',
    customization: 'Personalizzazione',
    includedInPlan: 'Incluso nel piano',
    yourPlan: 'Il tuo piano',
    saveThe: 'Risparmia il ',
    freeMonths: 'mesi gratis',
    yourClientSocial: 'GESTISCI I SOCIAL DEI TUOI CLIENTI',
    annual: 'Annuale',
    mensil: 'Mensile',
    annualPayment: 'Pagamento annuale ',
    continue: 'Continua',
    upgrade: 'Upgrade',
    do_upgrade: "Effettua l'upgrade",
    downgrade: 'Downgrade',
    forYourSocialAndActivity: 'PER I TUOI SOCIAL O PER LA TUA ATTIVITÀ',
    youArePaying: 'Stai pagando:',
    youWillPay: 'Pagherai:',
    personalize: 'Personalizzazione',
    year: 'anno',
    month: 'mese',
    paymentDone: 'Abbonamento attivato con successo!',
    wantToAddAi: 'Vuoi aggiungere crediti AI?',
    paymentMothly: 'Pagamento mensile',
    payment: 'Pagamento',
    ragioneSociale: 'Ragione sociale',
    partitaIva: 'Partita iva',
    CodiceSdiOrPEC: 'Codice SDI o PEC',
    youPaid: 'Pagavi',
    youWllPayNextUpdate: 'Pagherai al prossimo rinnovo',
    importedAt: 'importo al',
    summary: 'Riepilogo',
    createWorkspace: 'Crea workspace',
    corispondTo: 'corrisponde a ',
    yourPlamWillBeActivated1: 'Il tuo piano verrà attivato non appena riceviamo il pagamento il ',
    yourPlamWillBeActivated2: "rinnovato in automatico alla scadenza. L'abbonamento non è rimborsabile.",
    yourPlamWillBeActivated3:
      "Il tuo piano verrà attivato non appena riceviamo il pagamento e sarà rinnovato in automatico alla scadenza. L'abbonamento non è rimborsabile.",
    willAPliedNextUpdate:
      ' Le modifiche si applicheranno al prossimo rinnovo. Fino ad allora potrai continuare ad usufruire degli add-on e dei vantaggi del tuo piano corrente.',
    selectAmbientation: 'Seleziona ambiente',
    socialToConnect: 'Quali profili social vuoi collegare?',
    setEnv: 'Assegna ad un ambiente',
    notSeeingProfiles1: 'Non vedi il tuo profilo social?',
    notSeeingProfiles2: 'Se non vedi il profilo che vorresti collegare, consulta ',
    notSeeingProfiles3: 'le nostre guide',
    notSeeingProfiles4: "sul collegamento profili social o scrivi all'assistenza",
    me: '€/mese',
    planCost: 'Importo piano',
    confirmPrivacy1: 'Confermo di aver letto e preso visione dei',
    confirmPrivacy2: 'termini di utilizzo',
    subscribe: 'Sottoscrivi',
    buy: 'Acquista',
    creditAi: 'Crediti AI',
    yourData: 'I tuoi dati',
    standard: 'Standard',
    changePlan: 'Modifica piano',
    creditsAvailabe: 'Crediti disponibili',
    goAnnual: 'Passa all’annuale',
    earnMoreCredits: 'Guadagna altri crediti',
    yourMedia: 'I tuoi media',
    caricaMedia: 'Seleziona Media',
    youHaveModifyNotsaved: 'Hai delle modifiche non salvate',
    howYouWannaProcced: 'Come desideri procedere?',
    cardWasentAproved: 'La tua carta non è stata confermata, riprova e autorizza il pagamento.',
    retry: 'Riprova',
    weAreProccesingThePayment: 'Stiamo effettuando il pagamento, attendi fino alla conferma',
    insertTextGenerateHash: 'Inserisci qui alcune parole chiave per generare gli hashtag',
    insert: 'Inserisci',
    insertSomeKeyWord: 'Inserisci qui alcune parole chiave',
    insertHereYourFeedBack: 'Inserisci qui il tuo feedback',
    controlSsocial: 'Controlla connessione',
    pleaseSelectAReason: "Seleziona almeno un 'opzione",
    ivaPayment: 'Iva',
    cardNumberPlaceholder: 'Numero carta',
    cvcPlaceholder: 'CVC',
    expiryPlaceholder: 'MM/AA',
    invalidCardCvc: 'CVC non valido',
    invalidCardExpiry: 'Data di scadenza non valida',
    invalidCardNumber: 'Numero carta non valido',
    invalidExpiryDate: 'La data di scadenza non è valida',
    monthOutOfRange: 'Il mese di scadenza non è valido',
    yearOutOfRange: "L'anno di scadenza non è valido",
    dateOutOfRange: 'La  di scadenza non è valida',
    typeHere: 'Scrivi qui',
    coupon: 'Coupon',
    aplicate: 'Applica',
    eSara: 'e sarà ',
    difrance: 'Differenza',
    // da qui
    type_name: 'Inserisci il tuo nome',
    type_surname: 'Inserisci il tuo cognome',
    type_email: 'Inserisci la tua email',
    type_fiscalCode: 'Inserisci il tuo codice fiscale',
    type_password: 'Inserisci la tua password',
    type_password_again: 'Inserisci nuovamente la tua password',
    type_company_name: 'Inserisci il nome della tua azienda',
    type_company_vat: 'Inserisci la tua partita iva',
    type_sdi: 'Inserisci il tuo codice SDI',
    type_pec: 'Inserisci la tua PEC',
    type_phone: 'Inserisci il tuo numero di telefono',
    type_address: 'Inserisci il tuo indirizzo',
    type_city: 'Inserisci la tua città',
    type_cap: 'Inserisci il tuo CAP',
    type_country: 'Inserisci il tuo paese',
    type_sdi_or_pec: 'Inserisci il tuo codice SDI o PEC',
    type_coupon: 'Inserisci il tuo coupon',
    there_are: 'Ci sono',
    accounts_to_reconnect: 'account da ricollegare nel tuo attuale workspace',
    account_to_refresh: 'Non hai nessun account da refreshare',
    payment_success: 'Pagamento effettuato con successo',
    payment_error: 'Si è verificato un errore durante il pagamento',
    yourWorkspaces: 'I tuoi ambienti',
    searchWorkspace: 'Cerca ambiente',
    sharedWorkspaces: 'Ambienti condivisi',
    setTimeZone: 'Seleziona il fuso orario',
    whatsapp: 'Whatsapp',
    yourPhone: 'Il tuo numero',
    sendCode: 'Invia codice di verifica',
    resendCode: 'Invia nuovo codice',
    verifyCode: 'Verifica codice',
    noNumber: 'Non hai i ancora inserito un numero WhatsApp, aggiungilo per salvare le modifiche',
    otpSendSuccess: 'Ti abbiamo inviato un codice di verifica al numero inserito',
    otpSendError: "Errore nell'invio del codice di verifica",
    otpSuccess: 'Numero verificato con successo',
    otpError: 'Codice di verifica errato',
    otpJustVerified: 'Numero già verificato',
    otpExpired: 'Codice di verifica scaduto',
    tokenExpired: 'Token scaduto, accedi di nuovo',
    saveFor: 'Salva per:',
    allEnvironments: 'Tutti gli ambienti',
    thisEnvironment: 'Questo ambiente',
    allNotifications: 'Tutte le notifiche',
    apiSection: {
      noKeyPresent: 'Non sono presenti API key',
      API: 'API',
      deleteBtn: 'Elimina',
      generateApiBtn: 'Genera chiave API',
      documentationTitle: 'Documentazione API',
      readDocumentation: 'Leggi la documentazione API',
      docDescription:
        "L'API di Asters facilita per i programmatori l'integrazione di molte funzionalità di Asters in altre applicazioni.",
      docDescriptionLearnMore: 'Vuoi saperne di più?',
      table: {
        name: 'Nome',
        key: 'Chiave',
        createdTime: 'Data di creazione',
        createdBy: 'Creato da',
        scopes: 'Scopes',
      },
    },
  },
  header: {
    upgrade: 'Upgrade',
    click_for_more_info: 'Clicca per informazioni',
    increasePlan: 'Aumenta piano',
    leaveComment: 'Lascia una recensione',
    guideTutorial: 'Guide e tutorial',
    redBanners: [],
    yellowBanner: [],
    showAll: 'Mostra intero messaggio',
    convalidYourMail: 'Conferma la tua email per accedere a tutte le funzionalità',
    convalidYourMailInner: "Non hai ricevuto l'email di conferma del tuo account?",
    clickHereToReceveMail: 'Reinvia email di conferma',
    updateTheConnection: 'Aggiorna la connessione ai tuoi social per poter continuare a pianificare',
    whyHaveToUpdate: 'Perché devo aggiornare la connessione?',
    updateYourConnection: 'Alcuni tuoi profili social stanno per disconnettersi da Asters',
    youAccountIsExpired: 'Connessione scaduta',
    daysToExpire: ' giorni alla scadenza',
    updateNow: 'Aggiorna subito',
    weNoticed: {
      1: ' Congratulazioni per aver completato l’iscrizione ad Asters 🎉',
      2: 'Abbiamo inviato un’email al tuo indirizzo con il link per attivare il tuo account.',
      3: 'Per sicurezza controlla anche la casella spam.',
      4: "Se non dovessi aver ricevuto l'email di conferma, clicca sul pulsante qui sotto.",
    },
  },
  langauge: {
    eng: 'Inglese',
    ita: 'Italiano',
  },
  plans: {
    base: 'Base',
    complete: 'Completo',
    articles: 'Articoli',
    getDemo: 'Richiedi una call',
    alreadyInDemo: 'Hai già la Demo',
    annualVersionChipText: 'Versione annuale del tuo piano corrente',
    tooltips: {
      profileSocial: {
        note: '',
        title: 'Profili Social',
        text: 'Numero di profili social gestibili dal tuo account',
      },
      teamMembers: {
        note: '',
        title: 'Membri del Team',
        text: 'Centralizza la gestione degli utenti in Asters, assegnando ruoli e permessi specifici',
      },
      allSocial: {
        note: '',
        title: 'Tutti i Social',
        text: 'Asters al momento supporta Instagram, TikTok, Facebook, LinkedIn, X e YouTube',
      },
      extraStorage: {
        note: '',
        title: 'Spazio Storage',
        text: 'Spazio archiviazione per i tuoi media con infinite cartelle e sottocartelle',
      },
      generationText: {
        note: '',
        title: 'Generazione Testi',
        text: 'Asters AI genera caption formattate per i tuoi post social, in 27 lingue e decine di obiettivi e toni di voce',
      },
      whatsapp: {
        note: '',
        title: 'Notifiche WhatsApp',
        text: 'Ricevi notifiche personalizzate direttamente sul tuo numero WhatsApp',
      },
      generationImage: {
        note: '',
        title: 'Generazione Immagini',
        text: 'Asters AI genera immagini con diversi stili e pronte per i diversi formati social',
      },
      generationHashtag: {
        note: '',
        title: 'Generazione Hashtag',
        text: 'Il numero di generazione di hashtag che hai a disposizione',
      },
      analytics: {
        note: '',
        title: 'Analytics',
        text: 'Traccia e misura la tua crescita e vedi quali post generano maggior engagement',
      },
      aiDiscover: {
        note: '',
        title: 'AI Discover',
        text: 'Trova idee di contenuti in trend e monitora le comunicazione dei tuoi competitors',
      },
      inbox: {
        note: '',
        title: 'Inbox',
        text: 'Gestisci tutta la messaggistica social da un unico posto e fornisci un servizio eccezionale su scala',
      },
      bestTimeToPost: {
        note: '',
        title: 'Best Time to Post',
        text: 'Asters AI suggerisce i giorni e gli orari di pubblicazione su Instagram più performanti',
      },
      advertising: {
        note: '',
        title: 'Advertising',
        text: 'Rendi più incisivi i post organici aggiungendo budget pubblicitario per una maggiore visibilità',
      },
      quickSearch: {
        note: '',
        title: 'Quick Search',
        text: 'Ricerca immagini o video della tua libreria in Asters descrivendo il contenuto',
      },
      autoPilot: {
        note: '',
        title: 'Auto Pilot',
        text: 'Social media automation su scala. Crea flussi di lavoro personalizzati, riconquista tempo e incrementa la tua produttività',
      },

      advancedCalendar: {},
      saveInDraft: {},
      graficEditor: {},
      support: {},
      application: {},
      instagramReels: {},
    },
    professionist: {
      professional: 'Professionista',
      profileSocial: 'Profili Social',
      teamMembers: 'Membri del Team',
      allSocial: 'Tutti i social',
      extraStorege: 'Spazio di Archiviazione',
      generationText: "Testi Generati dall'AI",
      generationImage: "Immagini Generate dall'AI",
      advancedCalendar: 'Calendario editoriale',
      generationHashtag: "Set Hashtag generati dall'AI",
      saveInDraft: 'Salva post in bozze',
      graficEditor: 'Editor grafico completo',
      analytics: 'Analytics',
      support: 'Assistenza chat in 24h',
      application: 'App',
      instagramReels: `${commonToAll.socials.instagram} reels`,
      inbox: 'Inbox',
      aiDiscover: 'AI Discover',
      whatsapp: 'Notifiche WhatsApp',
      autoPilot: 'Autopilot',
      advertising: 'Advertising',
      bestTimeToPost: 'AI Scelta orario Post',
      quickSearch: 'AI Ricerca Rapida',
      extraSocialFeatures: 'Funzionalità social extra',
      offer: {
        text: 'OFFERTA DI LANCIO',
        type: 'annual',
        label: 'Professionista',
        slogan: 'Per il primo anno',
      },
    },
    corporate: {
      corporate: 'Azienda',
      profileSocial: '10 profili social',
      extraStorege: '10GB spazio storage',
      generationText: "40 testi generati dall'AI",
      advancedCalendar: 'Calendario editoriale',
      generationHashtag: "40 set di hashtag generati dall'AI",
      saveInDraft: 'Salva post in bozze',
      graficEditor: 'Editor grafico completo',
      analytics: 'Analytics (coming soon)',
      support: 'Assistenza chat in 12h',
      application: 'App (coming soon)',
      instagramReels: 'Instagram reels',
      inbox: 'Inbox (coming soon)',
      extraSocialFeatures: 'Funzionalità social extra',
    },
    premium: {
      premium: 'Premium',
      profileSocial: 'Profili social',
      extraStorege: 'Spazio Storage',
      generationText: "Testi generati dall'AI",
      advancedCalendar: 'Calendario editoriale',
      generationHashtag: "60 set di hashtag generati dall'AI",
      saveInDraft: 'Salva post in bozze',
      graficEditor: 'Editor grafico completo',
      analytics: 'Analytics (coming soon)',
      support: 'Assistenza chat in 4h',
      application: 'App (coming soon)',
      instagramReels: 'Instagram reels',
      inbox: 'Inbox (coming soon)',
      extraSocialFeatures: 'Funzionalità social extra',
    },
    enterprise: {
      enterprise: 'Enterprise',
      speakWithOurTeam: 'Parla con il nostro team Vendite',
      enterpriseDiscoverText:
        'Scopri come aiutare i tuoi team a incrementare la produttività attraverso la social media automation e guarda Asters in azione con una demo live personalizzata specificamente per la tua azienda.',
    },
  },
  additionalOptions: {
    storage: {
      lable: '3GB di spazio storage extra a €2',
      description: 'Spazio storage extra',
    },
    extraSocial: {
      lable: '1 profilo social extra a €2',
      description: 'Profilo social',
    },
    extraHashtag: {
      lable: '10 generazioni hashtag extra a €2',
      description: 'Generazione hashtag tramite AI',
    },
    extraCaptions: {
      lable: '10 generazioni testo extra a €2',
      description: 'Generazione testo tramite AI',
    },
  },
  subscriptionSteps: {
    step1: 'Piano',
    step2: 'Personalizza',
    step3: 'Pagamento',
  },
  teamMembers: {
    manageWorkspace: 'Gestisci',
    setTimeZone: 'Cerca time-zone',
    rulesTitle: {
      publication: 'Pubblicazione',
      media: 'Media',
      calendar: 'Calendario',
      settings: 'Impostazioni',
      analytics: 'Analytics',
      discover: 'Discover',
    },
    rules: {
      viewCommentPost: 'Visualizzare e commentare post',
      editPost: 'Modificare post',
      publishPost: 'Pubblicare post',
      aiCopyGenerator: 'AI copy',
      manageDraft: 'Gestire bozze',
      uploadMedia: 'Caricare file',
      manageMedia: 'Gestire file',
      manageFolders: 'Gestire cartelle',
      mediaEditor: 'Editor grafico',
      aiMediaSearch: 'AI ricerca smart',
      calendarShowPost: 'Visualizzare post',
      exportCalendar: 'Esportare calendario editoriale',
      linkSocialAccount: 'Collegare profili social',
      deleteSocialAccount: 'Eliminare profili social',
      createWorkspaces: 'Creare ambienti',
      editWorkspaces: 'Modificare ambienti',
      deleteWorkspaces: 'Eliminare ambienti',
      manageBillings: 'Gestire abbonamento',
      createReport: 'Creare report',
      viewReport: 'Visualizzare report',
      shareReport: 'Condividere report',
      searchContent: 'Cercare contenuti',
      manageContentDiscover: 'Gestire board e seguiti',
    },
    tableHeaders: {
      name: 'Nome',
      publication: 'Pubblicazione',
      calendar: 'Calendario',
      media: 'Media',
      settings: 'Impostazioni',
      roleName: 'Nome ruolo',
    },
    addNewRole: 'Aggiungi ruolo',
    addTeamMember: 'Aggiungi membro del team',
    manageRole: 'Gestisci ruoli',
    loading: 'Caricamento in corso...',
    insertEmail: 'Inserisci email',
    selectRole: 'Seleziona ruolo',
    inviteMembers: 'Invita team member',
    apiButton: 'API',
    addEmail: 'Aggiungi email',
    invite: 'Invita',
    addText: 'Aggiungi',
    searchRole: 'Cerca ruolo',
    search: 'Cerca',
    create: 'Crea',
    comingSoon: 'presto in arrivo',
    roleName: 'Nome ruolo',
    successInvite: 'Invito inviato con successo',
    searchTeamMember: 'Cerca team member',
  },
  guidedTour: 'Tour Guidato',
  aiDiscoverPage: {
    sidebar: {
      sidebarTitleFollowed: 'Seguiti',
      sidebarTitleSuggested: 'Suggeriti',
      sidebarTitleBoard: 'Board',
      noArgumentFollowed: 'Non segui nessun argomento',
      noBoardsCreated: 'Nessuna board disponibile',
      manage: 'Gestisci',
      search: 'Cerca',
      noResultsFound: 'Nessun risultato trovato',
      unfollow: 'Non seguire più',
      deleteBoard: 'Elimina board',
      deleteBoards: 'Elimina boards',
      unfollowModal: 'Non segui più quei argomenti',
      deleteBoardsModal: 'Board eliminata con successo',
      deleteArticle: 'Articolo eliminato con successo',
      deleteArticleProblem: "Si è verificato un problema durante l'eliminazione dell'articolo",
      deleteTopicModal: 'Non segui più questo topic',
      deleteTopicProblem: "Si è verificato un problema durante l'eliminazione del topic",
      unfollowModalProblem: 'Si è verificato un problema, riprova più tardi',
      moreRecent: 'Più recente',
      lessRecent: 'Meno recente',
      all: 'Tutte',
      private: 'Private',
      public: 'Pubbliche',
      selectAllResults: 'Seleziona tutti i risultati',
      selectAllPrivateBoards: 'Seleziona tutte le board private',
      selectAllSharedBoards: 'Seleziona tutte le board condivise',
      selectAll: 'Seleziona tutto',
      StartDate: 'Data di inizio',
      EndDate: 'Data di fine',
    },
    searchbar: {
      resultsFor: 'Risultati per',
      following: 'Seguito',
      follow: 'Segui',
      searchByTopic: 'Cerca per topic',
      addedTopicToFollowed: 'Aggiunto topic ai seguiti',
      problemToAddTopicToFollowed: 'Si è verificato un problema, riprova più tardi',
      removedTopicToFollowed: 'Rimosso topic ai seguiti',
      problemToRemoveTopicToFollowed: 'Si è verificato un problema, riprova più tardi',
    },
    discoverMenu: {
      search: 'Cerca',
      followed: 'Seguiti',
      boards: 'Boards',
    },
    searchContent: {
      noResults: 'Nessun risultato',
    },
  },
  analytics: {
    selectAPeriod: 'Seleziona un periodo',
    reportName: 'Nome del report',
    vsPreviousPeriod: 'vs periodo precedente',
    compareDateAlert: (nDays) =>
      `Puoi fare la comparazione solo su due lassi temporali uguali. \n Seleziona un range di ${nDays} giorni`,
    linkedinAlert1: 'LinkedIn consente solamente di leggere le statistiche delle pagine aziendali',
    linkedinAlert2: 'Per questo motivo non è possibile selezionare profili personali all’interno di quest’area',
    customize: 'Personalizza',
    compare: 'Confronta',
    lastEdit: 'Ultima modifica',
    myReports: 'I miei report',
    reportWith: 'Report con',
    metrics: 'Metriche',
    enterTheNameOfTheReport: 'Inserisci il nome del report',
    recommendedDimensionLogo: 'Le dimensioni consigliate per il logo sono',
    uploadLogo: 'Carica logo',
    noReportsAvailable: 'Nessun report disponibile',
    noReportsWith: 'Nessun report con',
    createYourFirstReport: 'Crea il tuo primo report',
    reportRename: (min, max) => `Il report deve contenere almeno ${min} lettera e massimo ${max} caratteri`,
    untitled: 'Senza titolo',
    addProfile: 'Collega profilo',
    noSocialProfilesConnectedTo: 'Nessun profilo social collegato con',
    comingSoon: 'Prossimamente',
    noReports: 'Nessun report',
    lastSave: 'Ultimo salvataggio',
    dashboard: 'Dashboard',
    new: 'Nuovo',
    setUp: 'Set-Up',
    social: 'Social',
    preview: 'Preview',
    share: 'Condividi',
    save: 'Salva',
    link: 'Link',
    savePdf: 'Salva PDF',
    selectASocialAccount: 'Seleziona un account social',
    reportDate: 'Data report',
    creatingPdf: 'Creazione PDF in corso',
    reportTooLargeForPdf: 'Il report è troppo grande per essere salvato come PDF',
  },
  socialProfileType: {
    group: 'Gruppo',
    page: 'Pagina',
    profile: 'Profilo',
    channel: 'Canale',
    business: 'Business',
  },
  headerAutopilotAndModal: {
    autopilot: 'Autopilot',
    analytics: 'Analytics',
    titleCreateKey: 'Genera chiave API',
    inputTitle: 'Assegna un nome',
    placeholderName: 'Inserisci qui',
    assignScopeTitle: 'Assegna uno scope',
    titleCreatedKey: 'La tua chiave API',
    descriptionApiKeyGenerated:
      'La chiave API è stata generata con successo. Copia la chiave e conservala in un luogo sicuro. Per motivi di sicurezza, non possiamo mostrartela di nuovo',
    generate: 'Genera',
    warning: {
      minMaxChar: 'Il testo deve contenere almeno 3 carattere e massimo 50 caratteri',
      selectScope: 'Seleziona uno scope',
    },
    info: {
      copyKey: 'Chiave copiata nella clipboard',
    },
  },

  billing: {
    settings: {
      error: "Errore nella gestione dell'abbonamento, riprovare.",
      title: 'Piano',
      description:
        "Visualizza i dettagli del piano, aggiorna i dati di fatturazione, annulla l'abbonamento, e scarica ricevute",
      manage: 'Gestisci abbonamento',
      freeTrial: 'Free Trial',
      remainingDays: 'Giorni rimanenti:',
      checkCapabilities: 'Confronta funzionalità',
      faq: 'FAQ',
    },

    comingSoon: 'Coming soon',
    matchPlans: 'Confronta tutte le funzionalità',
    faq: 'FAQ',
    addOn: {
      caption: 'Scegli il piano più adatto per te',
      addonTitle: 'Add-on',
      addonDescription:
        'Crea e analizza i tuoi contenuti social con Flora AI — il chatbot che aumenta la tua produttività e la tua efficienza sui social media. Il tutto senza muovere un dito. Aggiungila al tuo piano per meno di un cappuccino al giorno',

      floraTitle: 'Flora AI',
      floraDescription:
        "Uso illimitato dell'AI per creare post social con il brand voice, analizzare le performance dei post e profili, e molto altro",
    },
    status: {
      inactive: 'Inattivo',
      active: 'Attivo',
      expired: 'Scaduto',
      infinite: 'Illimitati',
    },
    labels: {
      socialProfile: 'profili social',
      suggested: 'Consigliato',
      colabs: 'utenze',
      api: 'Accesso API',
      autopilot: 'Autopilot',
      privateManager: 'Account Manager dedicato',
      customDevelopment: 'Sviluppo funzionalità custom',
      copies: 'Generazioni AI',
      posts: 'Post',
      discover: 'Discover risultati',
      storage: 'GB Storage',
      infiniteStorage: ' Storage',
    },
    plans: {
      downgradeNotEnabled: 'Conttatare assistenza',
      caption: 'Tutti i piani',
      monthly: 'Mensile',
      yearly: 'Annuale',
      reserveDemo: 'Contatta sales',
      activate: 'Attiva',
      upgrade: 'Upgrade a',
      downgrade: 'Downgrade a',
      startTrial: 'Prova gratis per 7 Giorni',
    },

    upgradePlan: {
      active: 'Attivo',
      expiredAt: 'Scaduto il ',
      expireAt: 'Scade il ',
      planned: 'Upgrade Pianificato',
      upgradePlanned:
        'Upgrade pianificato in corso. Sarà possibile aggiornare il piano al prossimo ciclo di fatturazione.',
      confirm: 'Conferma',
      from: 'Da ',
      to: 'A ',
      upgrade: 'Upgrade del piano',
      selectOption: "Seleziona un'opzione",
      unusedCredit: 'Credito non utilizzato',
      pricePlan: ' Tariffa del piano',
      vatDescription: 'IVA eclusa',
      upgradePaymentDate: 'Importo dovuto al ',
      upgradePlanDescription:
        ' Al termine del ciclo di fatturazione, il piano di abbonamento si rinnoverà automaticamente e si aggiornerà al nuovo piano scelto.',
      upgradeProrateDescription:
        "Il tuo nuovo piano di abbonamento diventerà attivo subito, e l'importo dovuto oggi sarà calcolato in automatico applicando il credito disponibile sulla base dell'inutilizzo del piano precedente.",
      amountNow: 'Importo dovuto oggi',
      amountVat: 'Importo IVA',
      amountWithoutVAT: 'Importo senza IVA',
      currency: '€',
      optionsDescription:
        "Puoi scegliere di applicare la modifica del tuo piano al prossimo rinnovo, oppure applicarla subito e verrà in automatico calcolato il tuo credito disponibile sulla base dell'inutilizzo del piano corrente.",
      applyNextRenewal: 'Applica upgrade al prossimo rinnovo',
      applyWithProrate: 'Applica upgrade subito con ricalcolo credito disponibile',
      continue: 'Continua',
      upgradeSuccess: 'Upgrade effettuato con successo',
      upgradeError: 'Si è verificato un errore durante l’upgrade',
      planLimitExceeded: 'Limite del piano superato. Prima di effettuare un upgrade verifica le attuali risorse.',
    },
  },
  appsumo: {
    title: 'Appsumo',
    welcome: 'Ciao Sumo-ling! 👋'
  }
}
