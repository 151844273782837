import styled from '@emotion/styled'
import Auth from 'api/Auth'
import BillingService from 'api/service/BillingService'
import { Tracker } from 'api/Tracker'
import { Box } from 'components/Box'
import { Button } from 'components/Button'
import InfiniteLoader from 'pages/ai-discover/UI/InfiniteLoader'
import { useEffect, useState } from 'react'
import { useUserStore } from 'stores/UserStore'
import { AppStore } from 'utils'
import { TRACKEREVENTS } from 'utils/eventsTracker'
import UpgradeModalAddon from './UpgradeModalAddon'
import { UpgradeModalPlans } from './UpgradeModalPlans'

export const UpgradeModal = ({ onClose, logout }: { onClose?: () => void; logout?: boolean }) => {
  const [loading, setLoading] = useState(true)
  const { user } = useUserStore((state) => ({ user: state.user }))
  const [loadedPlans, setLoadedPlans] = useState<any>([])

  const loadPlans = async () => {
    if (!user) return
    setLoading(true)
    const data = await BillingService.getPlans(!!user.license)
    setLoadedPlans(data)
    setLoading(false)
  }

  useEffect(() => {
    setTimeout(() => {
      loadPlans()
    }, 2000)
  }, [user])

  const loadingVisible = loading

  const LogoutButton = () => {
    const handleLogOut = () => {
      try {
        Auth.logout()
        Tracker.trackEvent(TRACKEREVENTS.LOGOUT_SUCCESSFUL, {})
        localStorage.clear()
      } catch (error) {
        Tracker.trackEvent(TRACKEREVENTS.LOGOUT_FAILED, { error_message: 'An error occured. Please try again later' })
      }
    }

    return <LogoutButtonStyle onClick={handleLogOut}>Logout</LogoutButtonStyle>
  }

  return (
    <Container>
      <ModalContainer width={1400}>
        {!loadingVisible && (
          <>
            <UpgradeModalAddon />
            <UpgradeModalPlans loadedPlans={loadedPlans} />
          </>
        )}

        {loadingVisible && (
          <LoadingContainer>
            <InfiniteLoader />
          </LoadingContainer>
        )}
      </ModalContainer>

      {logout && <LogoutButton />}
    </Container>
  )
}

// ------------ CSS STYLES ----------------

const Container = styled(Box)`
  justify-content: flex-end;
  align-items: flex-end;
`

const ModalContainer = styled(Box)`
  display: flex;
  padding: 32px;
  flex: 1;
  max-height: 870px;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  background-color: ${() => AppStore.theme.o.background};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`

const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 40px;
  border-radius: 10px;
  background-color: ${() => AppStore.theme.o.background};
  justify-content: space-between;
`

const LogoutButtonStyle = styled(Button)`
  padding: 8px 48px 8px 48px !important;
  height: 40px !important;
  min-height: 40px !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  margin-top: 8px;
  color: ${() => AppStore.theme.o.black};
  background-color: ${() => AppStore.theme.o.lightestGrey} !important;
  border: 1px solid transparent;
  border-color: ${() => AppStore.theme.o.lightGrey};

  &:hover {
    border-color: transparent;
  }
`
