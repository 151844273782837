import { create } from 'apisauce'
import Cohere from 'cohere-js'
import crypto from 'crypto'
import { get } from 'https'
import JSZip from 'jszip'
import { isNumber as _isNumber } from 'lodash'
import moment from 'moment'
import { toast, ToastOptions } from 'react-toastify'
import { CrelloProps, CrelloResponse, Media } from 'types'
import { PLACEHOLDER_DATE } from 'utils/Constants/Constants'
import { T } from '../translation/i18n'
import AppStore from './AppStore'
import { OWNER_POST_LIMITS_AVAILABLE, OWNER_POST_LIMITS_USED } from './Constants/LimitUserConstants'
export { default as AppStore } from './AppStore'

export * from '../translation/i18n'

export * from './CreatePostUtils'

export enum CrelloType {
  DESIGN = 'design',
  TEMPLATE = 'template',
  PROJECT = 'project',
}

export const showError = (_message: string | any, options?: ToastOptions) => {
  let message = _message
  if (typeof message !== 'string') {
    try {
      message = _message?.message ?? 'Generic error'
      if (message === '{}') return
    } catch (error) {}
  }
  return toast.error(message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    ...options,
  })
}

export const showWarning = (message: string, options?: ToastOptions) => {
  return toast.warning(message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    ...options,
  })
}

export const showInfo = (message: string, options?: ToastOptions) => {
  return toast.info(message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    ...options,
  })
}

export const showSuccess = (message: string, options?: ToastOptions) => {
  return toast.success(message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    style: { zIndex: 999 },

    ...options,
  })
}

export const showTimerTime = (message: string, closeTime: number, options?: ToastOptions) => {
  return toast.warn(message, {
    position: 'top-right',
    autoClose: closeTime,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    ...options,
  })
}

export const isNumber = (number: any) => _isNumber(number)

export function flexIfDefined(flex: number | boolean | string | undefined) {
  if (flex === undefined) return undefined
  if (isNumber(flex)) return flex as number
  if (typeof flex === 'string') return flex
  return 1
}

export function parseSize(size: string | number) {
  if (typeof size === 'string') {
    return size
  }
  return `${size}px`
}

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

//from pixel size (px) and return vw size with & on 1080px screen
export function pxToVw(px: number) {
  return `${(px / 1080) * 100}vw`
}

//from pixel size (px) and return Vh size with & on 1080px screen
export function pxToVh(px: number) {
  return `${(px / 1080) * 100}Vh`
}

//get name of media normalized name-timestamp.png removing the timestamp
export function getMediaName(_name: string) {
  const name = _name.split('/').slice(-1)[0]
  const parts = name.split('-')
  const extension = name.split('.').pop()
  return parts[0] + '.' + extension
}

export function openVistaCreate(onPublishAction: (data: CrelloResponse) => void, props?: CrelloProps) {
  try {
    const isFromProject = props?.crelloType

    const normalizeProps: any = {
      lang: AppStore.getLanguageValue(),
      user: {
        externalUserId: AppStore.loggedUser._id.toString(),
        //email: AppStore.loggedUser.email,
      },
    }

    // Open projects
    if (isFromProject == CrelloType.TEMPLATE) {
      normalizeProps.templateId = props?.customTemplateId
      normalizeProps.customTemplateId = props?.customTemplateId
      normalizeProps.designType = props?.designType ?? 'facebookSM'
    }
    // Open templates
    else if (isFromProject == CrelloType.PROJECT) {
      normalizeProps.designId = props?.customTemplateId
    } else {
      normalizeProps.sidebarDesignType = props?.sidebarDesignType ?? 'facebookSM'
      normalizeProps.customDimensions = props?.customDimensions
      normalizeProps.designType = props?.designType ?? 'facebookSM'
    }

    const crelloData = {
      apiKey: process.env.REACT_APP_CRELLO_EDITOR_KEY, //AppStore.settings.vistaCrello.apiKey,
      onPublishAction,
      ...normalizeProps,
    }

    console.log('crelloData', crelloData)
    ;(window as any).CrelloEditor.init(crelloData).then((editorApi) => {
      if (document.getElementsByClassName('crello-iframe-in-popup').length > 0) {
        ;(document.getElementsByClassName('crello-iframe-in-popup')[0] as any).style.visibility = 'visible'
      }

      if (document.getElementsByClassName('crello-closeBtn').length > 0) {
        ;(document.getElementsByClassName('crello-closeBtn')[0] as any).style.visibility = 'visible'
      }

      if (document.getElementsByClassName('crello-overlayFrame').length > 0) {
        ;(document.getElementsByClassName('crello-overlayFrame')[0] as any).style.visibility = 'visible'
      }
      AppStore.closeModal('crello-loader')

      // Editor frame is loaded and opened now. Methods are available in editorApi instance:
      if (props?.image) editorApi.addImage({ image: props.image })
    })
  } catch (error) {
    console.log('error', error)
  }
}

export const initFB = () => {
  if (!AppStore.settings?.facebook?.appId) return
  FB.init({
    appId: AppStore.settings.facebook.appId,
    autoLogAppEvents: true,
    xfbml: true,
    version: 'v14.0',
  })
  FB.AppEvents.logPageView()
}

export const initUsetiful = () => {
  ;(function (w, d, s) {
    const a = d.getElementsByTagName('head')[0]
    const r: any = d.createElement('script')
    r.async = 1
    r.src = s
    r.setAttribute('id', 'usetifulScript')
    r.dataset.token = 'eb937ba2642dc8efcd8a4899216670b9'
    a.appendChild(r)
  })(window, document, 'https://www.usetiful.com/dist/usetiful.js')
  setTimeout(() => {
    ;(window as any).usetifulTags = {
      userId: AppStore.loggedUser?._id.toString(),
      language: AppStore.getLanguageValue(),
      name: AppStore.loggedUser?.name,
    }
  }, 2000)
}

// export const initEncharge = () => {
//   ;(function () {
//     if (!(window as any).EncTracking || !(window as any).EncTracking.started) {
//       ;(window as any).EncTracking = Object.assign({}, (window as any).EncTracking, {
//         // queue: (window as any).EncTracking && (window as any).EncTracking.queue ? (window as any).EncTracking.queue : [],
//         // track: function (t) {
//         //   this.queue.push({ type: 'track', props: t })
//         // },
//         // identify: function (t) {
//         //   this.queue.push({ type: 'identify', props: t })
//         // },
//         // started: !0,
//       })
//       const t = (window as any).EncTracking
//       ;(t.writeKey = 'tCW6qtGi9Gbfm252sd79SD2MZ'),
//         (t.hasOptedIn = true),
//         (t.shouldGetConsent = true),
//         t.hasOptedIn && (t.shouldGetConsent = !1),
//         (t.optIn = function () {
//           ;(t.hasOptedIn = !0), t && t.init()
//         }),
//         (t.optOut = function () {
//           ;(t.hasOptedIn = !1), t && t.setOptOut && t.setOptOut(!0)
//         })
//       const nFn = function (_t?: any) {
//         const n = document.createElement('script')
//         ;(n.type = 'text/javascript'),
//           (n.async = void 0 === _t || _t),
//           (n.src = 'https://resources-app.encharge.io/encharge-tracking.min.js')
//         const e = document.getElementsByTagName('script')[0]
//         e?.parentNode?.insertBefore(n, e)
//       }
//       'complete' === document.readyState
//         ? nFn()
//         : (window as any).attachEvent
//         ? (window as any).attachEvent('onload', nFn)
//         : (window as any).addEventListener('load', nFn, !1)
//     }
//   })()

//   setTimeout(() => {
//     const encharge = getEncharge()
//     if (!encharge || !AppStore.loggedUser?.email) return
//     const user = AppStore.loggedUser
//     encharge.identify({
//       email: user?.email,
//       userId: user?._id.toString(),
//       name: user?.name,
//       plan: user?.subscription?.activePlan?.plan.identifier ?? 'Free',
//       user_type: user?.type ?? 'Professionista',
//       media_count: OWNER_POST_LIMITS_USED,
//       copy_generation_used: OWNER_POST_LIMITS_USED,
//       copy_generation_available: OWNER_POST_LIMITS_AVAILABLE,
//       social_accounts: OWNER_POST_LIMITS_USED,
//       post_published: AppStore.workspace.owner.postInfos.published,
//       post_not_published: AppStore.workspace.owner.postInfos.notPublished,
//       post_planned: AppStore.workspace.owner.postInfos.planned,
//       post_draft: AppStore.workspace.owner.postInfos.draft,
//       UTMTerm: user.utms?.utm_term,
//       UTMSource: user.utms?.utm_source,
//       UTMMedium: user.utms?.utm_medium,
//       UTMContent: user.utms?.utm_content,
//       UTMCampaign: user.utms?.utm_campaign,
//     })
//   }, 2000)
// }

export const initCustomerly = () => {
  const customerly = getCustomerly()
  if (!customerly) return
  try {
    const user = AppStore.loggedUser
    customerly.load({
      app_id: 'ac99e9ac',
      user_id: user._id.toString(),
      name: user.name,
      email: user.email,
    })

    customerly.attribute('Plan', user.subscription?.activePlan?.plan?.identifier ?? 'Free')
    customerly.attribute('User Type', user.type ?? 'Professionista')
    customerly.attribute('Media count', OWNER_POST_LIMITS_USED)
    customerly.attribute('Copy generation used', OWNER_POST_LIMITS_USED)
    customerly.attribute('Copy generation available', OWNER_POST_LIMITS_AVAILABLE)
    customerly.attribute('Social accounts', OWNER_POST_LIMITS_USED)
    customerly.attribute('Post published', AppStore.workspace.owner.postInfos.published)
    customerly.attribute('Post not published', AppStore.workspace.owner.postInfos.notPublished)
    customerly.attribute('utm_source', user.utms?.utm_source)
    customerly.attribute('utm_medium', user.utms?.utm_medium)
    customerly.attribute('utm_campaign', user.utms?.utm_campaign)
    customerly.attribute('utm_term', user.utms?.utm_term)
    customerly.attribute('utm_content', user.utms?.utm_content)
  } catch (error) {}
}

export const getCustomerly = () => (window as any).customerly

export const getEncharge = () => (window as any).EncTracking

export function urlToBuffer(url: string): Promise<Buffer> {
  return new Promise((resolve, reject) => {
    const data: Uint8Array[] = []
    get(url, (res) => {
      res
        .on('data', (chunk: Uint8Array) => {
          data.push(chunk)
        })
        .on('end', () => {
          resolve(Buffer.concat(data))
        })
        .on('error', (err) => {
          reject(err)
        })
    })
  })
}

export function urlToBlob(baseURL: string): Promise<Blob> {
  return new Promise((resolve, reject) => {
    try {
      const api = create({
        baseURL,
        responseType: 'blob',
        timeout: 60000,
      })
      api
        .get<any>('')
        .then((res) => {
          if (!res.ok) throw new Error()
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    } catch (error) {
      reject(error)
    }
  })
}

export async function downloadMedias(medias: Media[]) {
  const zip = new JSZip()
  try {
    await Promise.all(
      medias.map(async (media) => {
        try {
          const blob = await urlToBlob(media.url)
          //where media.name is "625cb3bf4183811a7d3f6df0/625cb3bf4183811a7d3f6df2/New folder/CleanShot 2022-06-08 at 12.24.10@2x-1655287524232.png" remove first part of the string
          await zip.file(media.name.replace(media.user + '/', '').replace(media.workspace + '/', ''), blob)
        } catch (error) {
          // showError('Impossibile scaricare il file ' + getMediaName(media.name))
          throw new Error('')
        }
      })
    )

    zip.generateAsync({ type: 'blob' }).then((content) => {
      //download blob as file
      const a = document.createElement('a')
      a.href = URL.createObjectURL(content)
      a.style.display = 'none'
      a.download = 'medias.zip'
      a.click()
    })
  } catch (error) {
    showError(T.error.downlordFaild)
  }
}

export function getZoomString() {
  const zoom = AppStore.zoom
  return `transform: scale(${zoom}%, ${zoom}%);`
}

export function addZoomToDiv(element: string) {
  try {
    //@ts-ignore
    const elements: any = document.getElementsByClassName(element)
    let i = 0
    while (elements[i]) {
      elements[i].style.cssText = elements[i].style.cssText + ';' + getZoomString()
      i++
    }
  } catch (error) {}
}

export function addZoomToParent(element: string) {
  try {
    //@ts-ignore
    const elements: any = document.getElementsByClassName(element)
    let i = 0
    while (elements[i]) {
      elements[i].parentElement.style.cssText = getZoomString()
      i++
    }
  } catch (error) {}
}

export function getSizeZoomed(size: number, appendString = '') {
  return size * (100 / AppStore.zoom) + appendString
}

export function showComingSoonToast() {
  return showWarning(T.warning.comingSoon)
}

export function copyToClipboard(text) {
  const elem = document.createElement('textarea')
  elem.value = text
  document.body.appendChild(elem)
  elem.select()
  document.execCommand('copy')
  document.body.removeChild(elem)
}

export const initCohere = () => {
  Cohere.init('gebHP4I8qVPmVBjqMhX7uNlI')
  Cohere.identify(AppStore.loggedUser._id.toString(), {
    displayName: AppStore.loggedUser.name,
    email: AppStore.loggedUser.email,
    plan: AppStore.loggedUser.subscription?.activePlan?.plan?.identifier ?? 'Free',
  })
}

export const getHumanReadableDate = (date?: any, format?: string) => {
  return moment(date).format(format ?? 'DD/MM/YYYY')
}

export const getDaysDiff = (date?: any) => {
  return moment().diff(moment(date), 'days')
}

export const getPlanType = (): -1 | 0 | 1 | 2 => {
  const plan = AppStore.loggedUser.subscription?.activePlan?.plan?.identifier
  if (plan === undefined) return -1
  if (plan === 'pro') return 0
  else if (plan === 'business') return 1
  else return 2
}

export const getLastUpdateDate = (): string => PLACEHOLDER_DATE

// ========================< ===========

export const getActivePost = (socialAccounts, posts, uniqueCheck?: boolean) => {
  const socialaccountActive = socialAccounts.find((a) => a.active)
  let postIndex = socialaccountActive ? posts.findIndex((p) => p.socialaccount?._id === socialaccountActive._id) : 0
  if (uniqueCheck && !posts[postIndex]?.unique) postIndex = 0
  return { post: posts[postIndex], postIndex }
}

export const stringifyNumber = (n: number) => {
  return n.toLocaleString().replace(/\,/g, '.')
}

export const capitalizeFirstLetter = (string) => {
  if (string && string.length > 0) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
  } else {
    return string
  }
}

/**
 * Prende come valore un numero, una stringa numerica o una percentuale e la formatta in base alla lingua.
 *
 * Esempio (Italiano): "1000" => 1.000 | "1.000,5" => 1.000,50 | "50%"=> 50%
 *
 * Esempio (Inglese): "1000" => 1,000 | "1,000.5" => 1,000.50 | "50%"=> 50%
 * @param {number|string} value Numero in formato number o string
 * @returns {string} Il numero formattato
 */
export const formatNumber = (value: string | number): string => {
  // Ottieni la lingua del sito
  const userLanguage = AppStore.getLanguageValue() === 'en' ? 'en-US' : 'it-IT'

  const stringValue = String(value)

  const hasPercentage = stringValue.includes('%')

  if (isNaN(Number(value)) && !hasPercentage) return value as string
  // Rimuovi il simbolo percentuale e sostituisci la virgola con il punto
  const numericValue = parseFloat(stringValue.replace('%', '').replace(',', '.'))

  // Verifica se il numero contiene decimali
  const hasDecimals = numericValue % 1 !== 0

  // Formatta il numero utilizzando toLocaleString
  const formattedValue = hasDecimals
    ? numericValue.toLocaleString(userLanguage, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    : numericValue.toLocaleString(userLanguage)

  if (hasPercentage) {
    return `${formattedValue}%`
  }

  return formattedValue
}

/**
 * This function converts a date to a unix timestamp
 * @param date Date to convert
 * @returns Unix timestamp
 */
export const convertDateToUnixTimestamp = (date: Date | undefined): number | null => {
  if (date) {
    const dateObject = moment.utc(date)
    const timestamp = dateObject.unix()
    return timestamp
  }
  return null
}

/**
 * This function generates an Intercom hash for each user using a secret key and a user identifier.
 * @returns The user hash.
 */
export const generateIntercomHash = (): string => {
  const secretKey = process.env.REACT_APP_INTERCOM_SECRET_KEY
  const userIdentifier = AppStore.loggedUser._id
  return crypto.createHmac('sha256', secretKey!).update(userIdentifier).digest('hex')
}

/**
 * Funzione che controlla se il testo passato è un social media
 * @param {string} text Testo da controllare
 * @returns Il nome del Social Media se è un social media, altrimenti una stringa vuota
 */
export const checkSocialMedia = (text: string): string => {
  const socialMedia = text.toLowerCase()

  if (socialMedia.includes('youtube')) {
    return 'YouTube'
  } else if (socialMedia.includes('facebook')) {
    return 'Facebook'
  } else if (socialMedia.includes('instagram')) {
    return 'Instagram'
  } else if (socialMedia.includes('twitter')) {
    return 'X'
  } else if (socialMedia.includes('linkedin')) {
    return 'LinkedIn'
  } else if (socialMedia.includes('tiktok')) {
    return 'TikTok'
  } else {
    return ''
  }
}

/**
 * The `removeAccents` function in TypeScript removes accents and special characters from a given
 * string.
 * @param {string} str - The `str` parameter in the `removeAccents` function is a string that may
 * contain accented characters or special characters. The function is designed to remove accents and
 * special characters from the input string and return a normalized string without those characters.
 * @returns The `removeAccents` function returns a string with accents removed and special characters
 * removed as well.
 */
export function normalizeString(input: string): string {
  // Map of accent characters
  const accentMap: { [key: string]: string } = {
    á: 'a',
    à: 'a',
    ä: 'a',
    â: 'a',
    ã: 'a',
    å: 'a',
    é: 'e',
    è: 'e',
    ë: 'e',
    ê: 'e',
    í: 'i',
    ì: 'i',
    ï: 'i',
    î: 'i',
    ó: 'o',
    ò: 'o',
    ö: 'o',
    ô: 'o',
    õ: 'o',
    ø: 'o',
    ú: 'u',
    ù: 'u',
    ü: 'u',
    û: 'u',
    ç: 'c',
    ñ: 'n',
    Á: 'A',
    À: 'A',
    Ä: 'A',
    Â: 'A',
    Ã: 'A',
    Å: 'A',
    É: 'E',
    È: 'E',
    Ë: 'E',
    Ê: 'E',
    Í: 'I',
    Ì: 'I',
    Ï: 'I',
    Î: 'I',
    Ó: 'O',
    Ò: 'O',
    Ö: 'O',
    Ô: 'O',
    Õ: 'O',
    Ø: 'O',
    Ú: 'U',
    Ù: 'U',
    Ü: 'U',
    Û: 'U',
    Ç: 'C',
    Ñ: 'N',
  }

  // Replace accented characters with standard characters
  const replaceAccent = (char: string): string => accentMap[char] || char

  // Remove special characters
  const isStandardChar = (char: string): boolean => /[a-zA-Z0-9\s._/-]/.test(char)

  // Normalize string
  return input.split('').map(replaceAccent).filter(isStandardChar).join('')
}
