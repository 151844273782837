import styled from '@emotion/styled'
import Auth from 'api/Auth'
import { Tracker } from 'api/Tracker'
import { Box, ZoomedContainer } from 'components'
import { AppsumoSignupCard } from 'components/Login/AppsumoSignupCard'
import { useEffect, useRef, useState } from 'react'
import { T } from 'translation/i18n'

import { AppStore, showError } from 'utils'
import {
  TRACKEREVENTS,
  getBrowserProperties,
  getDeviceProperties,
  getLocationProperties,
  getRefUrlProperties,
  getUtmProperties,
} from 'utils/eventsTracker'
import MediaUtils from 'utils/MediaUtils'

const backgroundImages = [MediaUtils.backgrounUrl]


export const AppsumoLoginPage = (props) => {
  const { loggedUser, onLoginCallback } = props

  const [licenseKey, setLicenseKey] = useState<string | null>(null)
  const [activeBackground, setActiveBackground] = useState(Math.floor(Math.random() * backgroundImages.length))
  const cardContainer = useRef(null)

  const changeLang = async () => {
    await localStorage.setItem('firstTime', 'false')
    const lang = navigator.language
    if (lang.split('-')[0] === 'it') {
      await localStorage.setItem('defaultLang', 'it')
    } else {
      await localStorage.setItem('defaultLang', 'en')
      history.go(0)
    }
  }

  const trackSoftwareLaunched = async () => {
    const softwareLaunchTrackProperties = {
      ...getUtmProperties(),
      ...getRefUrlProperties(),
      ...getBrowserProperties(),
      ...getDeviceProperties(),
      ...(await getLocationProperties()),
    }

    Tracker.trackEvent(TRACKEREVENTS.SOFTWARE_LAUNCHED, softwareLaunchTrackProperties)
  }
  //change background image every 5 seconds
  const changeBackground = () => {
    setTimeout(() => {
      setActiveBackground((prev) => (prev + 1) % backgroundImages.length)
      changeBackground()
    }, 10000)
  }

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const authorizationCode = params.get("code")

    if (!authorizationCode) {
      showError(T.error.missingAppsumoAuthCode)
    }

    if (!authorizationCode) return;

    Auth.getAppsumoLicenseKey(authorizationCode)
      // TODO marnec: move this check to the api call, throw if !result
      .then((result) => {
        if (!result) {
          showError(T.error.genericErrorWhileFetchingLicenseKey)
          return;
        }
        setLicenseKey(result?.license_key)
      })
      .catch((err) => {
        showError(`${T.error.specificErrorWhileFetchingLicenseKey}: ${JSON.stringify(err)}`)
      })
  }, [])

  useEffect(() => {
    backgroundImages.forEach((backgroundImage) => {
      const img = new Image()
      img.src = backgroundImage
    })
    const firstTime = localStorage.getItem('firstTime') ?? 'true'
    if (firstTime === 'true') changeLang()
    changeBackground()
    trackSoftwareLaunched()
  }, [])

  return (
    <LoginPageContainer background={backgroundImages[activeBackground]}>
      <ZoomedContainer>
        <CardContainer ref={cardContainer} >
          <Box
            id={'FullSingUpCard'}
            flex
            style={{ position: 'absolute', minWidth: 420, top: 0 }}
          >
            <AppsumoSignupCard onLoginCallback={onLoginCallback} onChangeSelected={() => { }} licenseKey={licenseKey} />
          </Box>
        </CardContainer>
      </ZoomedContainer>
    </LoginPageContainer>
  )
}

const LoginPageContainer = styled.div<{ background }>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center !important;
  align-items: center !important;
  justify-content: left;
  background: url(${(props) => props.background}) no-repeat center center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: background-image 0.5s ease-in-out;
`

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 40px;
  margin-top: -1px;
  min-height: 600px;
  background-color: ${() => AppStore.theme.o.surface};
  border-radius: 14px 14px 14px 14px;
  min-width: 500px;
  position: relative;
  overflow: hidden;
  /* background-color: red; */
`
