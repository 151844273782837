import styled from '@emotion/styled'
import ApiKeys from 'api/ApiKeys'
import { Box } from 'components/Box'
import { Icons } from 'components/Icons'
import InfiniteLoader from 'pages/ai-discover/UI/InfiniteLoader'
import { useEffect, useState } from 'react'
import { AppStore, T, showWarning } from 'utils'
import AutopilotCreateKey from './AutopilotCreateKey'
import AutopilotYourKeyCreated from './AutopilotYourKeyCreated'
import { RemoteConfigValues, isVisible } from 'utils/CheckVisibleElement'
import { useUserStore } from 'stores/UserStore'
import BillingService from 'api/service/BillingService'

interface Props {
  setterStateBol: () => void // serve a refreshare le API key
}

/// Scopes utils

export interface KeyScope {
  _id: string
  label: string
  icon: any
  selected: boolean
}

const scopeAnalytics: KeyScope = {
  _id: 'analytics',
  label: T.headerAutopilotAndModal.analytics,
  icon: <Icons.analytics fill={'currentColor'} height={24} width={24} />,
  selected: false,
}

const scopeAutopilot: KeyScope = {
  _id: 'autopilot',
  label: T.headerAutopilotAndModal.autopilot,
  icon: <Icons.autopilotElectric fill="currentColor" height={24} width={24} />,
  selected: false,
}

const AutopilotGenerateKeyModal = ({ setterStateBol }: Props) => {
  /* -------------------- STATE -------------------- */
  const [selectedScope, setSelectedScope] = useState<KeyScope[]>([])

  const [loadingGenerateKey, setLoadingGenerateKey] = useState<boolean>(false)
  const [generatedKey, setGeneratedKey] = useState<string>('')
  const { currrentAvailableScopes } = useUserStore((state) => ({
    currrentAvailableScopes: state.scopes,
  }))

  /// Check if the scopes are enabled then set the selected scope
  useEffect(() => {
    updateScopeVisibility()
  }, [currrentAvailableScopes])

  const updateScopeVisibility = async () => {
    const enabledScopes: KeyScope[] = []
    currrentAvailableScopes.autopilot && enabledScopes.push(scopeAutopilot)
    currrentAvailableScopes.analytics && enabledScopes.push(scopeAnalytics)
    setSelectedScope(enabledScopes)
  }

  /* -------------------- METHODS -------------------- */
  const onChangeSelectedScope = (id: string) =>
    setSelectedScope((prev) =>
      prev.map((scope) => ({ ...scope, selected: scope._id === id ? !scope.selected : scope.selected }))
    )

  const onGenerateKey = async (inputNameValue: string) => {
    // INFO -->  The value of 'inputNameValue' is being controlled in the component 'AutopilotCreateKey.tsx'.

    // INFO --> value to send to the api ( apiKeyName, scopeSelected)
    const apiKeyName = inputNameValue
    const scopeSelected = selectedScope.find((scope) => scope.selected)

    // Check if a scope is selected
    if (!scopeSelected) {
      showWarning(T.headerAutopilotAndModal.warning.selectScope)
      return
    }
    setLoadingGenerateKey(true)

    const normScopes = selectedScope
      .filter((item) => item.selected)
      .map((scope) => ({ id: scope._id, label: scope.label }))
    const generateResult = await ApiKeys.createApiKey(apiKeyName, AppStore.workspaceId!, normScopes)
    const generateKeyString: string = generateResult.data.key.key

    // INFO --> Change component ( from AutopilotCreateKey to AutopilotYourKeyCreated)
    setTimeout(() => {
      setGeneratedKey(generateKeyString)
      setLoadingGenerateKey(false)
      setterStateBol()
    }, 200)
  }

  return (
    <ContainerAutopilotGenerateKey>
      {/* ---------------------- FORM GENERATE KEY  */}
      {!generatedKey && !loadingGenerateKey && (
        <AutopilotCreateKey
          selectedScope={selectedScope}
          onChangeSelectedScope={onChangeSelectedScope}
          onGenerateKey={onGenerateKey}
        />
      )}

      {/* ---------------------- LOADER  */}
      {loadingGenerateKey && (
        <InfiniteLoaderContainer>
          <InfiniteLoader />
        </InfiniteLoaderContainer>
      )}

      {/* ---------------------- GENERATED KEY  */}
      {generatedKey && !loadingGenerateKey && <AutopilotYourKeyCreated apiKeyAutopilot={generatedKey} />}
    </ContainerAutopilotGenerateKey>
  )
}

export default AutopilotGenerateKeyModal

const ContainerAutopilotGenerateKey = styled(Box)`
  position: relative;
  width: 671px;
  min-height: 253px;
  max-height: 253px;
  background-color: ${() => AppStore.theme.o.surface};
  border-radius: 14px;
  padding: 24px 48px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const InfiniteLoaderContainer = styled(Box)`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`
