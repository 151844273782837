import { SubscriptionPlan } from 'types/SubscriptionInterfaces'
import { AppStore } from 'utils'
import AstersBaseResource from '../AstersBaseResource'
import { api } from '../api'

export default class BillingService extends AstersBaseResource {
  static endpoint = '/plans'

  /**
   * Restituisce l'header per le richieste Asters.
   * @returns
   */
  private static getPublicHeaders() {
    return {
      headers: {
        'X-API-KEY': process.env.REACT_APP_PUBLIC_API_KEY,
      },
    }
  }

  /**
   * Richiede tutti i piani attualmente attivi su Asters.
   * @returns
   */
  static async getPlans(lifetime: boolean) {
    const params = { lifetime }
    const config = this.getPublicHeaders()
    const response: any = await api.get(`${this.endpoint}`, params, config)
    // Ordina tutti i piani e restituisce solo i primi 4
    const data = response?.data
    return data
  }

  static orderPlans(a: SubscriptionPlan, b: SubscriptionPlan) {
    return a.order ? 1 : -1
  }

  /**
   * Richiede attivazione piano per l'utente loggato.
   * @returns
   */
  static activatePlan(planId: string) {
    const params = { newPlanId: planId, userId: AppStore.loggedUser._id }
    return api.post(`${this.endpoint}/getPaymentLink`, params)
  }

  /**
   * Richiede upgrade di un piano.
   * @returns
   */
  static upgradePlan(planId: string, prorate: boolean) {
    const params = { newPlanId: planId, userId: AppStore.loggedUser._id, prorate }
    return api.post(`${this.endpoint}/requireUpgrade`, params) as any
  }

  /**
   * Richiede dati per il prorata.
   * @returns
   */
  static recoverProrateData(planId: string) {
    return api.get(`${this.endpoint}/getProrate/${planId}`) as any
  }

  /**
   * Richiede il link per gestire il piano.
   * @returns
   */
  static recoverManageLink(userId: string) {
    const params = { userId }
    return api.post(`${this.endpoint}/getManageLink`, params) as any
  }
}
