/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Button, Text } from 'components'
import { LabelFilterCalendar } from './LabelFilterCalendar'
import { StatusFilterCalendar } from './StatusFilterCalendar'
import { openDraftModal } from 'components/Modal/ModalManager'
import { T } from 'utils'
import { RefreshButton } from 'components/RefreshButton'
import { useCalendarStore } from 'stores/CalendarStore'
import { isVisible, RemoteConfigValues } from 'utils/CheckVisibleElement'
import { CreatedFilterCalendar } from './CreatedFilterCalendar'
import { useUserStore } from 'stores/UserStore'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {}

export const UpperSectionCalendarHeader = ({}: Props) => {
  /* ----------------- STATE  */

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */
  const { forceUpdate } = useCalendarStore()
  const { scopes } = useUserStore((state) => ({
    scopes: state.scopes,
  }))

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  return (
    <UpperSectionCalendarHeaderContainer>
      <Box row gap={24}>
        <LabelFilterCalendar />

        <DraftButton onClick={openDraftModal} variant="neutral" center>
          <Text fontSize={14} weight={600}>
            {T.DashboardPage.draft}
          </Text>
        </DraftButton>
      </Box>

      <Box row gap={8} mr={8} center>
        <StatusFilterCalendar />

        {(isVisible(RemoteConfigValues.AutopilotButtonDisabled) || scopes.autopilot) && <CreatedFilterCalendar />}

        <RefreshContainer variant="neutral">
          <RefreshButton refreshAction={() => forceUpdate()} style={{ width: '100%', padding: '8px' }} />
        </RefreshContainer>
      </Box>
    </UpperSectionCalendarHeaderContainer>
  )
}

const UpperSectionCalendarHeaderContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const DraftButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 48px !important;
  min-height: 40px !important;
  height: 40px !important;
`

const RefreshContainer = styled(Button)`
  min-height: 32px !important;
  height: 100% !important;
  width: 100% !important;
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
`
