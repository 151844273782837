/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Button, Icons } from 'components'
import { AppStore, T } from 'utils'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  className: string
  text: string
  resetSelectedIcon: () => void
  doAction: () => void
}

export const DeleteBanner = ({ className, text, resetSelectedIcon, doAction }: Props) => {
  /* ----------------- STATE  */

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  return (
    <DeleteBannerContainer className={className}>
      <UpperSection>
        <Icons.warningIcon fill={AppStore.theme.o.black} /> {text ?? T.postPage.confirmPostElimination}
      </UpperSection>

      <LowerSection>
        <ButtonBanner variant="delete" onClick={resetSelectedIcon}>
          {T.singleWord.cancel}
        </ButtonBanner>
        <ButtonBanner variant="neutral" onClick={doAction}>
          {T.singleWord.confirm}
        </ButtonBanner>
      </LowerSection>
    </DeleteBannerContainer>
  )
}

const DeleteBannerContainer = styled(Box)`
  position: absolute;
  top: 101px;
  right: 15px;
  background-color: ${() => AppStore.theme.o.surface};
  color: ${() => AppStore.theme.o.black};
  box-shadow: ${() => AppStore.theme.smallOuterShadow};
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  z-index: 1;

  opacity: 0;
  scale: 0;
  transition: opacity 0.3s ease-in-out, scale 0.3s ease-in-out;

  &.selected_container {
    opacity: 1;
    scale: 1;
  }
`

const UpperSection = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: ${() => AppStore.theme.o.black};
`

const LowerSection = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`

const ButtonBanner = styled(Button)`
  display: flex;
  height: 40px !important;
  min-height: 40px !important;
  padding: 8px 48px !important;
  justify-content: center;
  align-items: center;
  gap: 8px;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 14px;
  cursor: pointer;
`
