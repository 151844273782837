import { T } from 'translation/i18n'
import { Media } from 'types'
import { IPostVariant } from 'types/PostInterface'
import { socialCheckConfig } from 'utils/apiSocialCheckConfig/SocialCheckConfig'
import { checkMediaValidation } from './ValidationPostMedias'

/**
 * @param post Post con la quale fare la verifica dei requisiti
 * @returns Un'array di stringhe con tutti i problemi identificati sui mancati requisiti per essere postato
 */
export const checkPostValidation = (post: IPostVariant): string[] => {
  let problems: string[] = []

  const { data, info } = post
  const { account, type } = info
  const { provider } = account
  const { medias, thumbnail } = data

  //? MEDIA CHECK
  // Per ogni media controlla che passi i requisiti
  medias.forEach((media: Media) => {
    problems = problems.concat(checkMediaValidation(media, provider, type, thumbnail))
  })

  //? POST CHECK
  switch (provider) {
    case 'instagram':
      problems = problems.concat(checkInstagramPostValidation(post))
      break

    case 'facebook':
      problems = problems.concat(checkFacebookPostValidation(post))
      break

    case 'linkedin':
      problems = problems.concat(checkLinkedinPostValidation(post))
      break

    case 'x':
      problems = problems.concat(checkXPostValidation(post))
      break

    case 'youtube':
      problems = problems.concat(checkYoutubePostValidation(post))
      break

    case 'tiktok':
      problems = problems.concat(checkTiktokPostValidation(post))
      break

    case 'google':
      break

    case 'pinterest':
      break

    default:
      break
  }

  return problems
}

/**
 * Controllo dei requisiti per un post di Instagram
 * @param post Post con la quale fare la verifica dei requisiti
 * @returns Un'array di stringhe con tutti i problem
 */
export const checkInstagramPostValidation = (post: IPostVariant): string[] => {
  const problems: string[] = []

  const { data, info } = post
  const { account } = info
  const { provider } = account
  const { text, medias } = data

  const { checkTextLength, checkCarouselDimension, checkCarouselRatio, checkIfIsCarousel } = socialCheckConfig

  const { maxChars, needMedia, maxPostHashtag, needText, maxPostForDay, maxMedia, maxMediaStories } =
    socialCheckConfig.instagram

  //TODO: Da aggiungere limite hashtags instagram (?)
  // //? Controllo numero massimo di hashtag
  // if (hashtags && hashtags.length > maxPostHashtag) {
  //   problems.push(`${T.common.socials[provider]}  ${T.error.maxHashtag1} ${maxPostHashtag} ${T.error.maxHashtag2} `)
  // }

  //? Controllo numero minimo di media media
  if (needMedia && medias.length === 0) {
    problems.push(`${T.error.obligatoryMedia} ${T.common.socials[provider].toLowerCase()}.`)
  }

  switch (info.type) {
    case 'STANDARD':
      //? Controllo numero massimo di caratteri
      if (!checkTextLength(text, maxChars)) {
        problems.push(T.error.descriptionTooLong(T.common.socials[provider], maxChars))
      }

      //? Controlli carosello
      if (checkIfIsCarousel(medias)) {
        //? Controllo numero massimo di media (immagini e video) in carosello
        if (!checkCarouselDimension(medias, maxMedia)) {
          problems.push(
            `${T.common.socials[provider]}  ${T.error.MaxMedia} ${maxMedia} ${T.singleWord.medias.toLowerCase()}.`
          )
        }

        //? Controllo che tutti i media abbiano lo stesso ratio
        if (!checkCarouselRatio(medias)) {
          problems.push(T.error.allMediaSameRatio)
        }
      }

      break

    case 'STORY':
      //? Controlla dimensione carosello per immagini e video
      if (!checkCarouselDimension(medias, maxMediaStories)) {
        problems.push(
          `${T.error.theStoriesOf} ${T.common.socials[provider]}  ${
            T.error.MaxMedia
          } ${maxMediaStories} ${T.singleWord.media.toLowerCase()}.`
        )
      }
      break
  }

  return problems
}

/**
 * Controllo dei requisiti per un post di Facebook
 * @param post Post con la quale fare la verifica dei requisiti
 * @returns Un'array di stringhe con tutti i problem
 */
export const checkFacebookPostValidation = (post: IPostVariant): string[] => {
  const problems: string[] = []

  const { data, info } = post
  const { account, type, linkRef } = info
  const { provider } = account
  const { text, medias } = data

  const { isEmpty, containsPhotoAndVideo, checkTextLength, checkCarouselTypeDimension, checkIfIsCarousel } =
    socialCheckConfig

  const { maxChars, canContainPhotoAndVideo, needTextOrMedia, maxPostForDay, maxMedia, maxVideoSize } =
    socialCheckConfig.facebook

  //? Controlla numero massimo di caratteri
  if (!checkTextLength(text, maxChars)) {
    problems.push(T.error.descriptionTooLong(T.common.socials[provider], maxChars))
  }

  //? Controlla se il post contiene testo o media
  console.log('medias', medias, data.urls, linkRef)
  const selectedDataUrl = data?.urls?.map((url) => (url.shortened ? url.shortUrl : url.originalUrl))
  const link = linkRef ? linkRef : selectedDataUrl?.[0] ?? ''
  if (needTextOrMedia && isEmpty(medias, text, link)) {
    problems.push(`${T.common.socials[provider]} ${T.error.needTextOrMedia}`)
  }

  //? Controlli carosello
  if (checkIfIsCarousel(medias)) {
    //? Controlla se il post contiene foto e video nello stesso momento
    if (!canContainPhotoAndVideo && containsPhotoAndVideo(medias)) {
      problems.push(`${T.common.socials[provider]} ${T.error.dontConsentFotoAndVideo}`)
    }

    //? Controlla dimensione carosello per immagini
    if (!checkCarouselTypeDimension(medias, 'image', maxMedia)) {
      problems.push(
        `${T.common.socials[provider]} ${T.error.MaxMedia} ${maxMedia} ${T.singleWord.images.toLowerCase()}.`
      )
    }

    //? Controlla dimensione carosello per video
    if (!checkCarouselTypeDimension(medias, 'video', 1)) {
      problems.push(`${T.common.socials[provider]} ${T.error.MaxMedia} 1 ${T.singleWord.video.toLowerCase()}.`)
    }

    //? Controlla la size dei video
    medias.forEach((media) => {
      if (media.mimetype.includes('video') && media.size > maxVideoSize) {
        problems.push(`${T.common.socials[provider]} ${T.error.theMaximumSize}`)
      }
    })
  }

  return problems
}

/**
 * Controllo dei requisiti per un post di LinkedIn
 * @param post Post con la quale fare la verifica dei requisiti
 * @returns Un'array di stringhe con tutti i problem
 */
export const checkLinkedinPostValidation = (post: IPostVariant): string[] => {
  const problems: string[] = []

  const { data, info } = post
  const { account, type } = info
  const { provider } = account
  const { text, medias } = data

  const { isEmpty, containsPhotoAndVideo, checkTextLength, checkCarouselTypeDimension, checkIfIsCarousel } =
    socialCheckConfig

  const {
    canContainPhotoAndVideo,
    needTextOrMedia,
    maxPostForDay,
    maxPostForMonth,
    maxChars,
    maxMedia, // ONLY FOR IMG, FOR VIDEO MAX 1
  } = socialCheckConfig.linkedin

  //? Controlla se il post contiene testo o media
  if (needTextOrMedia && isEmpty(medias, text)) {
    problems.push(`${T.common.socials[provider]} ${T.error.needTextOrMedia}`)
  }

  //? Controlla numero massimo di caratteri
  if (!checkTextLength(text, maxChars)) {
    problems.push(T.error.descriptionTooLong(T.common.socials[provider], maxChars))
  }

  //? Controllo carosello
  if (checkIfIsCarousel(medias)) {
    //? Controlla se il post contiene foto e video nello stesso momento
    if (!canContainPhotoAndVideo && containsPhotoAndVideo(medias)) {
      problems.push(`${T.common.socials[provider]} ${T.error.dontConsentFotoAndVideo}`)
    }

    //? Controlla dimensione carosello per immagini
    if (!checkCarouselTypeDimension(medias, 'image', maxMedia)) {
      problems.push(
        `${T.common.socials[provider]} ${T.error.MaxMedia} ${maxMedia} ${T.singleWord.images.toLowerCase()}.`
      )
    }

    //? Controlla dimensione carosello per video
    if (!checkCarouselTypeDimension(medias, 'video', 1)) {
      problems.push(`${T.common.socials[provider]} ${T.error.MaxMedia} 1 ${T.singleWord.video.toLowerCase()}.`)
    }
  }

  return problems
}

/**
 * Controllo dei requisiti per un post di X Twitter
 * @param post Post con la quale fare la verifica dei requisiti
 * @returns Un'array di stringhe con tutti i problem
 */
export const checkXPostValidation = (post: IPostVariant): string[] => {
  const problems: string[] = []

  const { data, info } = post
  const { account, type } = info
  const { provider } = account
  const { text, medias } = data

  const { isEmpty, checkTextLength, checkCarouselDimension, checkIfIsCarousel } = socialCheckConfig

  const { maxChars, needTextOrMedia, maxMedia, maxPostForDay } = socialCheckConfig.x

  //? //? Controlla se il post contiene testo o media
  if (needTextOrMedia && isEmpty(medias, text)) {
    problems.push(`${T.common.socials[provider]} ${T.error.needTextOrMedia}`)
  }

  //? Controlla numero massimo di caratteri
  if (!checkTextLength(text, maxChars)) {
    problems.push(T.error.descriptionTooLong(T.common.socials[provider], maxChars))
  }

  //? Controllo carosello
  if (checkIfIsCarousel(medias)) {
    //? Controlla dimensione carosello per immagini e video
    if (!checkCarouselDimension(medias, maxMedia)) {
      problems.push(
        `${T.common.socials[provider]} ${T.error.MaxMedia} ${maxMedia} ${T.singleWord.medias.toLowerCase()}.`
      )
    }
  }

  return problems
}

/**
 * Controllo dei requisiti per un post di Youtube
 * @param post Post con la quale fare la verifica dei requisiti
 * @returns Un'array di stringhe con tutti i problem
 */
export const checkYoutubePostValidation = (post: IPostVariant): string[] => {
  const problems: string[] = []

  const { data, info } = post
  const { account, type } = info
  const { provider } = account
  const { text, medias, description } = data

  const { checkTextLength, checkMediasType, checkCarouselDimension, checkIfIsCarousel } = socialCheckConfig

  const { needMedia, onlyVideo, needText, maxPostForDay, maxTitleChars, maxCharsDescription, maxMedia } =
    socialCheckConfig.youtube

  //? Controllo che si sia inserito almeno un media
  if (needMedia && medias.length === 0) {
    problems.push(`${T.error.obligatoryMedia}  ${T.common.socials[provider].toLowerCase()}.`)
  }

  //? Controllo che il tipo del media sia corretto
  if (onlyVideo && !checkMediasType(medias, 'video')) {
    problems.push(`${T.error.onlyVideoAllowedIn}  ${T.common.socials[provider].toLowerCase()}.`)
  }

  //? Controllo che si sia inserito un testo
  if (needText && !text) {
    problems.push(`${T.error.obligatoryTitle}  ${T.common.socials[provider].toLowerCase()}.`)
  }

  //? Controllo numero massimo di caratteri per TITOLO
  if (!checkTextLength(text, maxTitleChars)) {
    problems.push(T.error.titleTooLong(T.common.socials[provider], maxTitleChars))
  }

  //? Controllo numero massimo di caratteri per DESCRIZIONE
  if (description && !checkTextLength(description, maxCharsDescription)) {
    problems.push(T.error.descriptionTooLong(T.common.socials[provider], maxCharsDescription))
  }

  //? Controllo carosello
  if (checkIfIsCarousel(medias)) {
    //? Controlla dimensione carosello (accetta solo video)
    if (!checkCarouselDimension(medias, maxMedia)) {
      problems.push(
        `${T.common.socials[provider]} ${T.error.MaxMedia} ${maxMedia} ${T.singleWord.video.toLowerCase()}.`
      )
    }
  }

  return problems
}

/**
 * Controllo dei requisiti per un post di TikTok
 * @param post Post con la quale fare la verifica dei requisiti
 * @returns Un'array di stringhe con tutti i problem
 */
export const checkTiktokPostValidation = (post: IPostVariant): string[] => {
  const problems: string[] = []

  const { data, info } = post
  const { account, type } = info
  const { provider } = account
  const { text, medias, description } = data

  const { checkTextLength, checkMediasType, checkCarouselDimension, checkIfIsCarousel } = socialCheckConfig

  const { needMedia, needText, onlyVideo, maxPostForDay, maxChars, maxMedia } = socialCheckConfig.tiktok

  //? Controllo che si sia inserito almeno un media
  if (needMedia && medias.length === 0) {
    problems.push(`${T.error.obligatoryMedia}  ${T.common.socials[provider].toLowerCase()}.`)
  }

  //? Controllo che il tipo del media sia corretto
  if (onlyVideo && !checkMediasType(medias, 'video')) {
    problems.push(`${T.error.onlyVideoAllowedIn}  ${T.common.socials[provider].toLowerCase()}.`)
  }

  //? Controllo che il testo inserito non superi il numero massimo di caratteri
  if (!checkTextLength(text, maxChars)) {
    problems.push(T.error.descriptionTooLong(T.common.socials[provider], maxChars))
  }

  //? Controllo carosello
  if (checkIfIsCarousel(medias)) {
    //? Controlla dimensione carosello (accetta solo video)
    if (!checkCarouselDimension(medias, maxMedia)) {
      problems.push(
        `${T.common.socials[provider]} ${T.error.MaxMedia} ${maxMedia} ${T.singleWord.video.toLowerCase()}.`
      )
    }
  }

  return problems
}
