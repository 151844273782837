import styled from '@emotion/styled'
import { Tracker } from 'api/Tracker'
import { getUserInfo, timeout10s } from 'api/Utils'
import { Box, Page, Text } from 'components'
import { DashboardEnvironment } from 'components/Dashboard/DashboardEnvironment'
import { DashboardOrganization } from 'components/Dashboard/DashboardOrganization'
import { LowerButtonsDashboard } from 'components/Dashboard/LowerButtonsDashboard'
import { UpperButtonsDashboard } from 'components/Dashboard/UpperButtonsDashboard'
import { useEffect, useState } from 'react'
import { useNotifyStore } from 'stores/NotifyStore'
import { T } from 'translation/i18n'
import { AppStore } from 'utils'
import {
  OWNER_COLLABORATORS_LIMITS_AVAILABLE,
  OWNER_COLLABORATORS_LIMITS_USED,
  OWNER_COPIES_TOTAL,
  OWNER_COPIES_USED,
  OWNER_POST_INFO_NOT_PUBLISHED,
  OWNER_POST_INFO_PLANNED,
  OWNER_POST_INFO_PUBLISHED,
  OWNER_POST_LIMITS_AVAILABLE,
  OWNER_POST_LIMITS_USED,
  OWNER_SOCIAL_ACCOUNTS_LIMITS_AVAILABLE,
  OWNER_SOCIAL_ACCOUNTS_LIMITS_USED,
  getWorkspaceOwnerData,
} from 'utils/Constants/LimitUserConstants'
import { TRACKEREVENTS, getDashboardData } from 'utils/eventsTracker'

const DashboardPage = () => {
  /* --------------- STATE */
  //? Limiti dell'owner
  const [usedCopies, setUsedCopies] = useState(OWNER_COPIES_USED)
  const [availableCopies, setAvailableCopies] = useState(OWNER_COPIES_TOTAL)
  const [usedCollaborators, setUsedCollaborators] = useState(OWNER_COLLABORATORS_LIMITS_USED)
  const [availableCollaborators, setAvailableCollaborators] = useState(OWNER_COLLABORATORS_LIMITS_AVAILABLE)
  const [usedPosts, setUsedPosts] = useState(OWNER_POST_LIMITS_USED)
  const [availablePosts, setAvailablePosts] = useState(OWNER_POST_LIMITS_AVAILABLE)
  const [usedSocials, setUsedSocials] = useState(OWNER_SOCIAL_ACCOUNTS_LIMITS_USED)
  const [availableSocials, setAvailableSocials] = useState(OWNER_SOCIAL_ACCOUNTS_LIMITS_AVAILABLE)

  //? Post info
  const [postPublished, setPostPublished] = useState(OWNER_POST_INFO_PUBLISHED)
  const [postPlanned, setPostPlanned] = useState(OWNER_POST_INFO_PLANNED)
  const [postNotPublished, setPostNotPublished] = useState(OWNER_POST_INFO_NOT_PUBLISHED)

  /* --------------- METHODS */
  const trackDashboardLoadedEvent = () => {
    const loggedUserData = AppStore.loggedUser
    Tracker.trackEvent(TRACKEREVENTS.DASHBOARD_PAGE_VIEWED, getDashboardData(loggedUserData))
  }

  const { updateDashboard } = useNotifyStore((s) => ({
    updateDashboard: s.updateDashboard,
    setUpdatedDashboard: s.setUpdatedDashboard,
  }))

  let recursiveTimeout!: NodeJS.Timeout
  const recursiveTimeoutAction = (callback: () => void, time: number) => {
    if (recursiveTimeout) clearTimeout(recursiveTimeout)
    recursiveTimeout = setTimeout(() => {
      callback()
      recursiveTimeoutAction(callback, time)
    }, time)
  }

  const update = async () => {
    await getWorkspaceOwnerData({})
    //? Limiti dell'owner
    setUsedCopies(OWNER_COPIES_USED)
    setAvailableCopies(OWNER_COPIES_TOTAL)

    setUsedCollaborators(OWNER_COLLABORATORS_LIMITS_USED)
    setAvailableCollaborators(OWNER_COLLABORATORS_LIMITS_AVAILABLE)

    setUsedPosts(OWNER_POST_LIMITS_USED)
    setAvailablePosts(OWNER_POST_LIMITS_AVAILABLE)

    setUsedSocials(OWNER_SOCIAL_ACCOUNTS_LIMITS_USED)
    setAvailableSocials(OWNER_SOCIAL_ACCOUNTS_LIMITS_AVAILABLE)

    //? Post info
    setPostPublished(OWNER_POST_INFO_PUBLISHED)
    setPostPlanned(OWNER_POST_INFO_PLANNED)
    setPostNotPublished(OWNER_POST_INFO_NOT_PUBLISHED)
  }

  const updateDashboardData = async () => {
    await AppStore.refreshLoggedUser()
    update()
    trackDashboardLoadedEvent()
  }

  /* --------------- USEFFECT */
  useEffect(() => {
    const { language, timeZone, utcOffset } = getUserInfo()
    const timezone = {
      name: timeZone,
      value: utcOffset,
    }
    updateDashboardData()
    recursiveTimeoutAction(update, timeout10s)
    return () => {
      clearTimeout(recursiveTimeout)
    }
  }, [])

  return (
    <Page title={T.DashboardPage.dashboard}>
      <PageContent>
        {/* Parte superiore (ambienti) */}
        <DashboardSection style={{ alignItems: 'flex-end' }}>
          <Box gap={16}>
            <Text fontSize={21} weight={700}>
              {T.DashboardPage.yourEnvironment}
            </Text>
            <DashboardEnvironment
              postPublished={postPublished}
              postPlanned={postPlanned}
              postNotPublished={postNotPublished}
            />
          </Box>
          <UpperButtonsDashboard />
        </DashboardSection>

        {/* Parte inferiore (organization) */}
        <DashboardSection>
          <Box gap={16}>
            <Text fontSize={21} weight={700}>
              {T.DashboardPage.yourOrganization}
            </Text>
            <DashboardOrganization
              usedCopies={usedCopies}
              availableCopies={availableCopies}
              usedCollaborators={usedCollaborators}
              availableCollaborators={availableCollaborators}
              usedPosts={usedPosts}
              availablePosts={availablePosts}
              usedSocials={usedSocials}
              availableSocials={availableSocials}
            />
          </Box>

          <LowerButtonsDashboard />
        </DashboardSection>
      </PageContent>
    </Page>
  )
}

export default DashboardPage

const PageContent = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 32px;
  flex: 1;
  position: relative;
  max-height: 100%;
  overflow: hidden;
  margin-top: 10px; // 76 da figma
  width: 100%;
  border-radius: 0px;
`

const DashboardSection = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 40px;
  position: relative;
  width: 100%;
`
