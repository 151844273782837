import styled from '@emotion/styled'
import Medias from 'api/Medias'
import Workspaces from 'api/Workspaces'
import { Box } from 'components/Box'
import { Button } from 'components/Button'
import { Icons } from 'components/Icons'
import { Input } from 'components/Input'
import PopConfirm from 'components/PopConfirm'
import SearchTimezone from 'components/SearchTimezone'
import { Text } from 'components/Text'
import { Title } from 'components/Title'
import React, { useEffect, useState } from 'react'
import { T } from 'translation/i18n'
import { Workspace } from 'types'
import { AppStore, showError, showSuccess } from 'utils'

import moment from 'moment-timezone'
import { MixpanelWorkspaceCreation } from 'utils/eventsTracker/workspace/workspaceInterfaces'
import { Tracker } from 'api/Tracker'
import { TRACKEREVENTS, getMixpanelWorkspaceCreationData } from 'utils/eventsTracker'
import MediaUtils from 'utils/MediaUtils'
import { navigate } from 'utils/router'
interface Props {
  workspace?: Workspace
  onConfirm?: () => void
  onWorkspaceDelete?: () => void
}

export const WorkspaceModal: React.FC<Props> = ({ workspace = { name: '' }, onConfirm, onWorkspaceDelete }) => {
  const [workspaceState, setworkspace] = useState(JSON.parse(JSON.stringify(workspace)))
  const [openTimezone, setOpenTimezone] = useState(false)
  const [loading, setloading] = useState(false)
  const [selectedTimezone, setSelectedTimezone] = useState<any>()
  const inputFileRef = React.createRef<any>()

  useEffect(() => {
    //if (selectedTimezone) {
    addTimezone(selectedTimezone)
    //}
  }, [selectedTimezone])

  useEffect(() => {
    if (!workspace.timezone) {
      const timezones = moment.tz.guess()
      setSelectedTimezone({ name: timezones, value: `UTC${moment.tz(timezones).format('Z')}` })
    } else {
      setSelectedTimezone({ ...workspace.timezone })
    }
  }, [])

  const onFileChange = async (event) => {
    // Update the state
    const selectedFile = event.target.files[0]
    const formData = new FormData()

    // Update the formData object
    formData.append('file', selectedFile, selectedFile.name)
    formData.append('prefix', 'avatar/')

    const data = (await Medias.upload(formData)).data

    workspaceState.image = data
    const res = await Workspaces.save(workspaceState)
    res.image = data
    await AppStore.refreshLoggedUser()
    AppStore.reloadRouting()
    setworkspace(res)
    showSuccess(T.success.envImgUpdated)
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    if (selectedTimezone === undefined || workspaceState.name === '') {
      if (selectedTimezone === undefined && workspaceState.name !== '') {
        showError(T.error.addTimezoneOfWorkspace)
      } else if (selectedTimezone !== undefined && workspaceState.name === '') {
        showError(T.error.addNameOfWorkspace)
      } else {
        showError(T.error.completeFieldsFirst)
      }
      return
    }
    if (loading) return
    setloading(true)
    const copyWorkspaceState = { ...workspaceState }

    const createdWorkspaceData: MixpanelWorkspaceCreation = {
      workspace_name: copyWorkspaceState.name,
      workspace_profile_url: copyWorkspaceState.image?.presignedUrl,
      time_zone: `${copyWorkspaceState.timezone.name} (${copyWorkspaceState.timezone.value})`,
    }

    try {
      e.preventDefault()
      await Workspaces.save(workspaceState)
      if (workspaceState.id || workspaceState._id) showSuccess(T.success.envNameUpdated)
      else {
        showSuccess(T.success.envCreated)

        Tracker.trackEvent(
          TRACKEREVENTS.WORKSPACE_CREATION_SUCCESSFUL,
          getMixpanelWorkspaceCreationData(createdWorkspaceData)
        )
      }
      await AppStore.refreshLoggedUser()
      onConfirm && onConfirm()
      AppStore.closeModal('workspace-modal')
    } catch (error) {}
    setloading(false)
  }

  const addTimezone = (element?: any, id?: string, daylightTime?: boolean) => {
    workspaceState.timezone = element
    workspaceState.daylightTime = daylightTime === undefined ? false : daylightTime
    setworkspace(workspaceState)
  }

  const onDelete = async () => {
    if (loading) return
    setloading(true)
    try {
      await Workspaces.delete(workspaceState._id)

      onWorkspaceDelete && onWorkspaceDelete()
      AppStore.loggedUser.workspaces = AppStore.loggedUser.workspaces.filter((w) => w._id !== workspaceState._id)

      navigate('/settings/workspace')
      onConfirm && onConfirm()
      AppStore.closeModal('workspace-modal')
    } catch (error) {}
    setloading(false)
  }

  const styleQuestion = {
    backgroundImage: workspaceState.image?.presignedUrl
      ? `url(${workspaceState.image?.presignedUrl})`
      : `url(${MediaUtils.workspacePlaceholder})`,
  }

  return (
    <Box row flex vcenter pv={48} ph={80}>
      <Box center>
        <Box style={{ position: 'relative' }}>
          <Box
            onClick={() => inputFileRef?.current?.click()}
            key={workspaceState.image?.filename}
            center
            style={{
              width: '176px',
              height: '176px',
              borderRadius: '50%',
              backgroundColor: AppStore.theme.o.grey,
              backgroundSize: 'contain',
              filter: 'brightness(0.6)',
              ...styleQuestion,
            }}
          >
            <input style={{ display: 'none' }} onChange={(e) => onFileChange(e)} ref={inputFileRef} type="file" />
          </Box>
          <Box
            onClick={() => inputFileRef?.current?.click()}
            style={{
              position: 'absolute',
              margin: 'auto',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
            }}
            removeHover
          >
            <Icons.edit />
          </Box>
        </Box>
        {!workspace.default && !!workspace._id && (
          <PopConfirm
            title={
              <p>
                {T.settings.popEnvConfirm1}
                <br />
                {T.settings.popEnvConfirm2}
                {T.settings.popEnvConfirm3}
                <br />
                {T.settings.popEnvConfirm4}
              </p>
            }
            okText={T.settings.confirm}
            onConfirm={onDelete}
          >
            <Text
              color={AppStore.theme.o.red}
              style={{ textDecoration: 'underline', marginTop: 10, fontWeight: 'bold' }}
            >
              {T.settings.deleteEnv}
            </Text>
          </PopConfirm>
        )}
      </Box>
      <form style={{ display: 'flex', flex: 1 }} onSubmit={onSubmit}>
        <Box justify={'space-evenly'} flex ml={40}>
          <Title style={{ marginBottom: 22 }}> {T.settings.createYourEnv}</Title>
          <Input
            prefix={
              <Icons.workSpaces
                fill={AppStore.theme.o.black}
                style={{ color: AppStore.theme.o.black, width: 24, height: 24 }}
              />
            }
            width={'30vw'}
            autoFocus
            style={{ marginBottom: 20 }}
            defaultValue={workspaceState?.name}
            placeholder={T.settings.nameEnv}
            onChange={(txt) => {
              workspaceState.name = txt
              setworkspace(workspaceState)
            }}
          />
          <Box>
            <Box
              width={344}
              height={48}
              row
              mb={20}
              vcenter
              ph={16}
              style={{
                backgroundColor: AppStore.theme.o.lightestGrey,
                justifyContent: 'space-between',
                border: `1px solid ${AppStore.theme.o.grey}`,
              }}
              onClick={() => setOpenTimezone(!openTimezone)}
            >
              <SelectTimezone selectedTimezone={selectedTimezone}>
                {selectedTimezone?.name || T.settings.setTimeZone}
              </SelectTimezone>
              <ArrowIcon />
            </Box>
            {openTimezone && (
              <Box style={{ zIndex: 9999 }} absolute mt={52}>
                <SearchTimezone setSelectedTimezone={setSelectedTimezone} setOpenTimezone={setOpenTimezone} />
              </Box>
            )}
          </Box>
          <Button loading={loading} size="small" variant="primary" type="submit">
            {T.settings.save}
          </Button>
        </Box>
      </form>
    </Box>
  )
}

const SelectTimezone = styled.p<{ selectedTimezone: any }>`
  font-family: Aktiv Grotesk;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: ${(props) => (props.selectedTimezone === undefined ? AppStore.theme.o.darkGrey : AppStore.theme.o.black)};
`

const ArrowIcon = styled(Icons.downIcon)`
  width: 14px;
  height: 14px;
  fill: ${() => AppStore.theme.o.black};
`

const AddImageIcon = styled(Icons.addImageWorkspace)`
  fill: ${() => AppStore.theme.o.darkGrey};
  width: 48px;
  height: 48px;
`
