import styled from '@emotion/styled'
import Workspaces from 'api/Workspaces'
import { Button } from 'components'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Workspace } from 'types'
import { AppStore, T } from 'utils'
import { Box } from './Box'
import SearchRoleComponent from './SearchRoleComponent'
import { HandleSelectCard } from './UI'
import UserRow from './UserRow'
import { requestConfirmDialog } from './Modal/ModalManager'
import { truncateText } from 'pages/ai-discover/commonFunction'

export interface Roles {
  id: string
  permissions: {
    id: string
    number: number
  }[]
}

interface ICollabUser {
  name: string
  email: string
  role: string
  invitationConfirmed: boolean
  id: string
}

interface Props {
  changeSession?: any
  workspace?: any
  users?: any
  rolesNumber?: any
  onWorkspaceChange: (workspace: Workspace) => void
  handleForceUpdate?: () => void
}

export default function UsersGridSection({
  changeSession,
  workspace,
  users,
  rolesNumber,
  onWorkspaceChange,
  handleForceUpdate,
}: Props) {
  /* ------------------- STATE  */
  const [roles, setRoles] = useState<any>([])

  const [Users, setUsers] = useState<Array<ICollabUser | undefined>>([])

  const [modifyingRole, setModifyingRole] = useState(-1)

  const [selectedIds, setSelectedIds] = useState<string[]>([])

  /* ------------------- ROUTER  */
  const { workspaceId } = useParams()

  /* ------------------- VARIABLES  */
  const maxPermissions = [
    {
      id: T.teamMembers.rulesTitle.publication,
      number: 4,
    },
    {
      id: T.teamMembers.rulesTitle.calendar,
      number: 1,
    },
    {
      id: T.teamMembers.rulesTitle.media,
      number: 3,
    },
    {
      id: T.teamMembers.rulesTitle.analytics,
      number: 3,
    },
    {
      id: T.teamMembers.rulesTitle.discover,
      number: 2,
    },
    {
      id: T.teamMembers.rulesTitle.settings,
      number: 3,
    },
  ]

  const sessions = [
    T.teamMembers.tableHeaders.publication,
    T.teamMembers.tableHeaders.calendar,
    T.teamMembers.tableHeaders.media,
    T.teamMembers.rulesTitle.analytics,
    T.teamMembers.rulesTitle.discover,
    T.teamMembers.tableHeaders.settings,
  ]

  const widthPerIndex = {
    0: 82,
    1: 64,
    2: 36,
    3: 55,
    4: 52,
    5: 75,
    6: 114,
  }

  /* ------------------- METHODS  */
  const handleChangeRole = (e, user, index) => {
    if (!user.invitationConfirmed) return
    e.stopPropagation()
    if (index === modifyingRole) setModifyingRole(-1)
    setModifyingRole(index)
  }

  const countHowManyPermissions = (AsignedRole: string) => {
    const appstoreRole = workspace.roles ?? []
    const roleIndex = appstoreRole?.findIndex((role) => role.name === AsignedRole) ?? 0
  }

  // if admin doesn't exist = false
  const findRole = (roleId: string | undefined, referenceArray?: any) => {
    if (referenceArray !== undefined) {
      if (roleId !== undefined) {
        return referenceArray.find((role) => role.roleName.toLowerCase() === roleId.toLowerCase())
      }
    }

    if (roleId !== undefined) {
      if (roleId !== 'Undefined' && roleId !== 'Admin') {
        return roles.find((role) => role.roleName.toLowerCase() === roleId.toLowerCase())
      }
    }

    return {
      roleName: 'undefined',
      rolesNumber: [
        {
          name: T.teamMembers.rulesTitle.publication,
          permissionSelected: 0,
        },
        {
          name: T.teamMembers.rulesTitle.calendar,
          permissionSelected: 0,
        },
        {
          name: T.teamMembers.rulesTitle.media,
          permissionSelected: 0,
        },
        {
          name: T.teamMembers.rulesTitle.analytics,
          permissionSelected: 0,
        },
        {
          name: T.teamMembers.rulesTitle.discover,
          permissionSelected: 0,
        },
        {
          name: T.teamMembers.rulesTitle.settings,
          permissionSelected: 0,
        },
      ],
    }
  }

  const addNewRole = (newRoleName: string) => {
    roles.push({ id: newRoleName, permissions: [] })
  }

  /**
   * Funzione per gestire la selezione per l'eliminazione di un team member
   * @param id
   */
  const handleSelectId = (id: string) => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id))
    } else {
      setSelectedIds([...selectedIds, id])
    }
  }

  const handleSelectAll = () => {
    if (selectedIds.length === Users.length) {
      setSelectedIds([])
    } else {
      setSelectedIds(Users.map((user) => user?.id ?? ''))
    }
  }

  /* ------------------- API CALLS  */
  const deleteCollaborator = async (userIds: string[]) => {
    await Workspaces.deleteCollaborator(userIds, workspaceId!)
    setSelectedIds([])

    const willHaveNoTeamMembers = workspace.collaborators.length === userIds.length

    //@ BUG se si eliminano tutti i team members il workspace non aggiorna i dati correttamente, quindi si ricarica la pagina
    if (willHaveNoTeamMembers || workspace.collaborators.length === 1) {
      window.location.reload()
    } else {
      handleForceUpdate && handleForceUpdate()
    }
  }

  /* ------------------- USEFFECT  */
  useEffect(() => {
    setRoles(rolesNumber)
  }, [rolesNumber])

  useEffect(() => {
    setUsers(users)
  }, [users])

  /* ------------------- COMPONENTS  */
  const NumberComponent = ({ id, roleName, i }) => {
    let idMaxNumber = 0
    let roleData = 0
    let backGroundColor = AppStore.theme.o.lightGrey
    let textColor = AppStore.theme.o.darkGrey

    if (roleName !== 'Undefined') {
      try {
        idMaxNumber =
          maxPermissions.filter((permission) => permission.id.toLowerCase() === id.toLowerCase())[0].number ?? 0
        if (roleName === 'Admin') {
          roleData = idMaxNumber
        } else
          roleData =
            roles
              .filter((role) => role.roleName.toLowerCase() === roleName.toLowerCase())[0]
              .rolesNumber.filter((role) => role.name.toLowerCase() === id.toLowerCase())[0].permissionSelected ?? 0
      } catch (e) {
        console.error('error', e)
      }
    }

    if (roleData !== 0) {
      backGroundColor = idMaxNumber === roleData ? AppStore.theme.o.lightBlue : AppStore.theme.o.lightGrey
      textColor = idMaxNumber === roleData ? AppStore.theme.o.blue : AppStore.theme.o.black
    }

    return (
      <Box id={`permission-${id}`} height={21} center width={widthPerIndex[i]}>
        <Box style={{ radius: '50%', backgroundColor: backGroundColor }} p={'4px 8px'}>
          <HeaderText style={{ color: textColor, fontSize: 10 }}>
            {roleData}/{idMaxNumber}
          </HeaderText>
        </Box>
      </Box>
    )
  }

  const AddRoleComponent = (user, index) => {
    const isAdmin = user.role === 'Admin'

    const color = isAdmin ? AppStore.theme.o.on_primary_container : AppStore.theme.o.black

    return (
      <Box relative>
        {index === modifyingRole && (
          <SearchRoleComponent
            collabId={user.collabId}
            listOfRoles={roles}
            index={index}
            workspace={workspace}
            userId={user.id}
            userRole={user.role ?? ''}
            addNewRole={addNewRole}
            changeSection={changeSession}
            onWorkspaceChange={onWorkspaceChange}
            handleForceUpdate={handleForceUpdate}
          />
        )}

        <Button
          variant={isAdmin ? 'primary' : 'neutral'}
          width={114}
          height={21}
          p={'8px 4px'}
          style={{ minHeight: 21 }}
          onClick={(e) => handleChangeRole(e, user, index)}
        >
          <RoleText style={{ color: color }}>{truncateText(user.role ?? T.teamMembers.addText, 15)}</RoleText>
        </Button>
      </Box>
    )
  }

  const openConfirmModal = (usersId: string[]) => {
    const message = (
      <p>
        {T.settings.remove_user_from_workspace}
        <br />
        {T.settings.remove_user_from_workspace_confirm}
        <br />
        {T.settings.popEnvConfirm4}
      </p>
    )

    requestConfirmDialog(message, () => deleteCollaborator(usersId))
  }

  return (
    <Box
      removeHover
      hideBar
      scroll
      width={967}
      style={{ maxHeight: 570, borderRadius: 0, cursor: 'default' }}
      onClick={() => setModifyingRole(-1)}
    >
      {/* HEADER GRID */}
      <Box row vcenter justify="space-between" p={'16px 16px 16px 12px'}>
        <Box row vcenter gap={8} width={194}>
          <Box
            height={16}
            width={16}
            style={{
              backgroundColor:
                selectedIds.length !== 0 && selectedIds.length === workspace?.collaborators.length
                  ? AppStore.theme.o.green
                  : AppStore.theme.o.grey,
            }}
            onClick={handleSelectAll}
          />
          <HeaderText>{T.teamMembers.tableHeaders.name}</HeaderText>
        </Box>

        <Box row vcenter justify="space-around" flex style={{ marginLeft: 'auto' }}>
          {sessions.map((session) => (
            <HeaderText>{session}</HeaderText>
          ))}
        </Box>

        <Box width={114}>
          <HeaderText>{T.teamMembers.tableHeaders.roleName}</HeaderText>
        </Box>
      </Box>

      <DivisionLine />

      {/* CONTENT GRID */}
      <Box>
        {/* ADMIN */}
        <UserRow
          removeEvents
          AddRoleComponent={AddRoleComponent}
          NumberComponent={NumberComponent}
          handleSelectId={handleSelectId}
          findRole={findRole}
          index={-2}
          admin={true}
          user={{
            name: workspace?.user?.name ?? '',
            email: workspace?.user?.email ?? '',
            role: 'Admin',
            invitationConfirmed: true,
            id: 'Admin',
          }}
        />

        {/* TEAM MEMBERS */}
        {Users.map((user, index) => {
          countHowManyPermissions(user?.role ?? '')
          if (user === undefined) return
          if (user.role !== undefined)
            return (
              <UserRow
                AddRoleComponent={AddRoleComponent}
                NumberComponent={NumberComponent}
                handleSelectId={handleSelectId}
                findRole={findRole}
                index={index}
                user={user}
                key={index}
                isSelected={selectedIds.includes(user.id)}
              />
            )
        })}
      </Box>

      {/* HANDLE DELETE */}
      <ContainerHandleDelete
        style={{
          bottom: selectedIds.length > 0 ? 16 : -100,
        }}
      >
        <HandleSelectCard handleDelete={() => openConfirmModal(selectedIds)} />
      </ContainerHandleDelete>
    </Box>
  )
}

const ContainerHandleDelete = styled(Box)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  z-index: 0;
`

const DivisionLine = styled.div`
  background-color: ${() => AppStore.theme.o.grey};
  height: 1px;
  width: 100%;
`

const HeaderText = styled.p`
  color: ${() => AppStore.theme.o.black};
  text-align: center;
  font-family: 'Aktiv Grotesk';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.12px;
`

const RoleText = styled.p`
  font-family: 'Aktiv Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 13px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
`
