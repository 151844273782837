import { AppStore, T, showError } from 'utils'
import { useUserStore } from 'stores/UserStore'
import { Button } from 'components'
import BillingService from 'api/service/BillingService'
import styled from 'styled-components'
import review from 'assets/icons/review.svg'
import upgrade from 'assets/icons/upgrade.svg'
import { License } from 'types'
import { SubscriptionPlan } from 'types/SubscriptionInterfaces'

export const BillingLinks = {
  capabilities: 'https://asters.ai/pricing/#plans-comparison',
  faq: 'https://help.asters.ai',
  appsumo: 'https://appsumo.com/products/asters/?p=1#pricePlans'
}


const LicenseCard = ({ license, currentPlan }: { license: License; currentPlan: SubscriptionPlan | undefined }) => (
  <LicenseCardContainer>
    <LicenseHeader>
      <LicenseTier>
        {license.provider.toLocaleUpperCase()} Tier {license.tier}
      </LicenseTier>
      <LicenseStatus>{license.status}</LicenseStatus>
    </LicenseHeader>
    <LicenseDetails>
      <LicenseItem>{currentPlan?.limits?.socials?.total} {T.billing.labels.socialProfile}</LicenseItem>
      <LicenseItem>{currentPlan?.limits?.collaborators?.total} {T.billing.labels.colabs}</LicenseItem>
      <LicenseItem>{currentPlan?.limits?.copies?.total} {T.billing.labels.copies}</LicenseItem>
      <LicenseItem>{T.billing.labels.discover} {T.billing.status.infinite}</LicenseItem>
      <LicenseItem>{currentPlan?.limits?.storage?.total} {T.billing.labels.storage}</LicenseItem>
      <LicenseItem>Live chat support</LicenseItem>
    </LicenseDetails>
  </LicenseCardContainer>
)

const BillingSection = () => {
  const { plan } = useUserStore((state) => ({ plan: state.user?.subscription?.activePlan }))
  const license = useUserStore((state) => state.user?.license)
  const currentPlan = useUserStore((state) => state.currentPlan)

  const handleManagePlanClick = async () => {
    try {
      const { data } = await BillingService.recoverManageLink(AppStore.loggedUser._id)
      if (data.url) {
        window.location.href = data.url
      }
    } catch (e) {
      showError(T.billing.settings.error)
    }
  }

  const TrialComponentBar = () => {
    const isPlanTrial = plan?.isTrial
    const freeTrialExpirationDate = plan?.freeTrialExpirationDate

    const trialEndDate = freeTrialExpirationDate ? new Date(freeTrialExpirationDate) : null
    const isValidTrial = isPlanTrial && trialEndDate && trialEndDate > new Date()

    if (!isValidTrial) return null

    const daysRemaining = Math.ceil((trialEndDate.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24))

    return (
      <TrialInfo>
        <TrialText>{T.billing.settings.freeTrial}</TrialText>
        <RemainingDays>{`${T.billing.settings.remainingDays} ${daysRemaining}`}</RemainingDays>
      </TrialInfo>
    )
  }

  return (
    <Container>
      <PlanHeader>
        <Title>{T.billing.settings.title}</Title>
        {!license && <Description>{T.billing.settings.description}</Description>}
        {!license && <PlanButton onClick={handleManagePlanClick}>{T.billing.settings.manage}</PlanButton>}
        {license && <LicenseCard license={license} currentPlan={currentPlan} />}
      </PlanHeader>

      <PlanFooter>
        <TrialComponentBar />
        <FooterContent>
          <Links>
            <Link href={license ? BillingLinks.appsumo : BillingLinks.capabilities} target="_blank">
              {T.billing.settings.checkCapabilities}
            </Link>
            <Link href={BillingLinks.faq} target="_blank">
              {T.billing.settings.faq}
            </Link>
          </Links>
          {license &&
            <ButtonGroup id="license-button">
              <PlanButton>
                <ButtonIcon src={upgrade} alt="Upgrade" />
                <ButtonLink href={BillingLinks.appsumo}>{T.header.upgrade}</ButtonLink>
              </PlanButton>
              <PlanButton>
                <ButtonIcon src={review} alt="Review" />
                <ButtonLink href={BillingLinks.appsumo}>{T.header.leaveComment}</ButtonLink>
              </PlanButton>
            </ButtonGroup>
          }
        </FooterContent>
      </PlanFooter>
    </Container>
  )
}

export default BillingSection

// ----------------- STYLES -----------------

const Container = styled.div`
  padding: 64px 80px;
  min-height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Title = styled.h1`
  color: ${() => AppStore.theme.o.green};
  font-size: 21px;
  font-weight: 700;
`

const Description = styled.p`
  color: ${() => AppStore.theme.o.textDarkGrey};
  margin-bottom: 20px;
`

const PlanButton = styled(Button)`
  height: 40px !important;
  min-height: 40px !important;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 40px 8px 40px !important;

  background-color: ${() => AppStore.theme.o.primary_container};
  border: 1px solid transparent;
  border-color: ${() => AppStore.theme.o.on_primary_container};
  color: ${() => AppStore.theme.o.on_primary_container};

  &:hover {
    border-color: transparent;
  }
`

const TrialInfo = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${() => AppStore.theme.o.lightestGrey};
  align-items: center;
  padding: 16px 24px;
  border-radius: 14px;
`

const TrialText = styled.span`
  color: ${() => AppStore.theme.o.black};
`

const RemainingDays = styled.span`
  color: ${() => AppStore.theme.o.textDarkGrey};
`

const Links = styled.div`
  margin-top: 20px;
`

const Link = styled.a`
  color: ${() => AppStore.theme.o.textDarkGrey};
  text-decoration: underline;
  margin-right: 20px;

  &:hover {
    text-decoration: underline;
    color: ${() => AppStore.theme.o.black};
  }
`

const PlanHeader = styled.div`
  flex-direction: column;
  justify-content: space-between;
`

const PlanFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
`

const LicenseCardContainer = styled.div`
  padding: 32px 24px;
  background-color: ${() => AppStore.theme.o.lightestGrey};
  border-radius: 14px;
  margin-top: 48px;
`

const LicenseHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const LicenseTier = styled.div`
  font-size: 32px;
  font-weight: 600;
  line-height: 41px;
  color: ${() => AppStore.theme.o.textDarkGrey};
`

const LicenseStatus = styled.div`
  padding: 0 12px;
  background-color: ${() => AppStore.theme.o.primary_container};
  border: 0.5px solid ${() => AppStore.theme.o.on_primary_container};
  color: ${() => AppStore.theme.o.on_primary_container};
  border-radius: 8px;
`

const LicenseDetails = styled.div`
  margin-top: 48px;
`

const LicenseItem = styled.div`
  font-size: 16px;
  margin-top: 10px;
  &:first-child {
    margin-top: 0;
  }
`

const FooterContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
`

const ButtonIcon = styled.img`
  margin-right: 10px;
`

const ButtonLink = styled.a`
  color: unset;
`
