import ShortUrl from 'api/ShortUrl'
import { DEFAULT_URL, WHITELIST_URL_FUNCTIONALITIES_PROVIDERS } from 'utils/Constants/CreatePostConstants'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { IPostData, IPostVariant, IUrl } from 'types/PostInterface'
import { AppStore, T, showWarning } from 'utils'
import {
  getActiveDescription,
  getActiveInfo,
  getActiveText,
  getFirstCommentText,
  getPostData,
  updateActive,
  updatePost,
} from './Post'

//Metadati - UTM - Shortlink
export const findFirstDiff = (string, string2) => {
  const stringWords = string
  const string2Words = string2

  let index = 0
  for (const word of string2Words) {
    if (word !== stringWords[index]) {
      let diff = ''
      for (let i = index; i < string2Words.length; i++) {
        diff += string2Words[i]
        if (string2Words[i] === ' ' || string2Words[i] === '\n') {
          break
        }
      }
      return diff
    }
    index++
  }

  return ''
}

export interface PasteLinkConfig {
  activePost: IPostVariant | IPostData
  originalUrl?: string
  newText: string
  finalText?: string
  firstComment?: boolean
  isDescription?: boolean
}

export const onPasteLink = async (config: PasteLinkConfig) => {
  const { activePost, newText, firstComment, isDescription, finalText } = config

  // oscura l'input di testo per evitare che l'utente effettui azioni
  await useCreatePostStore.setState({ textLoading: true })

  if (activePost === undefined) return
  const postData = getPostData(activePost)
  // Check if the text is for the first comment
  const isForFirstComment = firstComment

  // Define the text to evaluate
  const evaluateText = isForFirstComment ? postData.firstComment?.text : postData.text

  // Define the origin URL
  const originUrl = newText

  try {
    // ----------- If it's not a shortened link -----------
    const newUrlProperty: any = await recoverShortenedLink(originUrl, 'shorten')

    const refParameter = 'info' in activePost ? getRefParameter(activePost.info.account.account_id) : undefined
    const shortenDisabled = !AppStore.shortenAvailable || refParameter

    // ********** Check shortening disabilitato **********
    // Se lo shortening è disabilitato, il workspace ha un social con refparameter
    // o altre motivazioni, non sostituire l'url ma recupera i metadati per mostrare le anteprime
    const updatedCopyDescription = (finalText ?? evaluateText).replaceAll(
      originUrl,
      shortenDisabled ? originUrl : newUrlProperty?.shortUrl
    )

    if (!newUrlProperty) {
      useCreatePostStore.setState({ textLoading: false })
      return
    }
    const urls = isForFirstComment ? postData.firstComment?.urls : postData.urls
    console.log('onPasteLink', urls)
    // ********** Metadata  **********
    // Map the urls and set the metadata to visible false
    const updateVisibilyMetadatas =
      urls?.length > 0
        ? urls?.map((url) => ({
            ...url,
            metaData: { ...url.metaData, visible: url.metaData.visible ?? false },
          }))
        : []

    // ----- Testo normale (sezione preview)
    if (!isForFirstComment || !('variantId' in activePost)) {
      const updateValue = !isDescription ? { text: updatedCopyDescription } : { description: updatedCopyDescription }
      const urlss = removeDuplicateUrls([...updateVisibilyMetadatas, newUrlProperty])
      // leave only one urls for originalUrl or shortUrl
      updateActive({ ...updateValue, urls: urlss })
    }
    // ----- Testo in first comment (sezione extra)
    else {
      const urlss = removeDuplicateUrls([...updateVisibilyMetadatas, newUrlProperty])
      updatePost(activePost.variantId, {
        firstComment: { text: updatedCopyDescription, urls: urlss },
      })
    }
  } catch (error) {
    console.error('error adding ref parameter to the url', error)
  }

  useCreatePostStore.setState({ textLoading: false })
}

function removeDuplicateUrls(urls: IUrl[]): IUrl[] {
  const uniqueUrls = new Map<string, IUrl>()

  for (const url of urls) {
    // Usa sia originalUrl che shortUrl come chiavi per controllare i duplicati
    const key = url.originalUrl || url.shortUrl

    if (!uniqueUrls.has(key)) {
      uniqueUrls.set(key, url)
    }
  }

  return Array.from(uniqueUrls.values())
}

export const recoverShortenedLink = async (originUrl: string, type: 'shorten' | 'standard', ref?: string) => {
  try {
    const results = await ShortUrl.getShortLinkAndMetadata(originUrl, type, ref)
    // console.log('results', results)
    if (results === undefined) return showWarning(T.warning.notValidUrl)

    const utmConfig = {
      utm_source: '',
      utm_medium: '',
      utm_campaign: '',
      utm_term: '',
      utm_content: '',
    }

    const metaData = {
      ogUrl: results?.metaData?.ogUrl,
      ogSiteName: results?.metaData?.ogSiteName,
      ogTitle: results?.metaData?.ogTitle,
      ogDescription: results?.metaData?.ogDescription,
      ogImg: results?.metaData?.ogImg,
      visible: true,
    }

    const newUrlProperty: IUrl = {
      id: results._id,
      shortUrl: results.shortUrl,
      originalUrl: results.origUrl,
      shortened: true,
      utmConfig,
      metaData,
    }

    return newUrlProperty
  } catch (error) {
    console.error('error adding ref parameter to the url', error)
  }
}

// Ritorna il primo metadata visible
export const findVisibleMetadata = (post: IPostData | IPostVariant, firstComment?: boolean): any => {
  if (post === undefined) return

  if (firstComment === undefined) {
    if ('_id' in post && post._id === 'GLOBAL')
      return (post as IPostData).urls?.filter((url) => url?.metaData?.visible ?? true)
    if ('variantId' in post) return post.data.urls?.filter((url) => url?.metaData?.visible ?? true)
  }

  if (firstComment === true) {
    if ('variantId' in post) return post.data.firstComment?.urls?.filter((url) => url?.metaData?.visible ?? true)
  }

  return []
}

export const getPostsUrls = (post: IPostData | IPostVariant, selectedIndex?: number, firstComment?: boolean) => {
  let urls: any = []
  if (firstComment === undefined) {
    if ('variantId' in post) urls = post.data.urls
    if ('_id' in post && post._id === 'GLOBAL' && 'urls' in post) urls = post.urls
  }

  if (firstComment === true) {
    if ('variantId' in post) urls = post.data.firstComment?.urls
  }

  if (selectedIndex) {
    return [urls[selectedIndex]]
  }

  return urls
}

// Di base ritorna l'ultimo url che trova
export const findWorkingUTM = (post: IPostData | IPostVariant, selctedIndex: number, firstComment?: boolean) => {
  if (firstComment === undefined) {
    if ('variantId' in post) return post.data.urls && post.data.urls[selctedIndex]
    if ('_id' in post && post._id === 'GLOBAL' && post.urls) return post.urls && post.urls[selctedIndex]
  }

  if (firstComment === true) {
    if ('variantId' in post) return post.data.firstComment?.urls && post.data.firstComment?.urls[selctedIndex]
  }
  return DEFAULT_URL
}

/**
 * Change the url with the new utm values, check if url already has utm params then replace them with the key
 * @param visibleLink
 * @param utmValues
 * @returns
 */
export const newUtmUrl = (visibleLink: IUrl, utmValues: any) => {
  try {
    const origUrl = visibleLink.originalUrl ?? ''
    const url = new URL(origUrl)
    const params = new URLSearchParams(url.search)
    const keys = Object.keys(utmValues)

    // Check if url already has utm params then replace them with the key
    // -- remove old params
    keys.forEach((key) => {
      params.delete(key)
    })

    // -- add new params
    keys.forEach((key) => {
      if (utmValues[key] && utmValues[key] !== '') {
        params.append(key, utmValues[key])
      }
    })

    // -- add utm values to the url
    url.search = params.toString()
    return url.toString()
  } catch (error) {
    return visibleLink?.originalUrl ?? ''
  }
}

/**
 * Update the utm values in the url
 * @param url
 * @returns
 */
export const converUtmConfig = (url: IUrl) => {
  return {
    utm_source: url?.utmConfig?.utm_source,
    utm_medium: url?.utmConfig?.utm_medium,
    utm_campaign: url?.utmConfig?.utm_campaign,
    utm_term: url?.utmConfig?.utm_term,
    utm_content: url?.utmConfig?.utm_content,
  }
}

//@refactoring da fare
export const setShortenedLink = (
  post: IPostData | IPostVariant,
  shortValue: boolean,
  visibleLink,
  firstComment?: boolean,
  isDescription?: boolean
) => {
  if (firstComment === undefined) {
    if ('_id' in post && post._id === 'GLOBAL') {
      const newUrls = (post as IPostData).urls?.map((url) => {
        if (url.id === visibleLink.id) {
          return { ...url, shortened: shortValue }
        }
        return url
      })

      //@refactoring da fare
      const currentText = !isDescription ? getActiveText() : getActiveDescription()
      const valueToReplace = visibleLink.shortened ? visibleLink.shortUrl : visibleLink.originalUrl
      const newValue = visibleLink.shortened ? visibleLink.originalUrl : visibleLink.shortUrl

      const editedText = currentText?.replaceAll(valueToReplace, newValue)
      const updateValue = !isDescription ? { text: editedText } : { description: editedText }
      updateActive({ ...updateValue, urls: newUrls })
    }

    if ('variantId' in post) {
      const newUrls = post.data.urls?.map((url) => {
        if (url.id === visibleLink.id) {
          return { ...url, shortened: shortValue }
        }
        return url
      })

      const currentText = !isDescription ? getActiveText() : getActiveDescription()
      const valueToReplace = visibleLink.shortened ? visibleLink.shortUrl : visibleLink.originalUrl
      const newValue = visibleLink.shortened ? visibleLink.originalUrl : visibleLink.shortUrl
      const editedText = currentText?.replaceAll(valueToReplace, newValue)
      const updateValue = !isDescription ? { text: editedText } : { description: editedText }
      updateActive({ ...updateValue, urls: newUrls })
    }
  }

  if (firstComment === true) {
    if ('variantId' in post) {
      const newUrls = post.data.firstComment?.urls?.map((url) => {
        if (url.id === visibleLink.id) {
          return { ...url, shortened: shortValue }
        }
        return url
      })

      const actualLinkValue = visibleLink.shortened
        ? visibleLink.shortUrl
        : newUtmUrl(visibleLink, converUtmConfig(visibleLink))

      const newText = visibleLink.shortened
        ? getFirstCommentText(post)?.replaceAll(actualLinkValue, newUtmUrl(visibleLink, converUtmConfig(visibleLink)))
        : getFirstCommentText(post)?.replaceAll(actualLinkValue, visibleLink.shortUrl)

      updatePost(post.variantId, { firstComment: { text: newText, urls: newUrls } })
    }
  }
}

export const getLinkProperties = (post?: IPostVariant) => {
  if (post === undefined) {
    return getActiveLinkProperties()
  }
  if (post !== undefined) {
    return getLinkPropertiesFirstComment(post)
  }
}

export const getActiveLinkProperties = () => {
  const { activePost } = useCreatePostStore.getState()

  if (activePost === undefined) return []

  if ('_id' in activePost && activePost._id === 'GLOBAL') return (activePost as IPostData).urls ?? []

  if ('variantId' in activePost) return activePost.data.urls ?? []

  return []
}

export const getLinkPropertiesFirstComment = (post: IPostVariant) => {
  return post.data.firstComment?.urls ?? []
}

// Il parametro "post" viene passato solo quando si lavora in un post specifico nello specifico quando lavoriamo nel primo commento, altrimenti se si lavora nella sezione di preview si utilizza il post attivo
export const canShowMetadata = (post?: IPostVariant, previewPost?) => {
  if (post !== undefined) {
    const firstMetadata = findVisibleMetadata(post, true)
    const visibleLink = firstMetadata ? firstMetadata[0] : undefined

    return (
      post?.info?.account &&
      WHITELIST_URL_FUNCTIONALITIES_PROVIDERS?.includes(post.info.account.provider) &&
      visibleLink &&
      visibleLink.metaData.ogTitle !== undefined
    )
  }

  if (!post) {
    const { activePost } = useCreatePostStore.getState()

    const workingPost = previewPost ?? activePost

    const firstMetadata = findVisibleMetadata(workingPost)
    const visibleLink = firstMetadata ? firstMetadata[0] : undefined

    return (
      getActiveInfo(workingPost).account &&
      WHITELIST_URL_FUNCTIONALITIES_PROVIDERS.includes(getActiveInfo(workingPost).account.provider) &&
      visibleLink &&
      visibleLink.metaData.ogTitle !== undefined
    )
  }
}

/**
 * The function `isShortenEnable` checks if any posts have a corresponding social account with a
 * reference parameter.
 * @returns The function `isShortenEnable` is returning a boolean value. It is checking if there are
 * any posts where the associated social account has a `refParameter` property. If no such post is
 * found, it returns `true`, indicating that shorten is enabled. If at least one post with a
 * corresponding social account having a `refParameter` is found, it returns `false`, indicating that
 * shorten
 */
export const isShortenEnable = (): boolean => {
  const { posts } = useCreatePostStore.getState()
  if (posts.length === 0) return true
  return (
    AppStore.socialAccounts
      .filter((s) => !!s.refParameter)
      .filter((appstoreSocial) => posts.some((socialPost) => appstoreSocial._id === socialPost.info.account.account_id))
      .length === 0
  )
}

/**
 * Add reference parameter to the url if it is not already present and exist
 * Add utm parameters if they are not already present
 * @param link
 * @param ref
 * @returns
 */
const addRefToUrl = (link: string, ref?: string) => {
  let url = new URL(link)
  // -- Add ref parameter to the url
  if (ref && ref !== '' && !url.searchParams.has('ref') && !url.toString().includes('ref=')) {
    const urlString = url.toString()
    const separator = urlString.includes('?') ? '&' : '?'
    url.searchParams.set('ref', ref)
    url = new URL(urlString + separator + `ref=${ref}`)
  }
  console.log('addRefToUrl', url.toString())
  return url.toString()
}

/**
 * Add the reference parameter to the description
 * @param description
 * @param refParameter
 * @param urlRegex
 * @returns
 */
export const addRefParameterToDescription = (description: string, refParameter?: string): string => {
  const urlRegex =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g
  return description.replaceAll(urlRegex!, (url) => {
    return addRefToUrl(url, refParameter)
  })
}

export const filterUrl = (postUrls: IUrl[], urls: string[]): string[] => {
  return urls.filter((url) => postUrls.every((postUrl) => !postUrl.originalUrl.startsWith(url)))
}

export const getRefParameter = (_id: string): string => {
  return AppStore.socialAccounts.find((account) => account._id === _id)?.refParameter ?? ''
}

export const checkIfUrlAlreadyExists = (storedUrls: IUrl[], urls: string[]): boolean => {
  return urls.every((url) => storedUrls.some((storedUrl) => url.startsWith(storedUrl.originalUrl)))
}

/**
 * Updates the URLs in a global post
 * @param contentUrls
 * @param posts
 * @param urlRegex
 * @returns
 */
export const manageGlobalLinkUpdate = async (
  contentUrls: string[],
  posts: IPostVariant[],
  urlRegex: RegExp
): Promise<IPostVariant[]> => {
  // If user is editing a global post and has social accounts added cycle through all the social accounts and add the ref parameter to the links
  const updatedPosts: IPostVariant[] = []
  try {
    await Promise.all(
      posts.map(async (post, index) => {
        // Get all the urls in the text that are not in the post datas
        const newUrls =
          !!post.data?.urls && post.data.urls.length > 0 ? filterUrl(post.data.urls, contentUrls) : contentUrls

        // If all urls are already in the post return
        if (newUrls.length === 0) {
          updatedPosts.push(post)
          return
        }

        // Get the ref parameter from the social account and start adding it to the urls
        const ref = getRefParameter(post?.info?.account?.account_id)
        const newLinks: IUrl[] = await processUrls(newUrls, ref)
        const urlsToSave = [...(post?.data?.urls ?? []), ...newLinks]

        // Save the new urls in the post
        index === 0 && updateActive({ urls: urlsToSave })
        const newText = post.data.text

        // Update the post data
        updatedPosts.push({
          ...post,
          data: { ...post.data, text: newText, urls: urlsToSave },
        })
      })
    )
  } catch (error) {}
  return updatedPosts
}

/**
 * Updates the URLs in a post by adding a reference
 * @param contentUrls
 * @param activePost
 * @param urlRegex
 * @param description
 * @returns
 */
const processUrls = async (urls: string[], ref: string): Promise<IUrl[]> => {
  const results = await Promise.all(
    urls.map(async (url) => {
      try {
        return await recoverShortenedLink(url, 'standard', ref)
      } catch (error) {
        console.error('error adding ref parameter to the url', error)
        return undefined
      }
    })
  )
  return results.filter((result): result is IUrl => result !== undefined)
}

/**
 * Updates the URLs in a post variant by adding a reference
 * @param contentUrls
 * @param activePost
 * @param urlRegex
 * @param description
 * @returns
 */
export const manageVariantLinkUpdate = async (
  contentUrls: string[],
  activePost: IPostVariant,
  urlRegex: RegExp,
  description: string
): Promise<void> => {
  if (contentUrls.length === activePost.data.urls?.length) return
  // Filter the urls that are not in the new post description
  activePost.data.urls = activePost.data?.urls?.filter((url: IUrl) => contentUrls.includes(url.originalUrl)) ?? []

  // Get all the urls in the text that are not in the post datas
  const newUrls = filterUrl(activePost.data.urls, contentUrls)

  if (newUrls.length === 0) return
  // Get the ref parameter from the social account
  // Get the ref parameter from the social account
  const ref = getRefParameter(activePost.info.account.account_id)

  // Add the ref parameter to the urls

  // Add the ref parameter to the urls
  try {
    // --- Process the urls and recover the infos from shortening service
    const newLinks: IUrl[] = await processUrls(newUrls, ref)

    // --- Update the post in the store
    updateActive({
      text: description,
      urls: [...activePost.data.urls.filter((u) => contentUrls.includes(u.originalUrl)), ...newLinks],
    })
    return
  } catch (error) {
    console.error('error adding ref parameter to the url', error)
  }
  return
}
