/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import ShortUrl from 'api/ShortUrl'
import Utils from 'api/Utils'
import { Box, Button, Icons } from 'components'
import { openMediaModal } from 'components/Modal/ModalManager'
import { set } from 'date-fns'
import { useState } from 'react'
import { Media } from 'types'
import { IPostVariant, IUrl } from 'types/PostInterface'
import { AppStore, T, showInfo, showWarning } from 'utils'
import { updateActive, updatePost } from 'utils/PostUtils/Post'

export const onClickOpenEditMetadataModal = (post: IPostVariant, visibleLink: IUrl, section) => {
  AppStore.openModal({
    id: 'edit-metadata-modal',
    body: <EditMetadataModal visibleLink={visibleLink} post={post} section={section} />,
    style: {
      maxWidth: 440,
    },
  })
}

/* ------------------- INTERFACES  */

interface Props {
  visibleLink: IUrl
  post: IPostVariant
  section: string
}

export const EditMetadataModal = ({ visibleLink, post, section }: Props) => {
  /* ----------------- VARIABLES  */
  const { metaData } = visibleLink

  const { ogDescription, ogSiteName, ogImg, ogTitle } = metaData
  const [selectedMedia, setSelectedMedia] = useState<Media | null>(null)
  /* ----------------- STATE  */
  const [newMetadata, setNewMetadata] = useState({
    siteName: ogSiteName,
    title: ogTitle,
    description: ogDescription,
    image: ogImg,
  })

  /* ----------------- METHODS  */
  const onChangeInput = (e) => {
    const { value, name } = e.target
    setNewMetadata({ ...newMetadata, [name]: value })
  }

  const handleClickSave = () => {
    if (newMetadata.siteName?.length === 0) return showInfo(T.info.enterName)

    if (newMetadata.title?.length === 0) return showInfo(T.info.enterTitle)

    if (newMetadata.description?.length === 0) return showInfo(T.info.enterDescription)

    updateMeta()

    AppStore.closeModal('edit-metadata-modal')
  }

  /* ----------------- API CALL  */
  const updateMeta = async () => {
    const modifyedMetaData = {
      ogDescription: newMetadata.description,
      ogSiteName: newMetadata.siteName,
      ogImg: newMetadata.image,
      ogTitle: newMetadata.title,
    }

    try {
      await ShortUrl.modifyMetadata(visibleLink.id, modifyedMetaData)

      if (section === 'preview') {
        const newUrls = post.data.urls?.map((url) => {
          if (url.id === visibleLink.id) {
            return {
              ...url,
              metaData: {
                ...url.metaData,
                ...modifyedMetaData,
              },
            }
          }
          return url
        })

        updateActive({ urls: newUrls })
      }

      if (section === 'extra') {
        const newUrls = post.data.firstComment?.urls?.map((url) => {
          if (url.id === visibleLink.id) {
            return {
              ...url,
              metaData: {
                ...url.metaData,
                ...modifyedMetaData,
              },
            }
          }
          return url
        })

        updatePost(post.variantId, {
          firstComment: {
            ...post.data.firstComment,
            urls: newUrls,
          },
        })
      }
    } catch (e) {
      showWarning(T.warning.problemSettingMetadata)
    }
  }

  const uploadMediaPublic = async (media: Media) => {
    const newMedia = await Utils.saveImageRemote(media.url, media.name, media.path, true)
    setNewMetadata({ ...newMetadata, image: newMedia.presignedUrl })
    newMedia && setSelectedMedia(newMedia)
  }

  return (
    <EditMetadataModalContainer>
      <EditMetadataContent>
        <MetadataImage
          onClick={() => {
            //openMediaModal(setNewMetadata)
            openMediaModal({
              context: 'image',
              onMediaSelected: (media) => {
                uploadMediaPublic(media[0])
              },
              selectedMedias: [],
              closeOnlyMedia: true,
            })
          }}
        >
          <img src={selectedMedia?.url ?? newMetadata.image} />
          <Icons.uploadFile />
        </MetadataImage>

        <MetadataContent>
          <MedatTitle
            value={newMetadata.title}
            name="title"
            onChange={(e) => onChangeInput(e)}
            placeholder="Site title"
          />

          <Divider />
          <MedataDescription
            value={newMetadata.description}
            name="description"
            onChange={(e) => onChangeInput(e)}
            placeholder="Site description"
          />
        </MetadataContent>
      </EditMetadataContent>

      <EditMetadataSaveButton onClick={handleClickSave} variant="primary">
        {T.settings.save}
      </EditMetadataSaveButton>
    </EditMetadataModalContainer>
  )
}

const EditMetadataModalContainer = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const EditMetadataContent = styled(Box)`
  padding: 16px;
  display: flex;
  flex-direction: row;
  gap: 8px;
`

const EditMetadataSaveButton = styled(Button)`
  width: 213px;
  min-height: 42px;
  padding: 12px 88px !important;
  position: absolute;
  right: 0px;
  bottom: -50px;
  font-size: 14px;
`

const MetadataImage = styled(Box)`
  margin-top: 8px;
  width: 56px;
  height: 56px;
  border-radius: 12px;
  cursor: pointer;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  svg {
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: 0.3s ease-in-out;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    svg {
      border-radius: 12px;
      background-color: rgba(128, 128, 128, 0.5);
      opacity: 1;
      fill: ${() => AppStore.theme.o.black};
    }
  }
`

const MetadataContent = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 334px;
`

const Divider = styled(Box)`
  width: calc(100%-8px);
  height: 0.5px;
  background-color: ${() => AppStore.theme.o.grey};
  margin: 4px 0px 4px 8px;
`

const MedatTitle = styled.textarea`
  color: ${() => AppStore.theme.o.black};
  background-color: ${() => AppStore.theme.o.surface};
  border: none;
  border-radius: 14px;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  height: 28px;
  padding: 8px;
  resize: none;
  transition: 0.3s ease-in-out;

  &:hover,
  :focus {
    background-color: ${() => AppStore.theme.o.lightGrey};
  }

  &::-webkit-scrollbar {
    display: none;
  }
`

const MedataDescription = styled.textarea`
  color: ${() => AppStore.theme.o.black};
  background-color: ${() => AppStore.theme.o.surface};
  border: none;
  border-radius: 14px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 8px;
  min-height: 72px;
  resize: none;
  transition: 0.3s ease-in-out;

  &:hover,
  :focus {
    background-color: ${() => AppStore.theme.o.lightGrey};
  }

  &::-webkit-scrollbar {
    display: none;
  }
`
