/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import ShortUrl from 'api/ShortUrl'
import { Box, Button, Input } from 'components'
import { useEffect, useState } from 'react'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { IUserUTM } from 'types'
import { IUrl, IUtm } from 'types/PostInterface'
import { AppStore, T } from 'utils'
import { TUTORIAL_LINK_UTMS } from 'utils/Constants/Constants'
import { getActiveDescription, getActiveText, updateActive, updatePost } from 'utils/PostUtils/Post'
import { newUtmUrl } from 'utils/PostUtils/ShortUrl'
/* ------------------- INTERFACES  */
interface Props {
  post: any
  visibleLink: IUrl
  firstComment?: boolean
  isDescription?: boolean
}

export const UtmModal = ({ post, visibleLink, firstComment, isDescription }: Props) => {
  /* ------------------- STATES  */
  const [utmValues, setUtmValues] = useState(defaultUtmValues)

  /* ------------------- METHODS  */
  const onUtmValueChanged = (value: any) => {
    // save in local storage the utm values
    localStorage.setItem('utmValues', JSON.stringify(value))
  }

  const elaborate = async (currentText, newUtm) => {
    // verifico il link e lo sostituisco con quello nuovo
    const utmUrl = newUtmUrl(visibleLink, newUtm)
    // recupero dei dati del nuovo link
    const results = await ShortUrl.getShortLinkAndMetadata(utmUrl, 'shorten')
    const urlResult = results.shortUrl

    const isShortened = visibleLink.shortened && AppStore.shortenAvailable
    //replace the old url with the new one
    const originalUrl = isShortened ? visibleLink.shortUrl : visibleLink.originalUrl
    const finalurl = isShortened ? urlResult : utmUrl

    const newText = currentText?.replaceAll(originalUrl, finalurl)

    return {
      newText,
      urlResult,
      utmUrl,
    }
  }

  const elaborateUrls = async (urls, urlResult, utmUrl, newUtm) => {
    return (
      urls?.map((url) => {
        if (url.id === visibleLink.id) {
          const config = {
            originalUrl: utmUrl,
            shortUrl: urlResult,
          }
          return { ...url, utmConfig: newUtm, ...config }
        }
        return url
      }) ?? []
    )
  }

  // const updateUrls = async (urls, newUtm) => {
  //   // console.log('updateUrls', urls)
  //   // ottiene i nuovi dati
  //   const currentText = !isDescription ? getActiveText() : getActiveDescription()
  //   // console.log('currentText', currentText)
  //   // console.log('newUtm', newUtm)
  //   const { utmUrl, urlResult, newText } = await elaborate(currentText, newUtm)
  //   // dai nuovi dati elabora i nuovi url
  //   const newUrls = await elaborateUrls(urls, urlResult, utmUrl, newUtm)
  //   // aggiorna i valori nello store
  //   const updateValue = !isDescription ? { text: newText } : { description: newText }
  //   updateActive({ urls: newUrls, ...updateValue })
  // }

  const newUpdateUrls = async (urls, newUtm) => {
    console.log('urls', urls)
    // Ottiene il link attivo in questo momento e ne resetta i valori utm per poi aggiornare il link con quelli inseriti dall'utente
    const activeUrl = urls.find((url) => url.id === visibleLink.id)
    const linkToChange = resetUtmValues(activeUrl.originalUrl, newUtm)

    // Richiede il nuovo valore del link ( con utm ) shortato
    const results = await ShortUrl.getShortLinkAndMetadata(linkToChange, 'shorten')
    const shortUrl = results.shortUrl

    // Modifica i valori degli url di post con quelli nuovi
    const editedUrls = urls.map((url) => {
      if (url.id === activeUrl.id) return { ...url, originalUrl: linkToChange, shortUrl: shortUrl }
      return url
    })

    const isShortened = activeUrl.shortened && AppStore.shortenAvailable
    // Modifica il valore del testo del post per aggiornare l'interfaccia e la caption
    const originalUrl = isShortened ? activeUrl.shortUrl : activeUrl.originalUrl
    const finalurl = isShortened ? shortUrl : linkToChange

    // richiede l'attuale testo in attivo
    const currentText = !isDescription ? getActiveText() : getActiveDescription()

    console.log(activeUrl, 'originalUrl', originalUrl, 'finalurl', finalurl)

    // sostituisce il vecchio link con il nuovo, fa attenzione a sostituire solo link e non i link che iniziano con il link
    const newText = replaceExactUrl(currentText, originalUrl, finalurl)

    // aggiorna i valori nello store
    const updateValue = !isDescription ? { text: newText } : { description: newText }
    updateActive({ urls: editedUrls, ...updateValue })
  }

  const handleConfirm = async () => {
    AppStore.closeModal('utm-modal')

    useCreatePostStore.setState({ textLoading: true })
    const newUtm: IUtm = {
      utm_source: utmValues.utm_source,
      utm_medium: utmValues.utm_medium,
      utm_campaign: utmValues.utm_campaign,
      utm_term: utmValues.utm_term,
      utm_content: utmValues.utm_content,
    }

    if (firstComment === undefined) {
      const urls = '_id' in post && post._id === 'GLOBAL' ? post.urls : post.data.urls
      //await updateUrls(urls, newUtm)
      await newUpdateUrls(urls, newUtm)
    }

    if (firstComment === true) {
      const currentText = post.data.firstComment.text
      const { utmUrl, urlResult, newText } = await elaborate(currentText, newUtm)
      // dai nuovi dati elabora i nuovi url
      const newUrls = await elaborateUrls(post.data.firstComment?.urls, urlResult, utmUrl, newUtm)
      // aggiorna i valori nello store
      updatePost(post.variantId, { firstComment: { ...post.data.firstComment, text: newText, urls: newUrls } })
    }

    useCreatePostStore.setState({ textLoading: false })
  }

  /* ------------------- USEEFFECT  */
  useEffect(() => {
    updateUtmsValues()
  }, [])

  /**
   * Update the utm values from the url or from the local storage
   */
  const updateUtmsValues = () => {
    console.log('visibleLink', visibleLink)
    setUtmValues(obtainStoredUtm(visibleLink.originalUrl))
  }

  const helpAction = () => window.open('https://help.asters.ai/it', '_blank')

  // Apre la pagina di aiuto su gli utm
  const handleHelpAction = () => window.open(TUTORIAL_LINK_UTMS, '_blank')

  return (
    <UtmModalContainer>
      <Title>UTM</Title>

      {/* Container cambio utm */}
      <ContainerChangeUtms>
        <ChangeUtmValue>
          Source
          <Input
            placeholder={'e.g. Twitter, Facebook'}
            value={utmValues.utm_source}
            onChange={(e) => {
              setUtmValues({ ...utmValues, utm_source: e })
              onUtmValueChanged({ ...utmValues, utm_source: e })
            }}
          />
        </ChangeUtmValue>

        <ChangeUtmValue>
          Medium
          <Input
            placeholder={'e.g. cpc, banner, email'}
            value={utmValues.utm_medium}
            onChange={(e) => {
              setUtmValues({ ...utmValues, utm_medium: e })
              onUtmValueChanged({ ...utmValues, utm_medium: e })
            }}
          />
        </ChangeUtmValue>

        <ChangeUtmValue>
          Campaign
          <Input
            placeholder={'e.g. ACME-campaign'}
            value={utmValues.utm_campaign}
            onChange={(e) => {
              setUtmValues({ ...utmValues, utm_campaign: e })
              onUtmValueChanged({ ...utmValues, utm_campaign: e })
            }}
          />
        </ChangeUtmValue>

        <ChangeUtmValue>
          Term
          <Input
            placeholder={'e.g. paid keywords'}
            value={utmValues.utm_term}
            onChange={(e) => {
              setUtmValues({ ...utmValues, utm_term: e })
              onUtmValueChanged({ ...utmValues, utm_term: e })
            }}
          />
        </ChangeUtmValue>

        <ChangeUtmValue>
          Content
          <Input
            placeholder={'e.g. text AD name'}
            value={utmValues.utm_content}
            onChange={(e) => {
              setUtmValues({ ...utmValues, utm_content: e })
              onUtmValueChanged({ ...utmValues, utm_content: e })
            }}
          />
        </ChangeUtmValue>
      </ContainerChangeUtms>

      {/* Esempio utm */}
      <CompletedLink>
        <TitleSection>Esempio di url</TitleSection>

        {newUtmUrl(visibleLink, utmValues)}
      </CompletedLink>

      {/* Confirm button */}
      <Button variant="primary" size="smaller" width={100} onClick={handleConfirm}>
        {T.singleWord.confirm}
      </Button>

      {/* Cos'è un utm btn */}
      <Button
        variant="neutral"
        size="smaller"
        onClick={handleHelpAction}
        style={{ position: 'absolute', bottom: '-50px', left: '50%', transform: 'translateX(-50%)' }}
      >
        {T.postPage.whatAreUtm}
      </Button>
    </UtmModalContainer>
  )
}

const UtmModalContainer = styled(Box)`
  padding: 32px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  align-items: center;
  justify-content: center;
`

const Title = styled(Box)`
  color: ${() => AppStore.theme.o.black};
  font-family: Aktiv Grotesk;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.21px;
`

const ContainerChangeUtms = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
`

const ChangeUtmValue = styled(Box)`
  color: ${() => AppStore.theme.o.black};
  font-family: Aktiv Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  flex-direction: column;
  gap: 6px;
  width: 49%;
`

const CompletedLink = styled(Box)`
  color: ${() => AppStore.theme.o.blue};
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  width: 100%;
  gap: 8px;
`

const TitleSection = styled(Box)`
  color: ${() => AppStore.theme.o.black};
  font-family: Aktiv Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`

export const extractUtmFromUrl = (url: string) => {
  // manual url parsing to avoid the utm_ prefix
  const utms: any = {
    utm_source: null,
    utm_medium: null,
    utm_campaign: null,
    utm_term: null,
    utm_content: null,
  }
  console.log('url', url)
  utms.utm_source = url.match(/utm_source=([^&]*)/)?.[1] ?? null
  utms.utm_medium = url.match(/utm_medium=([^&]*)/)?.[1] ?? null
  utms.utm_campaign = url.match(/utm_campaign=([^&]*)/)?.[1] ?? null
  utms.utm_term = url.match(/utm_term=([^&]*)/)?.[1] ?? null
  utms.utm_content = url.match(/utm_content=([^&]*)/)?.[1] ?? null

  console.log('utms', utms)
  return utms
}

/**
 * Get the utm values from the url or from the local storage
 * @returns
 */
export const obtainStoredUtm = (link: string): IUserUTM => {
  // Check if the url has utm values
  // const extractedUtms = extractUtmFromUrl(link)
  // const hasUtm = Object.values(extractedUtms).some((value) => value !== null)
  // if (hasUtm) return extractedUtms

  const localUtm = localStorage.getItem('utmValues')
  const utms = localUtm ? JSON.parse(localUtm!) : defaultUtmValues

  // remove all the utm values that not respect the utm format
  const keys = Object.keys(utms)
  keys.forEach((key) => {
    if (!key.startsWith('utm_')) delete utms[key]
  })
  return utms
}

export const defaultUtmValues: IUserUTM = {
  utm_source: '',
  utm_medium: '',
  utm_campaign: '',
  utm_term: '',
  utm_content: '',
}

export const clearUrlFromDefaultUtm = (url: string) => {
  const urlEdited = new URL(url)
  const params = urlEdited.searchParams
  params.delete('utm_source')
  params.delete('utm_medium')
  params.delete('utm_campaign')
  params.delete('utm_term')
  params.delete('utm_content')
  return urlEdited.toString()
}

export const resetUtmValues = (url, utmValues) => {
  const urlEdited = clearUrlFromDefaultUtm(url)
  const urlResponse = new URL(urlEdited)
  const params = urlResponse.searchParams
  utmValues.utm_source && params.set('utm_source', utmValues.utm_source)
  utmValues.utm_medium && params.set('utm_medium', utmValues.utm_medium)
  utmValues.utm_campaign && params.set('utm_campaign', utmValues.utm_campaign)
  utmValues.utm_term && params.set('utm_term', utmValues.utm_term)
  utmValues.utm_content && params.set('utm_content', utmValues.utm_content)
  urlResponse.search = params.toString()
  return urlResponse.toString()
}

export function replaceExactUrl(text, originalUrl, newUrl) {
  // Escape caratteri speciali nell'URL originale per usarlo in regex
  const escapedUrl = originalUrl.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  // Crea una regex che matcha l'URL originale seguito da spazio/punteggiatura/fine stringa
  const regex = new RegExp(`${escapedUrl}(?=[\\s,.!?]|$)`, 'g')

  return text?.replace(regex, newUrl)
}
