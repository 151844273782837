import { KeyScope } from 'components/Modal/AutopilotGenerateKey/AutopilotGenerateKeyModal'
import { User } from 'types'
import { Plan } from 'types/StripeSubscription'
import { SubscriptionPlan, UserPlan } from 'types/SubscriptionInterfaces'
import { create } from 'zustand'

interface IUserStore {
  user?: User
  setUser: (user: User) => void
  currentPlan?: SubscriptionPlan
  setCurrentPlan: (plan: SubscriptionPlan) => void
  scopes: {
    autopilot: boolean
    analytics: boolean
  }
  setScopes: (scopes: { autopilot: boolean; analytics: boolean }) => void
}

export const useUserStore = create<IUserStore>((set, get) => ({
  user: undefined,
  setUser: (user) => set({ user }),
  scopes: {
    autopilot: false,
    analytics: false,
  },
  setScopes: (scopes) => set({ scopes }),
  currentPlan: undefined,
  setCurrentPlan: (plan) => set({ currentPlan: plan }),
}))
