import styled from '@emotion/styled'
import { Box } from 'components/Box'
import { Icons } from 'components/Icons'
import { useState } from 'react'
import { IPostVariant, TPostStatus } from 'types/PostInterface'
import { AppStore } from 'utils'
import { CollaboratorsStatus } from './CollaboratorsStatus'
import { downloadPreviewPost, duplicatePost, editPost, goToThePost, possibleIcons } from './iconConfig'
import { IconContainer } from './IconContainer'

interface Props {
  post: IPostVariant
  selectedIcon: string
  setSelectedIcon: (newIcon: string) => void
  status: TPostStatus
  addBlackLayer: () => void
  removeBlackLayer: () => void
}

export const IconsContainer = ({
  post,
  selectedIcon,
  setSelectedIcon,
  status,
  addBlackLayer,
  removeBlackLayer,
}: Props) => {
  /* ----------------- STATE  */
  const [iconsContainerOpen, setIconsContainerOpen] = useState<boolean>(false)

  /* ----------------- VARIABLES  */

  const collaborators = post?.data?.collaborators
  const filteredCollaborators = collaborators?.filter((collaborator) => collaborator.status)
  const hasCollaborators = filteredCollaborators && filteredCollaborators.length > 0

  /* ----------------- METHODS  */
  const handleClickOpenIcons = () => {
    //Se si chiude il container è c'era qualcosa di selezionato, lo deseleziona e chiude
    if (iconsContainerOpen && selectedIcon) {
      setIconsContainerOpen(false)
      setSelectedIcon('')
      removeBlackLayer()
    } else if (iconsContainerOpen) {
      //Chiude container
      setIconsContainerOpen(false)
      removeBlackLayer()
    } else {
      //Apre container
      setIconsContainerOpen(true)
    }
  }

  const handleClickIcon = async (id: string) => {
    // Se è già selezionato lo rimuove
    if (selectedIcon === id) {
      setSelectedIcon('')
      removeBlackLayer()
      return
    }

    // Aggiunge il l'id al selected icon (solo se hanno qualche azione da container da fare)
    setSelectedIcon(id === 'labels' || id === 'delete' || id === 'edit' ? id : '')

    // Per id specifico fa partire una funzione
    switch (id) {
      case 'labels':
        addBlackLayer()
        //Aggiunto il black layer , poi le funzionalità vengono dal container
        break
      case 'delete':
        addBlackLayer()
        //Aggiunto il black layer , poi le funzionalità vengono dal container
        break
      case 'edit':
        addBlackLayer()
        // editPost(post)
        // removeBlackLayer()
        break
      case 'reuse':
        duplicatePost(post)
        removeBlackLayer()
        break
      case 'goToPost':
        goToThePost(post)
        removeBlackLayer()
        break
      case 'download':
        await downloadPreviewPost(post._id as string)
        removeBlackLayer()
        break
      default:
        break
    }
  }

  /* ----------------- API CALLS  */

  /* ----------------- COMPONENTS  */
  const onRenderIcons = () =>
    possibleIcons.map((iconObj, i) => {
      const { id, icon, label, color, background, visibilityCheck } = iconObj

      const isVisible =
        status === 'planned'
          ? visibilityCheck.planned
          : status === 'error'
          ? visibilityCheck.error
          : status === 'published'
          ? visibilityCheck.published
          : status === 'draft'
          ? visibilityCheck.draft
          : false

      const isSelected = selectedIcon === id

      return (
        <IconContainer
          key={id + i}
          icon={icon}
          label={label}
          isSelected={isSelected}
          classNames={`${isSelected ? 'open-label' : ''}`}
          color={color}
          background={background}
          onClick={() => handleClickIcon(id)}
          style={{ display: isVisible ? 'flex' : 'none' }}
        />
      )
    })

  return (
    <>
      {hasCollaborators && <CollaboratorsStatus collaborations={filteredCollaborators!} />}

      <IconWrapper
        className={`${iconsContainerOpen ? 'isOpen' : ''}`}
        style={{
          top: hasCollaborators ? '104px' : '52px',
        }}
      >
        <IconContainer
          icon={<Icons.menu />}
          classNames={`icon-toggle-icons ${iconsContainerOpen ? 'isOpen' : ''}`}
          onClick={handleClickOpenIcons}
          background={AppStore.theme.o.surface}
        />

        {onRenderIcons()}
      </IconWrapper>
    </>
  )
}

const IconWrapper = styled(Box)`
  z-index: 1;
  position: absolute;
  left: calc(100% + 12px);
  max-height: 44px;
  min-width: 44px;
  width: fit-content;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 0px;
  transition: 0.6s cubic-bezier(0.4, 0, 0.2, 1);

  &.isOpen {
    max-height: 100%;
  }
`
